import React from 'react'
import ServiceCard from "../ServiceSection/ServiceCard";
import simg1 from '../../images/service/1.svg'
import simg2 from '../../images/service/2.svg'
import simg3 from '../../images/service/3.svg'
import simg4 from '../../images/service/4.svg'
import sSimg1 from '../../images/service-single/img1.jpg'
import sSimg2 from '../../images/service-single/img2.jpg'
import sSimg3 from '../../images/service-single/img3.jpg'
import sSimg4 from '../../images/service-single/img4.jpg'
import SectionTitle from '../SectionTitle/SectionTitle';

function Volunteerhomecards() {
    const Services = [
        {
            Id: '01',
            sImg: simg1,
            sSImg: sSimg1,
            title: 'Join now',
            slug: '1',
            description: 'Register now and explore tasks. View all available opportunities.',
        },
        {
            Id: '02',
            sImg: simg2,
            sSImg: sSimg2,
            title: 'Skill Match',
            slug: '2',
            description: 'Apply for tasks easily. Match them to your skills and interests.',
        },
        {
            Id: '03',
            sImg: simg3,
            sSImg: sSimg3,
            title: 'Direct Communication',
            slug: '3',
            description: 'Communicate directly with organisers. Stay updated on tasks.'

        },
        {
            Id: '04',
            sImg: simg4,
            sSImg: sSimg4,
            title: 'Effortless Contribution',
            slug: '4',
            description: 'Simplifying efforts, enabling meaningful contributions.',
        },

    ]
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-6 col-md-12 col-12">
                    <SectionTitle subtitle="What We Do?" title="Discover your passion, create impact!" />
                </div>
            </div>
            <div className="service-wrap">
                <div className="row">
                    {Services.slice(0, 4).map((Service, sitem) => (
                        <div className="col col-lg-3 col-md-6 col-sm-6 col-12" key={sitem}>
                            <div className="service-card-wrapper d-flex flex-column h-100">
                                <ServiceCard title={Service.title} img={Service.sImg} slug={Service.slug} sdescription={Service.description} />
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    )
}

export default Volunteerhomecards