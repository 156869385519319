import React from 'react'
import ServiceCard from "../ServiceSection/ServiceCard";
import simg1 from '../../images/service/real-time.png'
import simg2 from '../../images/service/financial.png'
import simg3 from '../../images/service/recruitment.png'
import simg4 from '../../images/service/cloud-computing.png'
import simg5 from '../../images/service/reward.png'
import sSimg1 from '../../images/service-single/img1.jpg'
import sSimg2 from '../../images/service-single/img2.jpg'
import sSimg3 from '../../images/service-single/img3.jpg'
import sSimg4 from '../../images/service-single/img4.jpg'
import sSimg5 from '../../images/service-single/img4.jpg'
import SectionTitle from '../SectionTitle/SectionTitle';

function TrackEmployee() {

    const Services = [
        {
            Id: '01',
            sImg: simg1,
            sSImg: sSimg1,
            title: 'Real-Time Data',
            slug: '2',
            description: "Track volunteer hours, completed tasks, and the real-time impact of each employee's efforts.",
        },
        {
            Id: '02',
            sImg: simg2,
            sSImg: sSimg2,
            title: 'Detailed Reports',
            slug: '1',
            description: 'Generate reports that highlight your company’s collective contributions.',
        },
        {
            Id: '03',
            sImg: simg3,
            sSImg: sSimg3,
            title: 'Individual Employee Profiles',
            slug: '3',
            description: 'Each employee has their volunteer profile where they can track their progress.'

        },
        // {
        //     Id: '04',
        //     sImg: simg4,
        //     sSImg: sSimg4,
        //     title: 'Recognition and Rewards',
        //     slug: '4',
        //     description: 'Gain visibility for your company’s volunteer efforts through our public leaderboard and achievements system, building brand value and loyalty.',
        // },

    ]
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-10 col-md-12 col-12">
                    <SectionTitle title="Track Employee Contributions with Ease" />
                    <p className='text-center'>Sevak Army’s platform includes powerful tools for tracking employee volunteer efforts. Through the Volunteer Progress dashboard, organizations can monitor and assess their employees’ participation, ensuring that everyone has the opportunity to contribute and make an impact.</p>
                </div>
            </div>
            <div className="service-wrap mt-3">
                <div className="row justify-content-center">
                    {Services.slice(0, 3).map((Service, sitem) => (
                        <div className="col col-lg-3 col-md-6 col-sm-6 col-12" key={sitem}>
                            <div className="service-card-wrapper d-flex flex-column h-100">
                                <ServiceCard title={Service.title} img={Service.sImg} slug={Service.slug} sdescription={Service.description} />
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <p className='text-center'>Tracking progress not only ensures that your CSR goals are met, but it also recognises the dedication of your employees—boosting morale and encouraging further participation.</p>
        </div>
    )
}

export default TrackEmployee