import React, { useState } from "react";
import VideoModal from "../ModalVideo/VideoModal";

import Image1 from "../../images1/ngo/home/sevak-army-ngo-home-1.jpg";
import Videobg from "../../images1/ngo/home/sevak-army-ngo-home-2.jpg";

import ShapeBg from "../../images/slider/stick-man.svg";
import SupportersSlider from "../hero/SupportersSlider";

const Image2 = "";

function NgoHero() {
  return (
    <section className="static-hero">
      <div className="hero-container">
        <div className="hero-inner">
          <div className="container-fluid">
            <div className="hero-content">
              <div className="slide-title-sub">
                <span>We Transform India's Future.</span>
              </div>
              <div className="slide-title">
                <h2 className="slide-heading">Simplifying Collaboration for Lasting Global Community Change.</h2>
              </div>
              <div className="slide-text">
                <p>
                  Sevak Army connects volunteers and NGOs to create lasting global change by simplifying collaboration
                  for community impact.
                </p>
              </div>
              <div className="clearfix"></div>
              <div data-swiper-parallax="500" className="slide-video-content">
                <div className="slide-video-img">
                  <img src={Videobg} alt="img" />
                  <VideoModal />
                </div>
                <div className="slide-video-text">
                  <h4 className="slide-video-text-show">Make a difference together!</h4>
                  <p>Let’s come together and make a difference, creating a lasting impact for a better future!</p>
                </div>
              </div>
              <div className="charius-pic">
                <div className="charius-pic-main">
                  <img src={Image1} alt="img" />
                </div>
                {/* <div className="charius-inner">
                  <img src={Image2} alt="img" />
                </div> */}

                <div className="wpo-supporter">
                  <div className="wpo-supporter-text">
                    <div className="icon">
                      <i>
                        <img src={ShapeBg} alt="img" />
                      </i>
                    </div>
                    <div className="content">
                      <h3>500+</h3>
                      <p>NGOs Partnered</p>
                    </div>
                  </div>
                  <SupportersSlider />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NgoHero;
