import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Hero from "../../components/hero/hero";
import About from "../../components/about/about";
import CausesSecion from "../../components/CausesSecion/CausesSecion";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import CtaSection from "../../components/CtaSection/CtaSection";
import FunFact from "../../components/FunFact/FunFact";
import EventSection from "../../components/EventSection/EventSection";
import TestimonialSection from "../../components/TestimonialSection/TestimonialSection";
import InstagramSection from "../../components/InstagramSection/InstagramSection";
import BlogSection from "../../components/BlogSection/BlogSection";
import PartnersSection from "../../components/PartnersSection/PartnersSection";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Logo from "../../images/logo.svg";
import { Link, useNavigate } from "react-router-dom";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const featuresData = [
  {
    iconClass: "flaticon-volunteer",
    title: "Become a Volunteer",
    description: "Dive into impactful projects and make a real difference where it matters most.",
    link: "/volunteer",
    color: "linear-gradient(180deg, #EBB011 0%, #ED861D 99.48%)",
  },
  {
    iconClass: "flaticon-charity",
    title: "Connect as an Organisation",
    description: "Mobilise your team for impactful CSR initiatives that make a lasting difference.",
    link: "/organization",
    color: "linear-gradient(rgb(213 17 235 / 50%) 0%, rgb(66, 29, 237) 99.48%)",
  },
  {
    iconClass: "flaticon-house",
    title: "Join Your School/College",
    description: "Give students the chance to volunteer, learn and contribute to real-world causes.",
    link: "/school-college",
    color: "linear-gradient(180deg, #FF7888 0%, #C10000 94.79%)",
  },
  {
    iconClass: "flaticon-solidarity",
    title: "Join as an Influencer",
    description: "Use your voice to champion causes and drive positive impact with the Sevak Army.",
    link: "/influencer",
    color: "linear-gradient(180deg, #1ECFFE 0%, #1287FF 100%)",
  },
  {
    iconClass: "flaticon-profile",
    title: "Join as an NGO",
    description: "Engage with committed volunteers and drive meaningful social impact together.",
    link: "/ngo",
    color: "linear-gradient(180deg, #00AF90 0%, #00715D 97.92%)",
  },
];

const HomePage = (props) => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <style>
        {`
      .home-nav-link {
        transition: color 0.3s ease, transform 0.3s ease;
      }
      .home-nav-link:hover {
        color: #ED861D;
        cursor: pointer;
        transform: scale(1.07);
      }
    `}
      </style>
      <Navbar Logo={Logo} hclass={"wpo-site-header"} />
      <Hero />
      <section className="wpo-features-area mb-5">
        <div className="container">
          <div className="features-wrap">
            <div className="row justify-content-center ">
              {featuresData.map((feature, index) => (
                <div
                  onClick={() => {
                    navigate(feature.link);
                    ClickHandler();
                  }}
                  className="home-nav-link col col-lg-4 col-md-6 col-12 my-4"
                >
                  <div className="feature-item-wrap">
                    <div className="feature-item">
                      <div className="feature-icon">
                        <div className="icon" style={{ background: feature.color }}>
                          <i className={`fi ${feature.iconClass}`}></i>
                        </div>
                      </div>
                      <div className="feature-text">
                        <h2 className="fs-5">{feature.title}</h2>
                        <p className="fs-6">{feature.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <CtaSection />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default HomePage;
