// images
import blogImg1 from "../images1/school-college/pages/sevak-army-school-college-pages-16.jpg";
import blogImg2 from "../images1/school-college/pages/sevak-army-school-college-pages-14.jpg";
import blogImg3 from "../images1/school-college/pages/sevak-army-school-college-pages-15.jpg";

import blog1 from "../images1/team/sevak-army-team-1.jpg";
import blog2 from "../images1/team/sevak-army-team-3.jpg";
import blog3 from "../images1/team/sevak-army-team-6.jpg";
import blog4 from "../images1/team/sevak-army-team-8.jpg";

import recent1 from "../images/recent-posts/img-1.jpg";
import recent2 from "../images/recent-posts/img-2.jpg";
import recent3 from "../images/recent-posts/img-3.jpg";

import blogSingleImg1 from "../images/blog/1.jpg";
import blogSingleImg2 from "../images/blog/2.jpg";
import blogSingleImg3 from "../images/blog/3.jpg";

const blogs = [
  {
    id: "1",
    title: "Maximising Employee Engagement.",
    slug: "1",
    subtitle: "Successful Implementation Techniques.",
    create_at: "25 Jan, 24",
    screens: blogImg1,
    bsing: blog1,
    recent: recent1,
    blogSingleImg: blogSingleImg1,
    comment: "35",
    day: "28",
    month: "AUGUST",
    blClass: "format-standard-image",
    athor: "Sanket Kamble",
  },
  {
    id: "2",
    title: "Inspiring Journeys of Achieved Success.",
    slug: "2",
    subtitle: " How Companies Are Making a Difference?",
    create_at: "25 Jan, 24",
    screens: blogImg2,
    bsing: blog2,
    recent: recent2,
    blogSingleImg: blogSingleImg2,
    comment: "35",
    day: "28",
    month: "AUGUST",
    blClass: "format-standard-image",
    athor: "Rishi Rathod",
  },
  {
    id: "3",
    title: "The Benefits of Corporate Volunteering.",
    slug: "3",
    subtitle: "Why Your Company Should Get Involved?",
    create_at: "25 Jan, 24",
    screens: blogImg3,
    bsing: blog3,
    recent: recent3,
    blogSingleImg: blogSingleImg3,
    comment: "35",
    day: "28",
    month: "AUGUST",
    blClass: "format-standard-image",
    athor: "Priyanka Ray",
  },
  {
    id: "4",
    title: "Innovative Volunteer Programmes.",
    slug: "4",
    subtitle: "Ideas To Inspire Your Organization.",
    create_at: "25 Jan, 24",
    screens: blogImg3,
    bsing: blog4,
    recent: recent3,
    blogSingleImg: blogSingleImg3,
    comment: "35",
    day: "28",
    month: "AUGUST",
    blClass: "format-standard-image",
    athor: "Radhika Jagtap",
  },
];

export const originalBlogs = [];

export default blogs;
