import React from 'react'
import ServiceCard from "../ServiceSection/ServiceCard";
import simg1 from '../../images/service/university-card.png'
import simg2 from '../../images/service/industry.png'
import simg3 from '../../images/service/red-carpet.png'
import simg4 from '../../images/service/cloud-computing.png'
import simg5 from '../../images/service/reward.png'
import sSimg1 from '../../images/service-single/img1.jpg'
import sSimg2 from '../../images/service-single/img2.jpg'
import sSimg3 from '../../images/service-single/img3.jpg'
import sSimg4 from '../../images/service-single/img4.jpg'
import sSimg5 from '../../images/service-single/img4.jpg'
import SectionTitle from '../SectionTitle/SectionTitle';

function RegisterSevakArmy() {

    const Services = [
        {
            Id: '01',
            sImg: simg1,
            sSImg: sSimg1,
            title: 'Customisable Volunteer Opportunities',
            slug: '2',
            description: 'Tailor your volunteer efforts to causes that resonate with your company’s values and industry.',
        },
        {
            Id: '02',
            sImg: simg2,
            sSImg: sSimg2,
            title: 'Employee Engagement Tools',
            slug: '1',
            description: 'Easily invite, engage, and track your employees’ volunteer progress, fostering a culture of responsibility and compassion.',
        },
        {
            Id: '03',
            sImg: simg3,
            sSImg: sSimg3,
            title: 'Impact Measurement',
            slug: '3',
            description: 'Track volunteer hours, completed tasks, and the social change your company creates to report on CSR goals and achievements.'

        },
        {
            Id: '04',
            sImg: simg4,
            sSImg: sSimg4,
            title: 'Recognition and Rewards',
            slug: '4',
            description: 'Gain visibility for your company’s volunteer efforts through our public leaderboard and achievements system, building brand value and loyalty.',
        },

    ]
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-10 col-md-12 col-12">
                    <SectionTitle title="Why Register with Sevak Army?" />
                    <p className='text-center'>When you join Sevak Army, you gain access to a suite of features designed to maximise your CSR impact.</p>
                </div>
            </div>
            <div className="service-wrap mt-3">
                <div className="row justify-content-center">
                    {Services.slice(0, 5).map((Service, sitem) => (
                        <div className="col col-lg-3 col-md-6 col-sm-6 col-12" key={sitem}>
                            <div className="service-card-wrapper d-flex flex-column h-100">
                                <ServiceCard title={Service.title} img={Service.sImg} slug={Service.slug} sdescription={Service.description} />
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <p className='text-center'>Register today and take the first step.</p>
        </div>
    )
}

export default RegisterSevakArmy