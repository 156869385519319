import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "../HomePage/HomePage";
import HomePage2 from "../HomePage2/HomePage2";
import HomePage3 from "../HomePage3/HomePage3";
import HomePage4 from "../HomePage4/HomePage4";
import AboutPage from "../AboutPage/AboutPage";
import GalleryPage from "../GalleryPage/GalleryPage";
import TestimonialPage from "../TestimonialPage/TestimonialPage";
import CausePageOn from "../CausePageOn/CausePageOn";
import CausePagetwo from "../CausePagetwo/CausePagetwo";
import CausePagethre from "../CausePagethre/CausePagethre";
import CauseSinglePage from "../CauseSinglePage/CauseSinglePage";
import VolunteerSinglePage from "../VolunteerSinglePage/VolunteerSinglePage";
import ServicePage from "../ServicePage/ServicePage";
import ServiceSinglePage from "../ServiceSinglePage/ServiceSinglePage";
import DonatePage from "../DonatePage/DonatePage";
import EventPage from "../EventPage/EventPage";
import EventSinglePage from "../EventSinglePage/EventSinglePage";
import BlogPage from "../BlogPage/BlogPage";
import BlogPageLeft from "../BlogPageLeft/BlogPageLeft";
import BlogPageFullwidth from "../BlogPageFullwidth/BlogPageFullwidth";
import BlogDetails from "../BlogDetails/BlogDetails";
import BlogDetailsFull from "../BlogDetailsFull/BlogDetailsFull";
import BlogDetailsLeftSiide from "../BlogDetailsLeftSiide/BlogDetailsLeftSiide";
import ContactPage from "../ContactPage/ContactPage";
// import LoginPage from "../LoginPage/index";
// import Register from "../SignUpPage/index";
import ErrorPage from "../ErrorPage/ErrorPage";
import SchoolCollegeHome from "../School-CollegePage";
import SchoolCollegePages from "../School-CollegePage/Pages";
import OrganizationRegistration from "../Organization/OrganizationRegistration";
import Influencer from "../Influencer/Influencer";
import EmployeeEngagement from "../Organization/EmployeeEngagement";
import Leaderboard from "../Organization/Leaderboard";
import ContactUs from "../Organization/ContactUs";
import OrganizationHome from "../Organization/OrganizationHome";
import Blog from "../Organization/OrganizationBlogs";
import InfluencerBlogPage from "../InfluencerBlogs/InfluencerBlogPage";
import InfluencerContact from "../InfluencerContact/InfluencerContact";
import InfluencerDonation from "../InfluencerDonation/InfluencerDonation";
import InfluencerService from "../InfluencerService/InfluencerService";
import Layout_SEO from "../../components/LayoutSEO";
import ContactUsNgo from "../../components/ContactusNGO/ContactUsNgo";
import NgoLoginRegister from "../../components/NGOLoginRegister/NgoLoginRegister";
import NgoHome from "../../components/NgoHome/NgoHome";
import VolunteerEvents from "../../components/VolunteerEvents/VolunteerEvents";
import Blank from "../../components/404/Blank";
import Volunteerhome from "../../components/Volunteer/Volunteerhome";
import SchoolCollegeEvents from "../School-CollegePage/Events";
import InflunencerPages from "../InfluencerPages/InflunencerPages";
import SchoolBlog from "../School-CollegePage/Blog";
import NgoAbout from "../../components/NgoAbout/NgoAbout";
import VolunteerAboutUs from "../../components/VolunteerAbout/VolunteerAboutUs";
import VolunteerOpportunitiesPage from "../../components/VolunteerOpportunities/VolunteerOpportunitiesPage";
import SchoolContact from "../School-CollegePage/Contact";
import VolunteerBlogsPage from "../../components/VolunteerBlogs/VolunteerBlogsPage";
import Contact from "../../components/Organization/contact";
import VounteerJoinUs from "../../components/VounteerJoinUs/VounteerJoinUs";

import NgoMainInvlved from "../../components/NgoGetInvolved/NgoMainInvlved";
import TermsAndConditions from "../Tearms-Conditions";
import PrivacyPolicy from "../PrivacyPolicy";
import BlogListPagination from "../../components/BlogList/BlogListPagination";
import AuthModal from "../../auth-modal";

const AllRoute = () => {
  const [show, setShow] = useState(false);

  return (
    <div className="App">
      <BrowserRouter>
        <Layout_SEO>
          <Routes>
            {/* <Route path="home" element={<Homepage show={show} setShow={setShow} />} />
            <Route path="home-2" element={<HomePage2 />} />
            <Route path="home-3" element={<HomePage3 />} />
            <Route path="home-4" element={<HomePage4 />} />
            <Route path="about" element={<AboutPage />} /> */}
            {/* <Route path="testimonial" element={<TestimonialPage />} />
            <Route path="causes-s2" element={<CausePagetwo />} />
            <Route path="causes-s3" element={<CausePagethre />} /> */}
            {/* <Route path="causes-single/:slug" element={<CauseSinglePage />} />
            <Route path="volunteer-single" element={<VolunteerSinglePage />} />
            <Route path="service" element={<ServicePage />} />
            <Route path="service-single/:slug" element={<ServiceSinglePage />} /> */}
            {/* <Route path="event" element={<EventPage />} />
            <Route path="event-single/:slug" element={<EventSinglePage />} /> */}
            {/* <Route path="blog" element={<BlogPage />} />
            <Route path="blog-left-sidebar" element={<BlogPageLeft />} />
            <Route path="blog-fullwidth" element={<BlogPageFullwidth />} />
            <Route path="blog-single/:slug" element={<BlogDetails />} />
            <Route path="blog-single-fullwidth/:slug" element={<BlogDetailsFull />} />
            <Route path="blog-single-left-sidebar/:slug" element={<BlogDetailsLeftSiide />} /> */}
            {/* <Route path="forgot" element={<ForgotPassword />} /> */}
            {/* <Route path="causes" element={<CausePageOn />} /> */}
            {/* <Route path="404" element={<ErrorPage />} /> */}
            {/* <Route path="gallery" element={<GalleryPage />} /> */}

            <Route path="/" element={<Homepage show={show} setShow={setShow} />} />
            <Route path="contact-us" element={<ContactPage show={show} setShow={setShow} />} />
            <Route path="donate" element={<DonatePage show={show} setShow={setShow} />} />
            {/* <Route path="login" element={<LoginPage show={show} setShow={setShow} />} /> */}
            {/* <Route path="register" element={<Register show={show} setShow={setShow} />} /> */}
            <Route path="school-college" element={<SchoolCollegeHome show={show} setShow={setShow} />} />
            <Route path="volunteer/events" element={<VolunteerEvents show={show} setShow={setShow} />} />
            <Route path="school-college/about-us" element={<SchoolCollegePages show={show} setShow={setShow} />} />
            <Route path="influencer" element={<Influencer show={show} setShow={setShow} />} />
            <Route path="school-college/donations" element={<DonatePage show={show} setShow={setShow} />} />
            <Route path="school-college/events" element={<SchoolCollegeEvents show={show} setShow={setShow} />} />
            <Route path="school-college/contact-us" element={<SchoolContact show={show} setShow={setShow} />} />
            {/* <Route path="school-college/blogs" element={<SchoolBlog show={show} setShow={setShow} />} /> */}
            <Route path="/ngo/contact-us" element={<ContactUsNgo show={show} setShow={setShow} />} />
            <Route path="/ngo/login" element={<NgoLoginRegister show={show} setShow={setShow} />} />
            <Route path="influencer" element={<Influencer show={show} setShow={setShow} />} />
            {/* <Route path="influencer/blogs" element={<InfluencerBlogPage show={show} setShow={setShow} />} /> */}
            <Route path="influencer/about-us" element={<InflunencerPages show={show} setShow={setShow} />} />
            <Route path="influencer/contact-us" element={<InfluencerContact show={show} setShow={setShow} />} />
            <Route path="influencer/donation" element={<InfluencerDonation show={show} setShow={setShow} />} />
            <Route path="influencer/services" element={<InfluencerService show={show} setShow={setShow} />} />
            <Route path="/ngo" element={<NgoHome show={show} setShow={setShow} />} />
            <Route path="/ngo/about-us" element={<NgoAbout show={show} setShow={setShow} />} />
            <Route path="organization" element={<OrganizationHome show={show} setShow={setShow} />} />
            <Route
              path="organization/registration"
              element={<OrganizationRegistration show={show} setShow={setShow} />}
            />
            <Route
              path="organization/employee-engagement"
              element={<EmployeeEngagement show={show} setShow={setShow} />}
            />
            <Route path="organization/leaderboard" element={<Leaderboard show={show} setShow={setShow} />} />
            <Route path="organization/contact-us" element={<ContactUs show={show} setShow={setShow} />} />
            <Route path="organization/about-us" element={<AboutPage show={show} setShow={setShow} />} />
            {/* <Route path="organization/blogs" element={<Blog show={show} setShow={setShow} />} /> */}
            <Route path="/ngo/get-involved" element={<NgoMainInvlved show={show} setShow={setShow} />} />
            <Route path="volunteer" element={<Volunteerhome show={show} setShow={setShow} />} />
            <Route path="volunteer/about-us" element={<VolunteerAboutUs show={show} setShow={setShow} />} />
            <Route
              path="volunteer/opportunities"
              element={<VolunteerOpportunitiesPage show={show} setShow={setShow} />}
            />
            {/* <Route path="volunteer/blogs" element={<VolunteerBlogsPage show={show} setShow={setShow} />} /> */}
            <Route path="volunteer/contact-us" element={<VounteerJoinUs show={show} setShow={setShow} />} />
            <Route path="terms-and-conditions" element={<TermsAndConditions show={show} setShow={setShow} />} />
            <Route path="privacy-policy" element={<PrivacyPolicy show={show} setShow={setShow} />} />
            <Route path="opportunities/:slug" element={<BlogDetails show={show} setShow={setShow} />} />
          </Routes>
        </Layout_SEO>
        <AuthModal show={show} setShow={setShow} />
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
