import React from "react";

const PrivacyPolicyContent = () => {
  return (
    <div className="container mt-5">
      <style>
        {`
          li {
            justify-content: left;
          }
        `}
      </style>
      <h1 className="w-100 text-center mb-2">Privacy Policy</h1>
      <p>
        At SevakArmy.com, operated by the Jainam Jivika Foundation, we are committed to protecting your privacy and
        ensuring the security of your personal information. Whether you are a volunteer, NGO, school/college, influencer
        or organisation engaging with our platform, this Privacy Policy explains in detail how we collect, use, disclose
        and protect your information. We value your trust and aim to be transparent about our data collection and usage
        practices.
      </p>
      <p>
        This Privacy Policy applies to all services provided through SevakArmy.com and any related websites, mobile
        applications or digital platforms owned or operated by the Jainam Jivika Foundation.
      </p>
      <h2 className="mt-4">1. Information We Collect</h2>
      <p>
        We collect both personal and non-personal information to offer you a tailored experience, provide our services
        effectively and support our mission of enabling volunteerism and community service.
      </p>
      <h3 className="mt-3">a. Personal Information</h3>
      <p>
        Personal information refers to any information that can identify an individual directly or indirectly. The
        personal information we collect may include:
      </p>
      <ul>
        <li>
          <strong>Name:</strong> Your full name is used to personalise your interactions on the platform and to identify
          you to NGOs, schools, organisations and other users.
        </li>
        <li>
          <strong>Contact Information:</strong> This includes email address, phone number and postal address to
          communicate updates, opportunities, donation receipts and responses to inquiries.
        </li>
        <li>
          <strong>Demographic Information:</strong> We may collect your location, age, gender and other demographic
          details to match you with suitable volunteer opportunities better.
        </li>
        <li>
          <strong>Organisation Details:</strong> If you represent an NGO, school or organisation, we may collect details
          such as your organisation’s name, mission, registration details and contact information to connect you with
          relevant volunteers or partners.
        </li>
        <li>
          <strong>Social Media Information:</strong> For influencers and others who engage via social media, we may
          collect your public profiles and other social media details for collaboration purposes.
        </li>
        <li>
          <strong>Volunteer Preferences:</strong> We collect details about your volunteer preferences, interests,
          availability and skills to offer you relevant opportunities.
        </li>
        <li>
          <strong>Donation Information:</strong> If you make a donation, we collect payment information such as
          credit/debit card details, UPI information or bank account information, as well as transaction history for
          processing donations and providing receipts.
        </li>
      </ul>
     <h3 className="mt-3">b. Non-Personal Information</h3>
      <p>
        Non-personal information cannot directly identify you. This type of information is collected through your
        interaction with the platform and helps us optimise and enhance the user experience. This includes:
      </p>
      <ul>
        <li>
          <strong>Device Information:</strong> Your device type, operating system and browser type.
        </li>
        <li>
          <strong>IP Address:</strong> The Internet Protocol address used to connect your device to the internet,
          helping us track your geographical location and ensure security.
        </li>
        <li>
          <strong>Log Data:</strong> Details of how you use the platform, such as the pages you visited, time spent on
          each page and click actions.
        </li>
        <li>
          <strong>Cookies and Similar Technologies:</strong> We use cookies, pixels and beacons to track your
          preferences and activity on our website.
        </li>
      </ul>
      <h2 className="mt-4">2. How We Use Your Information?</h2>
      <p>
        Your personal information is crucial for SevakArmy.com to function effectively and deliver a personalised,
        efficient and secure experience. We use the data collected for the following purposes:
      </p>
     <h3 className="mt-3">a. Service Delivery</h3>
      <ul>
        <li>
          <strong>Volunteer Matching:</strong> To connect you with NGOs, schools/colleges, organisations and other
          entities based on your interests, skills and location.
        </li>
        <li>
          <strong>Project Opportunities:</strong> To enable NGOs, schools/colleges and organisations to post projects
          and find suitable volunteers, influencers or collaborators for community service and development projects.
        </li>
      </ul>
     <h3 className="mt-3">b. Communication</h3>
      <ul>
        <li>
          <strong>Notifications:</strong> To send email or SMS alerts about upcoming volunteer opportunities, projects,
          events and donations.
        </li>
        <li>
          <strong>Customer Support:</strong> To respond to inquiries and support requests from users and resolve any
          platform-related issues.
        </li>
        <li>
          <strong>Surveys & Feedback:</strong> Occasionally, we may send surveys to gather feedback on the platform,
          services or specific volunteer experiences.
        </li>
      </ul>
     <h3 className="mt-3">c. Donation Management</h3>
      <ul>
        <li>
          <strong>Processing Donations:</strong> To securely process your donations and send confirmation and receipt
          emails.
        </li>
        <li>
          <strong>Record Keeping:</strong> To maintain records for legal, tax and audit purposes, including issuing tax
          receipts for donations.
        </li>
      </ul>
     <h3 className="mt-3">d. Platform Improvement</h3>
      <ul>
        <li>
          <strong>Data Analysis:</strong> We analyse user behaviour to improve the website’s design, functionality and
          content. This includes tracking the success of campaigns, identifying trends and improving user engagement.
        </li>
        <li>
          <strong>Personalisation:</strong> To offer a customised experience by providing relevant project suggestions,
          matching you with NGOs or opportunities aligned with your preferences.
        </li>
      </ul>
     <h3 className="mt-3">e. Compliance and Legal Purposes</h3>
      <ul>
        <li>
          <strong>Legal Obligations:</strong> We may process your personal data to comply with any legal obligations,
          such as responding to court orders or regulatory authorities.
        </li>
        <li>
          <strong>Security Measures:</strong> To monitor suspicious activities, prevent fraud and protect against
          cyberattacks or unauthorised access to personal data.
        </li>
      </ul>
      <h2 className="mt-4">3. How We Share Your Information?</h2>
      <p>
        We value your privacy and ensure that your personal information is shared only when necessary. SevakArmy.com
        does not sell or rent your personal data to third parties. However, under certain circumstances, we may share
        your data as follows:
      </p>
     <h3 className="mt-3">a. Sharing with NGOs, Organisations, Schools/Colleges and Influencers</h3>
      <ul>
        <li>
          <strong>Volunteer Matching:</strong> If you register as a volunteer, your name, contact information and skills
          may be shared with registered NGOs, schools or other organisations seeking volunteers for specific projects.
        </li>
        <li>
          <strong>Collaboration Opportunities:</strong> For organisations, schools/colleges and NGOs, your details will
          be shared with suitable volunteers, influencers and partner entities looking for collaboration opportunities.
        </li>
      </ul>
     <h3 className="mt-3">b. Third-Party Service Providers</h3>
      <p>
        We may share your data with third-party service providers such as web hosting providers, payment processors and
        data analytics firms. These service providers have access to your data only for specific tasks on our behalf and
        are bound by confidentiality agreements.
      </p>
     <h3 className="mt-3">c. Legal Requirements and Protection</h3>
      <ul>
        <li>
          <strong>Compliance:</strong> We may disclose your personal information if required to comply with legal
          obligations, government requests or to protect our rights and interests.
        </li>
        <li>
          <strong>Protection:</strong> If we believe that your data is necessary to protect the safety of users, detect
          fraud or address security risks, we may share it with relevant authorities or third-party cybersecurity firms.
        </li>
      </ul>
      <h2 className="mt-4">4. Data Security Measures</h2>
      <p>
        At SevakArmy.com, safeguarding your personal information is a top priority. We implement industry-standard
        security measures to protect your data from unauthorised access, misuse, disclosure or alteration. Our security
        protocols include:
      </p>
      <ul>
        <li>
          <strong>Encryption:</strong> All sensitive information, such as payment data, is encrypted using SSL (Secure
          Socket Layer) technology during transmission.
        </li>
        <li>
          <strong>Access Controls:</strong> Only authorised personnel have access to personal data and they are required
          to maintain confidentiality.
        </li>
        <li>
          <strong>Firewalls and Security Monitoring:</strong> We deploy firewalls and other security monitoring tools to
          prevent unauthorised access and identify potential threats.
        </li>
      </ul>
      <p>
        Although we strive to use commercially acceptable means to protect your personal data, please be aware that no
        method of transmission over the Internet or electronic storage is 100% secure.
      </p>
      <h2 className="mt-4">5. Data Retention</h2>
      <p>
        SevakArmy.com retains personal data only for as long as is necessary to fulfil the purposes outlined in this
        Privacy Policy. Retention periods vary based on the type of data and the purpose of its collection:
      </p>
      <ul>
        <li>
          <strong>Volunteer Data:</strong> Retained for the duration of your active engagement with the platform or
          until you request deletion.
        </li>
        <li>
          <strong>Donation Records:</strong> Retained for legal, tax and accounting purposes, generally for at least
          seven years.
        </li>
        <li>
          <strong>NGO/Organisation Data:</strong> Retained for as long as you are registered with the platform or until
          the termination of your account.
        </li>
      </ul>
      <p>Once your data is no longer needed, it will either be securely deleted or anonymised.</p>
      <h2 className="mt-4">6. Cookies and Tracking Technologies</h2>
      <p>
        SevakArmy.com uses cookies and similar tracking technologies to enhance your experience, deliver personalised
        content and improve the overall functionality of the platform. Cookies are small data files that are placed on
        your device when you visit our site.
      </p>
     <h3 className="mt-3">Types of Cookies We Use:</h3>
      <ul>
        <li>
          <strong>Session Cookies:</strong> Temporary cookies that remain on your device until you close your browser.
        </li>
        <li>
          <strong>Persistent Cookies:</strong> Remain on your device for a set period or until deleted manually.
        </li>
        <li>
          <strong>Third-Party Cookies:</strong> We use third-party services such as Google Analytics to analyse website
          traffic and user behaviour.
        </li>
      </ul>
      <p>
        You can control and manage cookies through your browser settings. However, disabling cookies may limit the
        functionality of the website.
      </p>
      <h2 className="mt-4">7. Your Data Rights</h2>
      <p>SevakArmy.com respects your privacy and provides you with rights over your personal data:</p>
      <ul>
        <li>
          <strong>Access:</strong> You can request a copy of the personal data we hold about you.
        </li>
        <li>
          <strong>Correction:</strong> You have the right to correct any inaccurate or incomplete data.
        </li>
        <li>
          <strong>Deletion:</strong> You can request the deletion of your personal data under certain conditions, such
          as when it is no longer necessary for the purposes for which it was collected.
        </li>
        <li>
          <strong>Data Portability:</strong> You can request a copy of your personal data in a structured, commonly used
          and machine-readable format.
        </li>
        <li>
          <strong>Opt-Out:</strong> You may opt-out of marketing communications and newsletters by clicking the
          ‘unsubscribe’ link in our emails.
        </li>
      </ul>
      <p>To exercise any of these rights, please contact us at the address provided below.</p>
      <h2 className="mt-4">8. Third-Party Links</h2>
      <p>
        SevakArmy.com may include links to third-party websites or services. We do not operate or control these external
        sites and we are not responsible for their content, privacy policies or data practices. We recommend reviewing
        the privacy policies of any third-party websites before providing them with your personal information.
      </p>
      <h2 className="mt-4">9. Children’s Privacy</h2>
      <p>
        SevakArmy.com does not knowingly collect personal information from children under the age of 18. If we become
        aware that we have collected data from a child under 18, we will take steps to delete the information promptly.
        Parents or guardians who believe that their child has submitted personal information without consent are
        encouraged to contact us.
      </p>
      <h2 className="mt-4">10. International Data Transfers</h2>
      <p>
        SevakArmy.com is based in India and all personal data is processed within the country. If you are accessing the
        platform from outside India, your data will be transferred to and processed in India, which may have different
        data protection laws than your country of residence.
      </p>
      <p>By using our platform, you consent to the transfer, processing and storage of your data in India.</p>
      <h2 className="mt-4">11. Changes to This Privacy Policy</h2>
      <p>
        SevakArmy.com reserves the right to update or modify this Privacy Policy at any time. Changes will be reflected
        on this page with an updated ‘Last Updated’ date. In the event of significant changes, we will notify users via
        email or a prominent notice on the platform. We encourage users to review this policy periodically to stay
        informed about how we are protecting personal data.
      </p>
      <h2 className="mt-4">12. Contact Information</h2>
      <p>For any questions, concerns or to exercise your data rights, please contact us at:</p>
      <p>
        Email: <a href="mailto:info@jjfindia.com">info@jjfindia.com</a>
      </p>
      <p>
        By using SevakArmy.com, you agree to this Privacy Policy and consent to the collection, use and sharing of your
        information as described.
      </p>
    </div>
  );
};

export default PrivacyPolicyContent;
