import React from "react";
import { Link } from "react-router-dom";
// images
import blogImg1 from "../../images1/ngo/home/sevak-army-ngo-home-8.png";
import blogImg2 from "../../images1/ngo/home/sevak-army-ngo-home-9.jpg";
import blogImg3 from "../../images1/ngo/home/sevak-army-ngo-home-10.jpg";
import blogImg4 from "../../images1/ngo/home/sevak-army-ngo-home-11.jpg";
import blogImg5 from "../../images1/ngo/home/sevak-army-ngo-home-12.jpg";
import blogImg6 from "../../images1/ngo/home/sevak-army-ngo-home-13.jpg";

import blog1 from "../../images/blog/single/img-1.jpg";
import blog2 from "../../images/blog/single/img-2.jpg";
import blog3 from "../../images/blog/single/img-3.jpg";

import recent1 from "../../images/recent-posts/img-1.jpg";
import recent2 from "../../images/recent-posts/img-2.jpg";
import recent3 from "../../images/recent-posts/img-3.jpg";

import blogSingleImg1 from "../../images/blog/1.jpg";
import blogSingleImg2 from "../../images/blog/2.jpg";
import blogSingleImg3 from "../../images/blog/3.jpg";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

function NgoBlockHome() {
  const blogs = [
    {
      id: "1",
      title: "Harnessing Community Power: Volunteering Transforms Lives",
      slug: "1",
      subtitle:
        "Explore how volunteerism can drive significant social change and the impact it has on individuals and communities.",
      create_at: "5th September 2024",
      screens: blogImg1,
      bsing: blog1,
      recent: recent1,
      blogSingleImg: blogSingleImg1,
      comment: "35",
      day: "28",
      month: "AUGUST",
      blClass: "format-standard-image",
      athor: "Sanket Kamble",
    },
    {
      id: "2",
      title: "Sevak Army Launches New Environmental Campaign",
      slug: "2",
      subtitle:
        " Discover the details of our latest environmental campaign aimed at promoting sustainability and reducing waste.",
      create_at: "25th January, 24",
      screens: blogImg2,
      bsing: blog2,
      recent: recent2,
      blogSingleImg: blogSingleImg2,
      comment: "35",
      day: "28",
      month: "AUGUST",
      blClass: "format-standard-image",
      athor: "Rishi Rathod",
    },
  ];
  const blogs2 = [
    {
      id: "1",
      title: "Turning Tides: How a Clean-Up Drive Revitalised Local Parks?",
      slug: "1",
      subtitle:
        "Read about the transformative effects of our recent clean-up drive and how it has improved local green spaces.",
      create_at: "5th September 2024",
      screens: blogImg3,
      bsing: blog1,
      recent: recent1,
      blogSingleImg: blogSingleImg1,
      comment: "35",
      day: "28",
      month: "AUGUST",
      blClass: "format-standard-image",
      athor: "Sanket Kamble",
    },
    {
      id: "2",
      title: "Meet Maria: A Dedicated Volunteer Who Made a Difference",
      slug: "2",
      subtitle:
        " Get inspired by Maria’s journey as a volunteer and her contributions to our educational outreach programmes.",
      create_at: "25th January, 24",
      screens: blogImg4,
      bsing: blog2,
      recent: recent2,
      blogSingleImg: blogSingleImg2,
      comment: "35",
      day: "28",
      month: "AUGUST",
      blClass: "format-standard-image",
      athor: "Rishi Rathod",
    },
  ];
  const blogs3 = [
    {
      id: "1",
      title: "From Struggle to Success: Empowering Youth Through Education",
      slug: "1",
      subtitle:
        "Discover how our educational programmes have empowered young people and provided them new opportunities.",
      create_at: "5th September 2024",
      screens: blogImg5,
      bsing: blog1,
      recent: recent1,
      blogSingleImg: blogSingleImg1,
      comment: "35",
      day: "28",
      month: "AUGUST",
      blClass: "format-standard-image",
      athor: "Sanket Kamble",
    },
    {
      id: "2",
      title: "Revitalising Rural Areas: The Success of Our Health Camps",
      slug: "2",
      subtitle:
        " Learn about the success of our health camps in rural areas and how they have improved access to healthcare services.",
      create_at: "25 Jan, 24",
      screens: blogImg6,
      bsing: blog2,
      recent: recent2,
      blogSingleImg: blogSingleImg2,
      comment: "35",
      day: "28",
      month: "AUGUST",
      blClass: "format-standard-image",
      athor: "Rishi Rathod",
    },
  ];
  return (
    <>
      {/* 1-block */}
      <section className="wpo-blog-section-s2 section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-12">
              <div className="wpo-section-title mb-0">
                <span>From The Blog</span>
                <h2>Our Latest News and Articles </h2>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            {blogs.slice(0, 3).map((blog, bl) => (
              <div className="col-lg-6 col-md-6 col-12" key={bl}>
                <div className="blog-single-card">
                  <div className="image text-center">
                    <img
                      className="img-fluid"
                      style={{
                        maxHeight: 400,
                        width: "auto",
                      }}
                      src={blog.screens}
                      alt=""
                    />
                  </div>
                  <div className="content">
                    <span>{blog.create_at}</span>
                    <h3>{blog.title}</h3>
                    <p>{blog.subtitle}</p>
                  </div>
                  {/* <Link className="theme-btn" onClick={ClickHandler} to={`/blog-single/${blog.slug}`}>
                    Read More
                  </Link> */}
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* block-2 */}
        <div className="container section-padding">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-12">
              <div className="wpo-section-title">
                <span></span>
                <h2>Stories of Impact</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {blogs2.slice(0, 3).map((blog, bl) => (
              <div className="col-lg-6 col-md-6 col-12" key={bl}>
                <div className="blog-single-card">
                  <div className="image text-center">
                    <img
                      className="img-fluid"
                      style={{
                        maxHeight: 400,
                        width: "auto",
                      }}
                      src={blog.screens}
                      alt=""
                    />
                  </div>
                  <div className="content">
                    <span>{blog.create_at}</span>
                    <h3>{blog.title}</h3>
                    <p>{blog.subtitle}</p>
                  </div>
                  {/* <Link className="theme-btn" onClick={ClickHandler} to={`/blog-single/${blog.slug}`}>
                    Read More
                  </Link> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default NgoBlockHome;
