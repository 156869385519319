import React from "react";
import FeatureSection from "../../components/FeatureSection/FeatureSection";
import Navbar from "../../components/Navbar/Navbar";
import About from "../../components/about/about";
import CausesSecion from "../../components/CausesSecion/CausesSecion";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import EventSection from "../../components/EventSection/EventSection";
import FunFact from "../../components/FunFact/FunFact";
import Logo from "../../images/logo.svg";
import TestimonialSection from "../../components/TestimonialSection/TestimonialSection";
import InstagramSection from "../../components/InstagramSection/InstagramSection";
import BlogSection from "../../components/BlogSection/BlogSection";
import CtaSection from "../../components/CtaSection/CtaSection";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
// import Hero from "../../components/hero/hero";
import Hero from "../../components/hero2/Hero2";
import Hero3 from "../../components/hero3/hero3";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay } from "swiper";

/* team imags */
import team1 from "../../images1/team/sevak-army-team-1.jpg";
import team2 from "../../images1/team/sevak-army-team-7.jpg";
import team3 from "../../images1/team/sevak-army-team-3.jpg";
import team4 from "../../images1/team/sevak-army-team-6.jpg";
import team5 from "../../images1/team/sevak-army-team-5.jpg";

/* HeroSlide Image  */
import HeroSlide1 from "../../images1/school-college/home/sevak-army-school-college-home-slider-1.jpg";
import HeroSlide2 from "../../images1/school-college/home/sevak-army-school-college-home-slider-2.jpg";
import HeroSlide3 from "../../images1/school-college/home/sevak-army-school-college-home-slider-3.jpg";
import HeroSlide4 from "../../images1/school-college/home/sevak-army-school-college-home-slider-4.jpg";
import abImg1 from "../../images1/school-college/home/sevak-army-school-college-home-2.jpg";
import abImg2 from "../../images1/school-college/home/sevak-army-school-college-home-3.jpg";
import abImg3 from "../../images1/school-college/home/sevak-army-school-college-home-12.png";
import abImg4 from "../../images1/school-college/home/sevak-army-influencer-services-12.png";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import ServiceCard from "../../components/ServiceSection/ServiceCard";
import simg1 from "../../images/service/1.svg";
import simg2 from "../../images/service/2.svg";
import simg3 from "../../images/service/3.svg";
import simg4 from "../../images/service/4.svg";

import sSimg1 from "../../images/service-single/img1.jpg";
import sSimg2 from "../../images/service-single/img2.jpg";
import sSimg3 from "../../images/service-single/img3.jpg";
import sSimg4 from "../../images/service-single/img4.jpg";

const SchoolCollegeHome = (props) => {
  const Services = [
    {
      Id: "01",
      sImg: simg1,
      sSImg: sSimg1,
      title: "Regular Updated Opportunities",
      slug: "1",
      description:
        "Sevak Army constantly refreshes the platform with new volunteer tasks and projects for individuals to engage in.",
    },
    {
      Id: "02",
      sImg: simg2,
      sSImg: sSimg2,
      title: "Diverse Tasks Available With Us",
      slug: "2",
      description:
        "Volunteers can choose from a wide range of options, including short-term and long-term commitments, tailored to various skills.",
    },
    {
      Id: "03",
      sImg: simg3,
      sSImg: sSimg3,
      title: "Frequent Postings by Schools and Colleges",
      slug: "3",
      description:
        "Educational institutions can regularly post new opportunities and also attracting volunteers to support their initiatives.",
    },
    {
      Id: "04",
      sImg: simg4,
      sSImg: sSimg4,
      title: "Continuous Engagement With All",
      slug: "4",
      description:
        "The platform ensures a steady stream of activities, giving volunteers ample opportunities to contribute to social good.",
    },
    {
      Id: "05",
      sImg: simg1,
      sSImg: sSimg1,
      title: "Continuous Engagement",
      slug: "5",
      description:
        "The platform ensures a steady stream of activities, giving volunteers ample opportunities to contribute to social good.",
    },
  ];

  const Services1 = [
    {
      Id: "01",
      sImg: simg1,
      sSImg: sSimg1,
      title: "Community of Change-makers",
      slug: "1",
      description:
        "Sevak Army is not just a platform but a community dedicated to creating social impact through collaboration of everyone.",
    },
    {
      Id: "02",
      sImg: simg2,
      sSImg: sSimg2,
      title: "Empowering Students and Staff",
      slug: "2",
      description:
        "Schools and colleges can empower their students and staff by engaging them in volunteer work that benefits society.",
    },
    {
      Id: "03",
      sImg: simg3,
      sSImg: sSimg3,
      title: "Creating Opportunities for Social Impact",
      slug: "3",
      description:
        "Educational institutions can leverage Sevak Army to create opportunities for communities to participate in socially impactful projects.",
    },
    {
      Id: "04",
      sImg: simg4,
      sSImg: sSimg4,
      title: "Streamlined and Simplified Process",
      slug: "4",
      description:
        "Sevak Army simplifies the volunteering process by offering tools for task posting, volunteer matching and progress tracking.",
    },
  ];

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
  };

  const featuresData = [
    {
      iconClass: "flaticon-volunteer",
      title: "Volunteer Management",
      description: "Easily manage and connect with volunteers for events, community service and educational programs.",
    },
    {
      iconClass: "flaticon-solidarity",
      title: "Collaboration With Schools",
      description:
        "Partner with schools and colleges for social initiatives and student involvement in real-world projects.",
    },
    {
      iconClass: "flaticon-charity",
      title: "Skill Development",
      description: "Access to a variety of skill-building programs, workshops and training sessions for students.",
    },
  ];

  const featuresData1 = [
    {
      iconClass: "flaticon-volunteer",
      title: "Real-Time Alerts",
      description:
        "Stay updated with notifications about upcoming events, community drives and volunteer opportunities near you.",
    },
    {
      iconClass: "flaticon-solidarity",
      title: "Diverse Opportunities",
      description:
        "Whether it’s a blood donation drive, or tree plantation event, Sevak Army ensures you're always informed.",
    },
    {
      iconClass: "flaticon-charity",
      title: "Never Miss An Opportunity",
      description: "Timely alerts help you stay connected and contribute to meaningful causes and projects.",
    },
    {
      iconClass: "flaticon-chatting",
      title: "Tangible Impact",
      description:
        "Volunteers can make a significant difference in society while gaining valuable experiences through active participation.",
    },
    {
      iconClass: "flaticon-crib-toy",
      title: "Support For Schools",
      description:
        "Educational institutions can rely on these alerts to attract dedicated volunteers, ensuring smooth and successful event execution.",
    },
  ];

  return (
    <>
      <Navbar Logo={Logo} hclass={"wpo-site-header"} />
      {/* Hero3 */}
      <section className="static-hero-s3">
        <div className="hero-container">
          <div className="hero-inner">
            <div className="container-fluid">
              <div className="hero-content">
                <div className="slide-title-sub titleeee">
                  <h6>Connecting Schools, Colleges and Volunteers for a Better Tomorrow</h6>
                </div>
                <div className="slide-title titleeee2">
                  <h2>Building Bridges, Creating Impact.</h2>
                </div>
                <div className="slide-text slideee-texttt">
                  <p>
                    Your Trusted Partner in Connecting Schools and Colleges. Sevak Army is a bridge linking schools,
                    colleges and volunteers. We empower institutions to make a difference through volunteerism and
                    social responsibility. Our platform simplifies the process, making it easy for institutions to
                    contribute to society while gaining invaluable experience and recognition.
                  </p>
                </div>
                <div className="hero-btn">
                  <Link onClick={() => props.setShow(true)} to="#" className="theme-btn">
                    Join Our Team
                  </Link>
                  <div className="wpo-supporter-img">
                    <ul className="wpo-supporter-slide">
                      <Slider {...settings}>
                        <li data-bs-toggle="tooltip" data-bs-html="true" title="Aliza Anny">
                          <img src={team1} alt="" />
                        </li>
                        <li data-bs-toggle="tooltip" data-bs-html="true" title="David Miller">
                          <img src={team2} alt="" />
                        </li>
                        <li data-bs-toggle="tooltip" data-bs-html="true" title="Maria Silva">
                          <img src={team3} alt="" />
                        </li>
                        <li data-bs-toggle="tooltip" data-bs-html="true" title="Takila Mas">
                          <img src={team4} alt="" />
                        </li>
                        <li data-bs-toggle="tooltip" data-bs-html="true" title="Jenny Lawba">
                          <img src={team5} alt="" />
                        </li>
                      </Slider>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Swiper
          modules={[Autoplay, Navigation, A11y]}
          spaceBetween={0}
          slidesPerView={1}
          loop={true}
          speed={1000}
          parallax={true}
          navigation
          pagination={{ clickable: true }}
          autoplay={{
            delay: 1800,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
        >
          <SwiperSlide>
            <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${HeroSlide1})` }}></div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${HeroSlide2})` }}></div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${HeroSlide3})` }}></div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${HeroSlide4})` }}></div>
          </SwiperSlide>
          ...
        </Swiper>
      </section>

      {/* <FeatureSection /> */}
      <section className="wpo-features-area">
        <div className="container">
          <div className="features-wrap">
            <div className="row">
              {featuresData.map((feature, index) => (
                <div className="col col-lg-4 col-md-6 col-12" key={index}>
                  <div className="feature-item-wrap">
                    <div className="feature-item" style={{ alignItems: "start" }}>
                      <div className="feature-icon">
                        <div className="icon">
                          <i className={`fi ${feature.iconClass}`}></i>
                        </div>
                      </div>
                      <div className="feature-text">
                        <h2 className="fs-5">{feature.title}</h2>
                        <p className="fs-6">{feature.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* <About /> */}

      <section className="wpo-about-section section-padding">
        <section className="section-padding">
          <div className="container">
            <div className="wpo-about-wrap">
              <div className="row ">
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="wpo-about-img">
                    {/* <div className="wpo-about-left">
                    <img src={abImg2} alt="img" />
                  </div> */}
                    <div className="wpo-about-right pt-0">
                      <img src={abImg1} alt="img" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="wpo-about-text">
                    <div className="wpo-section-title">
                      <span>Who Are We?</span>
                      <h6 className="fw-bold display-5">
                        Empowering Education: Connecting Schools, Colleges And Volunteers For A Brighter Future
                      </h6>
                    </div>
                    <p>
                      Sevak Army is an engaging platform created to simplify collaboration between schools, colleges and
                      volunteers. It offers an easy and efficient way for schools and colleges to post tasks, find the
                      right volunteers and track progress.
                    </p>

                    <div className="about-info-wrap pb-0">
                      <div className="about-info-item me-2">
                        <div className="about-info-icon">
                          <div className="icon">
                            <i className="fi flaticon-target"></i>
                          </div>
                        </div>
                        <div className="about-info-text ">
                          <h4>Our Mission</h4>
                          <p className="mission-paragraph volunteer-home-text ">
                            At Sevak Army, our mission is to create a sustainable platform that bridges the gap between
                            schools, colleges, volunteers and non-governmental organizations.{" "}
                          </p>
                        </div>
                      </div>
                      <div className="about-info-item ms-2">
                        <div className="about-info-icon">
                          <div className="icon">
                            <i className="fi flaticon-mountain"></i>
                          </div>
                        </div>
                        <div className="about-info-text">
                          <h4>Our Vision</h4>
                          <p className="vision-paragraph volunteer-home-text">
                            Our vision is to create a world where volunteering is accessible and rewarding for everyone,
                            regardless of background, location or skill set.
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <Link onClick={ClickHandler} to="/about" className="theme-btn">
                    Discover More
                  </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="wpo-about-wrap">
              <div className="flex-containerrr justify-content-center">
                <div className="">
                  <div className="wpo-about-text mt-0">
                    <div className="wpo-section-title mt-0">
                      {/* <span>Welcome to Sevak Army!</span> */}
                      <h2 className="about-heading-third text-titleeee"> Join Our Campaign</h2>
                    </div>
                    <p>
                      <b>Volunteer Management:</b> Easily manage and connect with volunteers for events, community
                      service and educational programs.{" "}
                    </p>
                    <p>
                      <b>Collaboration with Schools and Colleges:</b> Partner with schools and colleges for social
                      initiatives, fundraising and student involvement in real-world projects.{" "}
                    </p>
                    <p>
                      <b>Skill Development Programmes:</b> Access to a variety of skill-building programs, workshops and
                      training sessions for students.
                    </p>
                    {/* <h4 className=".services-title-text">It’s simple, it’s effective and it starts with a click.</h4> */}

                    {/* <Link onClick={ClickHandler} to="/school-college/events" className="theme-btn">
                    Know More
                  </Link> */}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="wpo-about-img">
                    {/* <div className="wpo-about-left-side">
                    <img src={abImg3} alt="img" />
                  </div> */}
                    <div className="wpo-about-right pt-0">
                      <img src={abImg4} height={580} alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      {/* <ServiceSection hclass={"wpo-service-section"} /> */}
      <section className={"section-padding " + "wpo-service-section pt-0"}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12 col-12">
              <SectionTitle
                // subtitle="How Does It Work?"
                title="Latest Activities and Volunteer Opportunities"
              />
            </div>
          </div>
          <div className="service-wrap mt-5">
            <div className="row">
              {Services.slice(0, 4).map((Service, sitem) => (
                <div className="col col-lg-3 col-md-6 col-sm-6 col-12" key={sitem}>
                  <ServiceCard
                    title={Service.title}
                    img={Service.sImg}
                    slug={Service.slug}
                    sdescription={Service.description}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <FunFact hclass={"wpo-fun-fact-section"} />

      {/* <ServiceSection hclass={"wpo-service-section"} /> */}

      <section className={"section-padding " + "wpo-service-section"}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12 col-12">
              <SectionTitle subtitle="Why Choose Us?" title="Promoting The Safety And Rights Of Children" />
            </div>
          </div>
          <div className="service-wrap">
            <div className="row">
              {Services1.slice(0, 4).map((Service, sitem) => (
                <div className="col col-lg-3 col-md-6 col-sm-6 col-12" key={sitem}>
                  <ServiceCard
                    title={Service.title}
                    img={Service.sImg}
                    slug={Service.slug}
                    sdescription={Service.description}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Features2 */}
      <section className="wpo-features-area">
        <div className="container">
          <div className="features-wrap">
            <div className="row justify-content-center">
              {featuresData1.map((feature, index) => (
                <div className="col col-lg-4 col-md-6 col-12 mb-5" key={index}>
                  <div className="feature-item-wrap">
                    <div className="feature-item" style={{ alignItems: "start" }}>
                      <div className="feature-icon">
                        <div className="icon">
                          <i className={`fi ${feature.iconClass}`}></i>
                        </div>
                      </div>
                      <div className="feature-text">
                        <h2 className="fs-5">{feature.title}</h2>
                        <p className="fs-6">{feature.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* <BlogSection hclass={"wpo-blog-section-s2 section-padding"} /> */}
      {/* <CtaSection /> */}
      <Footer />
      <Scrollbar />
    </>
  );
};

export default SchoolCollegeHome;
