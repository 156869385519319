import React from "react";
import { Link } from "react-router-dom";

const ServiceCard = ({ img, title, sdescription, slug }) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  return (
    <div className="service-item px-3">
      <div className="icon">
        <img src={img} width={60} alt="img" />
      </div>
      <div className="text">
        <h2 className="services-title-text fw-bold">
          {/* <Link className="services-title-text" to={``} style={{ cursor: "default" }}> */}
          {title}
          {/* </Link> */}
        </h2>
        <p className="service-paragraph volunteer-home-text text-center">{sdescription}</p>
      </div>
    </div>
  );
};

export default ServiceCard;
