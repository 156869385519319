import React from "react";
import { Link } from "react-router-dom";
import abImg1 from "../../images1/ngo/home/sevak-army-ngo-home-4.jpg";
import abImg2 from "../../images1/ngo/home/sevak-army-ngo-home-3.jpg";
import NgoPromotes from "../NgoPromotes/NgoPromotes";

function NgoHomeAbout() {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  return (
    <section className="wpo-about-section section-padding">
      <div className="container">
        <div className="wpo-about-wrap">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-img">
                {/* <div className="wpo-about-left">
                  <img src={abImg2} alt="img" />
                </div> */}
                <div className="wpo-about-right pt-0">
                  <img src={abImg1} alt="img" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-text">
                <div className="wpo-section-title">
                  <span>Welcome to Sevak Army!</span>
                  <h2 className="about-heading-second text-titleeee">Be the Light for Others.</h2>
                </div>
                <p style={{ textAlign: "justify" }}>
                  At Sevak Army, we are committed to harnessing the power of collaboration to foster meaningful change
                  across communities. By working hand-in-hand with NGOs throughout India, we aim to create lasting,
                  transformative impacts in critical areas such as healthcare, education, sustainability and more. Join
                  Sevak Army today, where the synergy between dedicated volunteers and passionate organizations paves
                  the way for a brighter, more sustainable future for all. Together, we can build stronger communities
                  and make a lasting difference
                </p>

                <div className="about-info-wrap">
                  <div className="about-info-item">
                    <div className="about-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-target"></i>
                      </div>
                    </div>
                    <div className="about-info-text">
                      <h4>Mission</h4>
                      <p className="mission-paragraph">Simplifying collaboration for lasting global change. </p>
                    </div>
                  </div>
                  <div className="about-info-item">
                    <div className="about-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-mountain"></i>
                      </div>
                    </div>
                    <div className="about-info-text">
                      <h4>Vision</h4>
                      <p className="vision-paragraph">Uniting for global impact through seamless collaboration.</p>
                    </div>
                  </div>
                </div>

                {/* <Link onClick={ClickHandler} to="/about" className="theme-btn">
                  Discover More
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <NgoPromotes />
      </div>
    </section>
  );
}

export default NgoHomeAbout;
