import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
// import About from '../../components/about/about';
import CausesSecion from '../../components/CausesSecion/CausesSecion';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import CtaSection from '../../components/CtaSection/CtaSection';
import EventSection from '../../components/EventSection/EventSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Logo from '../../images/logo.svg'
import AboutSection from '../../components/about/about-section';
import ServiceSectionAbout from '../../components/ServiceSection/ServiceSection-About';
import CausesSecionS3 from '../../components/CausesSecionS3/CausesSecionS3';
import CausesSecionS3About from '../../components/CausesSecionS3/CausesSecionS3-about';
const AboutPage = () => {
    return (
        <Fragment>
            <Navbar Logo={Logo} hclass={'wpo-site-header'} />
            <PageTitle pageTitle={'About Us'} pagesub={'Organization'} />
            <AboutSection />
            {/* <CausesSecion /> */}
            {/* <ServiceSectionAbout/> */}
            <CausesSecionS3About/>
            {/* <CtaSection /> */}
            {/* <EventSection /> */}
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default AboutPage;
