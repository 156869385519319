import React from "react";
import abImg1 from "../../images1/organization/leaderboard/sevak-army-organization-leaderboard-1.jpg";
import abImg2 from "../../images1/organization/leaderboard/sevak-army-organization-leaderboard-2.jpg";
import abImg3 from "../../images1/organization/leaderboard/sevak-army-organization-leaderboard-3.jpg";
import abImg4 from "../../images1/organization/leaderboard/sevak-army-organization-leaderboard-4.jpg";
import abImg5 from "../../images1/organization/leaderboard/sevak-army-organization-leaderboard-5.jpg";
import abImg6 from "../../images1/organization/leaderboard/sevak-army-organization-leaderboard-6.jpg";
import abImg7 from "../../images1/organization/leaderboard/sevak-army-organization-leaderboard-7.jpg";
import abImg8 from "../../images1/organization/leaderboard/sevak-army-organization-leaderboard-8.jpg";
import abImg9 from "../../images1/organization/leaderboard/sevak-army-organization-leaderboard-9.jpg";
import abImg10 from "../../images1/organization/leaderboard/sevak-army-organization-leaderboard-10.jpg";
import { Link } from "react-router-dom";
import WhyMatter1 from "../Volunteer/WhyMatter1";
import WhyMatter2 from "../Volunteer/WhyMatter2";
import WhyMatter3 from "../Volunteer/WhyMatter3";

const LeaderboardPage = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <>
      <section className="wpo-about-section section-padding ">
        <div className="container ">
          <div className="wpo-about-wrap">
            <div className="flex-containerrr justify-content-center gap-3">
              <div className="w-lg-50 w-100">
                <div className="wpo-about-text">
                  <div className="wpo-section-title">
                    <span>Welcome to Sevak Army!</span>
                    <h2 className="about-heading-third text-titleeee">Recognising Top Performers and Achievements.</h2>
                  </div>
                  <p>
                    At Sevak Army, we believe in celebrating the efforts and achievements of those who contribute to
                    social good. Our Leaderboard feature is designed to highlight and reward both organizations and
                    individual employees who excel in their volunteer efforts. By showcasing top performers, we aim to
                    inspire others, foster a spirit of healthy competition and enhance the overall impact of our
                    collective volunteerism.<br></br>
                    Explore how our Leaderboard can help your organization stand out and motivate your team to achieve
                    even more.
                  </p>
                </div>
              </div>
              <br></br>
              <div className="">
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left-side">
                    <img src={abImg2} alt="img" />
                  </div> */}
                  <div className="wpo-about-right pt-0">
                    <img src={abImg1} width={900} alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="section-padding pt-100">
          <div className="container">
            <div className="wpo-about-wrap">
              <div className="row">
                <div className="col-lg-5 col-md-12 col-12">
                  <div className="wpo-about-img">
                    {/* <div className="wpo-about-left">
                    <img src={abImg4} alt="img" />
                  </div> */}
                    <div className="wpo-about-right pt-0">
                      <img src={abImg3} alt="img" />
                    </div>
                  </div>
                </div>
                <br></br>
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="wpo-about-text">
                    <div className="wpo-section-title">
                      <h2 className="about-heading-second text-titleeee">
                        Leading the Way in Corporate Social Responsibility.
                      </h2>
                    </div>
                    <p>
                      The Top Organisations Leaderboard showcases companies that excel in their commitment to
                      volunteerism and social impact.<br></br>
                      This ranking is based on various metrics, including:<br></br>
                      <b>Volunteer Hours:</b> The total number of hours contributed by employees within a company.
                      Companies that log the highest volunteer hours are recognised for their dedication and
                      involvement. <br></br>
                      <b>Tasks Completed:</b> The number of volunteer tasks successfully completed by employees. This
                      includes everything from community outreach programmes to environmental conservation efforts.
                      <br></br>
                      <b>Overall Impact:</b> A combined measure of volunteer hours and completed tasks, reflecting the
                      overall contribution of an organization to societal good.<br></br>
                      <b>
                        Get your company recognised by actively participating in volunteering opportunities and making a
                        significant impact on society.
                      </b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section>
          <div className="col-12 d-flex align-items-center justify-content-center">
            <div className="col col-lg-8 col-md-10 ">
              <div className="wpo-event-item">
                <div className="wpo-event-details-text w-100">
                  <h2 className="w-100 text-center text-titleeee">Why It Matters?</h2>
                </div>
                <div className="wpo-event-details-wrap">
                  <div className="wpo-event-details-tab">
                    <ul className="nav nav-tabs"></ul>
                  </div>
                  <div className="wpo-event-details-content">
                    <div className="tab-content">
                      <div className="active">
                        <div id="Schedule" className="tab-pane active">
                          <ul>
                            <li className="about-section-list">
                              <p className="about-section-paragraph-list">
                                <strong className="heading-paragraph">Inspiration and Motivation:</strong>
                                Seeing top-performing organizations on the leaderboard serves as inspiration for other
                                companies to enhance their own CSR efforts and engage more deeply in volunteerism.
                              </p>
                            </li>
                            <li className="about-section-list ">
                              <p className="about-section-paragraph-list">
                                <strong className="heading-paragraph">Public Recognition:</strong>
                                Being featured on the leaderboard not only highlights your company’s commitment to
                                social responsibility but also enhances your brand’s reputation among clients, partners
                                and potential employees.
                              </p>
                            </li>
                            <li className="about-section-list">
                              <p className="about-section-paragraph-list">
                                <strong className="heading-paragraph">Benchmarking:</strong>
                                The leaderboard provides a benchmark for organizations to measure their own volunteer
                                efforts against industry peers, helping identify areas for improvement and opportunities
                                for growth.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <WhyMatter1 />

        <section className="section-padding pb-0">
          <div className="container">
            <div className="wpo-about-wrap">
              <div className="flex-containerrr justify-content-center gap-3">
                <div className="w-lg-50 w-100">
                  <div className="wpo-about-text">
                    <div className="wpo-section-title">
                      {/* <span>Welcome to Sevak Army!</span> */}
                      <h2 className="about-heading-third text-titleeee">Honouring Top Volunteers.</h2>
                    </div>
                    <p>
                      The Individual Employee Leaderboard celebrates the outstanding efforts of individual volunteers
                      within organizations.<br></br>
                      This leaderboard is based on:<br></br>
                      <b>Volunteer Hours:</b> The total number of hours an employee has dedicated to volunteering. Top
                      volunteers who contribute significant time and effort are recognised for their commitment.
                      <br></br>
                      <b>Tasks Completed:</b> The number of tasks an employee has successfully completed. This includes
                      various volunteer activities and projects undertaken throughout their engagement.<br></br>
                      <b>Consistency and Engagement:</b> Recognising not just high achievers, but also those who
                      consistently engage in volunteer activities and demonstrate a sustained commitment to making a
                      difference.<br></br>
                      <b>Encourage your employees to participate.</b>
                    </p>
                  </div>
                </div>
                <div className="">
                  <div className="wpo-about-img">
                    {/* <div className="wpo-about-left-side">
                      <img src={abImg5} alt="img" />
                    </div> */}
                    <div className="wpo-about-right pt-0">
                      <img src={abImg6} alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-padding pb-0">
            <WhyMatter2 />
          </div>
        </section>
        <section className="pt-100">
          <div className="container">
            <div className="wpo-about-wrap">
              <div className="row ">
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="wpo-about-img">
                    {/* <div className="wpo-about-left">
                      <img src={abImg8} alt="img" />
                    </div> */}
                    <div className="wpo-about-right pt-0">
                      <img src={abImg7} alt="img" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="wpo-about-text">
                    <div className="wpo-section-title">
                      <h2 className="about-heading-second text-titleeee">Recognising Contributions and Milestones.</h2>
                    </div>
                    <p>
                      Achievements and Badges are a way to formally acknowledge and celebrate the efforts of both
                      organisations and individual employees.<br></br>
                      This feature provides a range of recognition opportunities, including:<br></br>
                      <b>Achievement Milestones:</b> Recognise significant milestones such as reaching a certain number
                      of volunteer hours, completing major tasks or participating in a series of volunteer activities.
                      These milestones are marked with digital badges that can be displayed on profiles.<br></br>
                      <b>Top Performer Badges:</b> Award badges to individuals and organisations that achieve top
                      rankings on the leaderboard. This includes badges for ‘Top Volunteer’, ‘Top Organisation’ and
                      other special recognitions based on performance metrics.<br></br>
                      <b>Custom Badges:</b> Create custom badges for specific achievements or contributions that align
                      with your company’s CSR goals or values. This allows for tailored recognition that reflects your
                      organisation’s unique mission.<br></br>
                      <b>
                        Celebrate your milestones and recognitions with Sevak Army’s achievements and badge system and
                        inspire others to join in the effort to make a positive impact.
                      </b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" section-padding">
            <WhyMatter3 />
          </div>
        </section>
        <div className="container">
          <div className="wpo-about-wrap">
            <div className="flex-containerrr justify-content-center gap-3">
              <div className="">
                <div className="wpo-about-text">
                  <div className="wpo-section-title">
                    {/* <span>Welcome to Sevak Army!</span> */}
                    <h2 className="about-heading-third text-titleeee">How to Get Involved and Achieve Recognition?</h2>
                  </div>
                  <p>
                    <b>Participate Actively:</b> Encourage your employees to engage in a variety of volunteer tasks and
                    contribute as much as possible. The more active your organization and its employees are, the higher
                    you’ll rank on the leaderboards. <br></br>
                    <b>Track Progress:</b> Use our platform’s tracking tools to monitor volunteer hours, task completion
                    and overall progress. Regularly review performance and set goals to improve standings. <br></br>
                    <b>Celebrate and Share:</b> Make a habit of celebrating achievements and sharing success stories
                    within your organization. Recognising and rewarding efforts not only boosts morale but also
                    motivates others to get involved.
                    <b> Get Started Today</b>
                  </p>
                  {/* <div className="d-flex justify-content-center my-5">
                    <Link onClick={()=>()=>setShow(true)} to="#" className="theme-btn ">
                      Get Started Today
                    </Link>
                  </div> */}
                </div>
              </div>
              <div className="">
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left-side">
                    <img src={abImg9} alt="img" />
                  </div> */}
                  <div className="wpo-about-right pt-0">
                    <img src={abImg9} alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LeaderboardPage;
