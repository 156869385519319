

import Cimg1 from '../images1/organization/about-us/sevak-army-organization-about-us-7.jpg'
import Cimg2 from '../images1/organization/about-us/sevak-army-organization-about-us-8.jpg'
import Cimg3 from '../images1/organization/about-us/sevak-army-organization-about-us-9.jpg'
import Cimg4 from '../images1/organization/about-us/sevak-army-organization-about-us-1.jpg'



import Csimg1 from '../images/cause-single/1.jpg'
import Csimg2 from '../images/cause-single/2.jpg'
import Csimg3 from '../images/cause-single/3.jpg'
import Csimg4 from '../images/cause-single/4.jpg'

const causesData = [
    {
        id: 1,
        slug:"1",
        title: "The Thirsty are Waiting For Your Help.",
        category: "Education",
        image: Cimg1,
        Simage: Csimg1,
        raisedAmount: 8000,
        goalAmount: 10000,
        description: "Leoardo et nisl mi at at blandit. Mauris nulla nec iaculis ultrices egestas consequat vitae facilisi dui."
    },
    {
        id: 2,
        slug:"2",
        title: "Changing a lives one meal at a time.",
        category: "Food",
        image: Cimg2,
        Simage: Csimg2,
        raisedAmount: 4000,
        goalAmount: 10000,
        description: "Leoardo et nisl mi at at blandit. Mauris nulla nec iaculis ultrices egestas consequat vitae facilisi dui."
    },
    {
        id: 3,
        slug:"3",
        title: "Let’s be one community in this cause.",
        category: "Education",
        image: Cimg3,
        Simage: Csimg3,
        raisedAmount: 8692,
        goalAmount: 10000,
        description: "Leoardo et nisl mi at at blandit. Mauris nulla nec iaculis ultrices egestas consequat vitae facilisi dui."
    },
    {
        id: 4,
        slug:"4",
        title: "Donate for healthy food",
        category: "Education",
        image: Cimg4,
        Simage: Csimg4,
        raisedAmount: 5000,
        goalAmount: 10000,
        description: "Leoardo et nisl mi at at blandit. Mauris nulla nec iaculis ultrices egestas consequat vitae facilisi dui."
    },
   

];
const causesDataAbout = [
    {
        id: 1,
        slug:"1",
        title: "Provide Education.",
        category: "Education",
        image: Cimg1,
        Simage: Csimg1,
        raisedAmount: 8000,
        goalAmount: 10000,
        description: "Corporations are organizing educational workshops, supporting schools, and providing mentorship to underprivileged students, helping close the educational gap."
    },
    {
        id: 2,
        slug:"2",
        title: "Environmental Sustainability.",
        category: "Food",
        image: Cimg2,
        Simage: Csimg2,
        raisedAmount: 4000,
        goalAmount: 10000,
        description: "Companies are leading environmental initiatives such as tree planting, beach clean-ups and energy conservation projects."
    },
    {
        id: 3,
        slug:"3",
        title: "Donate for healthy food.",
        category: "Education",
        image: Cimg3,
        Simage: Csimg3,
        raisedAmount: 8692,
        goalAmount: 10000,
        description: " From distributing food to volunteering at shelters, businesses are helping to provide basic necessities to those in need, ensuring no one is left behind."
    },
    {
        id: 4,
        slug:"4",
        title: "",
        category: "Education",
        image: Cimg4,
        Simage: Csimg4,
        raisedAmount: 5000,
        goalAmount: 10000,
        description: "Leoardo et nisl mi at at blandit. Mauris nulla nec iaculis ultrices egestas consequat vitae facilisi dui."
    },
   

];

export {causesData,causesDataAbout};
// export default ;