import React from "react";
import { Link } from "react-router-dom";
import abImg1 from "../../images1/organization/about-us/sevak-army-organization-about-us-1.jpg";
import abImg2 from "../../images1/organization/about-us/sevak-army-organization-about-us-22.png";
import abImg3 from "../../images1/organization/about-us/sevak-army-organization-about-us-3.jpg";
import abImg4 from "../../images1/organization/about-us/sevak-army-organization-about-us-4.jpg";
import abImg5 from "../../images1/organization/about-us/sevak-army-organization-about-us-5.jpg";
import abImg6 from "../../images1/organization/about-us/sevak-army-organization-about-us-6.jpg";
import WhyChooseSevak from "../Volunteer/WhyChooseSevak";

const AboutSection = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section className="wpo-about-section">
      <div className="container">
        <div className="wpo-about-wrap">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-img">
                {/* <div className="wpo-about-left">
                  <img src={abImg2} alt="img" />
                </div> */}
                <div className="wpo-about-right">
                  <img src={abImg2} alt="img" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-text">
                <div className="wpo-section-title">
                  <h2 className="about-section-heading-forth">Driving Corporate Social Responsibility with Action.</h2>
                </div>
                <p>
                  At <strong>Sevak Army</strong>, we are more than just a platform we are a movement dedicated to
                  bringing businesses together to create lasting social change through volunteerism. Our organization
                  was founded on the belief that companies, big and small, have the power to uplift communities, solve
                  societal challenges and leave a legacy of compassion. We connect businesses with meaningful volunteer
                  opportunities, helping them embed social responsibility into their core operations We make corporate
                  volunteerism easy, impactful and rewarding for companies and their employees. By partnering with us,
                  organizations are empowered to fulfil their corporate social responsibility (CSR) goals while actively
                  engaging their workforce in activities that make a difference.
                </p>

                {/* <div className="about-info-wrap">
                  <div className="about-info-item">
                    <div className="about-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-target"></i>
                      </div>
                    </div>
                    <div className="about-info-text">
                      <h4>Our Mission</h4>
                      <p className="mission-paragraph">
                        Enable businesses to transform the world around them
                        through organized volunteerism.{' '}
                      </p>
                    </div>
                  </div>
                  <div className="about-info-item">
                    <div className="about-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-mountain"></i>
                      </div>
                    </div>
                    <div className="about-info-text">
                      <h4>Our Vision</h4>
                      <p className="vision-paragraph">
                        Create a future where corporate volunteerism becomes the
                        cornerstone of social change.
                      </p>
                    </div>
                  </div>
                </div>

                <Link
                  onClick={ClickHandler}
                  to="/about"
                  className="theme-btn"
                >
                  Discover More
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="container section-padding">
        <div className="wpo-about-wrap">
          <div className="flex-containerrr justify-content-center gap-5">
            <div className="">
              <div className="wpo-about-text mt-0" style={{marginLeft:'20px'}}>
                <div className="wpo-section-title">
                  <span>Our Mission</span>
                  <h2 className="about-section-heading-forth">Empowering Companies to Champion Social Good.</h2>
                </div>
                <p>
                  Our mission is simple yet profound: to enable businesses to transform the world around them through
                  organized volunteerism. We aim to create a platform where companies can integrate CSR into their
                  day-to-day operations, contributing to societal change on both local and global scales. Whether it's
                  environmental sustainability, education, healthcare or community upliftment, Sevak Army is your
                  partner in driving meaningful, positive action. <br />
                  At Sevak Army, every company, regardless of size, can contribute to a better world.
                </p>
              </div>
            </div>
            <div className="">
              <div className="wpo-about-img">
                {/* <div className="wpo-about-left-side">
                  <img src={abImg4} alt="img" />
                </div> */}
                <div className="wpo-about-right pt-5">
                  <img src={abImg3} width={800} alt="img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container section-padding pt-0">
        <div className="wpo-about-wrap">
          <div className="row flex-wrap-reverse">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-img">
                {/* <div className="wpo-about-left">
                  <img src={abImg6} alt="img" />
                </div> */}
                <div className="wpo-about-right pt-5">
                  <img src={abImg5} alt="img" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-text p-2 mt-0">
                <div className="wpo-section-title mt-0">
                  <span>Our Vision</span>
                  <h2 className="about-section-heading-fifth text-titleeee">
                    A World Where Corporate Volunteerism Leads to Global Transformation.
                  </h2>
                </div>
                <p>
                  Our vision is to create a future where corporate volunteerism becomes the cornerstone of social
                  change. We see a world where companies lead by example, setting standards of corporate responsibility
                  that inspire others to follow. Through our platform, we envision businesses not only focusing on
                  profits but also on purpose—building a balanced approach to success that includes improving the world
                  we live in. By empowering organizations with the tools and opportunities to give back, we strive to
                  create a ripple effect of positive change that transforms communities and makes the world a better
                  place.
                </p>

                {/* <div className="about-info-wrap">
                  <div className="about-info-item">
                    <div className="about-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-target"></i>
                      </div>
                    </div>
                    <div className="about-info-text">
                      <h4>Our Mission</h4>
                      <p className="mission-paragraph">
                        Enable businesses to transform the world around them
                        through organized volunteerism.{' '}
                      </p>
                    </div>
                  </div>
                  <div className="about-info-item">
                    <div className="about-info-icon">
                      <div className="icon">
                        <i className="fi flaticon-mountain"></i>
                      </div>
                    </div>
                    <div className="about-info-text">
                      <h4>Our Vision</h4>
                      <p className="vision-paragraph">
                        Create a future where corporate volunteerism becomes the
                        cornerstone of social change.
                      </p>
                    </div>
                  </div>
                </div>

                <Link
                  onClick={ClickHandler}
                  to="/about"
                  className="theme-btn"
                >
                  Discover More
                </Link> */}
              </div>
            </div>
            
          </div>
        </div>
      </section>
      <div className="">
      <WhyChooseSevak/>
      </div>

    </section>
  );
};

export default AboutSection;
