import React from "react";
import SectionTitle from "../SectionTitle/SectionTitle";
import { Link } from "react-router-dom";
import Cimg1 from "../../images1/ngo/home/sevak-army-ngo-home-5.jpg";
import Cimg2 from "../../images1/ngo/home/sevak-army-ngo-home-8.png";
import Cimg3 from "../../images1/ngo/home/sevak-army-ngo-home-7.jpg";
import Cimg4 from "../../images1/ngo/home/sevak-army-ngo-home-10.png";
import Csimg1 from "../../images/cause-single/1.jpg";
import Csimg2 from "../../images/cause-single/2.jpg";
import Csimg3 from "../../images/cause-single/3.jpg";
import Csimg4 from "../../images/cause-single/4.jpg";
// import Services from "../../api/Services";

function NgoPromotes() {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const causesData = [
    {
      id: 1,
      slug: "1",
      title: "Connect with skilled volunteers for your mission.",
      category: "Education",
      image: Cimg1,
      Simage: Csimg1,
      raisedAmount: 800,
      goalAmount: 1000,
      description:
        "Connect with skilled professionals and passionate activists through our system, ensuring seamless volunteer onboarding for your mission.",
    },
    {
      id: 2,
      slug: "2",
      title: "Seamless project execution with expert logistical support.",
      category: "Food",
      image: Cimg2,
      Simage: Csimg2,
      raisedAmount: 1500,
      goalAmount: 2000,
      description:
        "Receive expert assistance in project planning, resource allocation, and coordination to ensure smooth, goal-oriented execution daily.",
    },
    {
      id: 3,
      slug: "3",
      title: "Increase visibility, attract volunteers, engage donors.",
      category: "Education",
      image: Cimg3,
      Simage: Csimg3,
      raisedAmount: 2500,
      goalAmount: 3000,
      description:
        "Boost awareness and visibility for your projects through Sevak Army, attracting volunteers, engaging donors and raising impactful awareness.",
    },
    {
      id: 4,
      slug: "4",
      title: "Donate for healthy food",
      category: "Education",
      image: Cimg4,
      Simage: Csimg4,
      raisedAmount: 5000,
      goalAmount: 10000,
      description:
        "Help provide nutritious meals to children in need, supporting their health and well-being. Your contribution ensures access to healthy food, fostering growth and enabling children to focus on their education and future.",
    },
  ];
  return (
    // <section className="wpo-causes-section-s2 section-padding">
    <section className="wpo-causes-section-s2 section-padding pb-0">
      <div className="causes-wrap">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col col-lg-6 col-md-12 col-12">
              <SectionTitle subtitle="" title="What Sevak Army Promotes?" />
            </div>
          </div>
          <div className="row g-0 align-items-center">
            <div className="w-100 d-flex flex-wrap">
              {causesData.slice(0, 4).map((cause, index) => (
                <div key={index} className="col col-lg-6 col-md-12 col-12">
                  <div className="causes-item mb-4">
                    <div className="image">
                      <img src={cause.image} alt={cause.title} width={400}/>
                    </div>
                    <div className="content">
                      <h2>
                        <Link onClick={ClickHandler} to={`/causes-single/${cause.slug}`}>
                          {cause.title}
                        </Link>
                      </h2>
                      <p>{cause.description}</p>
                      <div className="progress-item">
                        <div className="progress">
                          <div
                            className="bar"
                            style={{
                              width: `${(cause.raisedAmount / cause.goalAmount) * 100}%`,
                            }}
                          ></div>
                        </div>
                        <span className="cssProgress-label">
                          {((cause.raisedAmount / cause.goalAmount) * 100).toFixed(2)}%
                        </span>
                        <div className="progres-label">
                          <div className="label-on">
                            <p>
                              Raised: <span>${cause.raisedAmount}</span>
                            </p>
                          </div>
                          <div className="label-two">
                            <p>
                              Goal: <span>${cause.goalAmount}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

        </div>
      </div>
      {/* Waht we do */}
      {/* <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12 col-12">
              <SectionTitle subtitle="What We Do?" title="Together for a stronger impact." />
            </div>
          </div>
          </div> */}
    </section>
  );
}

export default NgoPromotes;
