import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay } from "swiper";
import CountUp from "react-countup";
import Shape from "../../images/testimonial/bg.png";

/* team imags */
import team1 from "../../images1/team/sevak-army-team-1.jpg";
import team2 from "../../images1/team/sevak-army-team-2.jpg";
import team3 from "../../images1/team/sevak-army-team-7.jpg";
import team4 from "../../images1/team/sevak-army-team-8.jpg";
import team5 from "../../images1/team/sevak-army-team-9.jpg";

/* HeroSlide Image  */
import HeroSlide1 from "../../images1/influencer/home/sevak-army-influencer-home-slider-1.jpg";
import HeroSlide2 from "../../images1/influencer/home/sevak-army-influencer-home-slider-2.jpg";
import HeroSlide3 from "../../images1/influencer/home/sevak-army-influencer-home-slider-3.jpg";
import HeroSlide4 from "../../images1/influencer/home/sevak-army-influencer-home-slider-4.png";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Logo from "../../images/logo.svg";
import simg1 from "../../images/service/1.svg";
import simg2 from "../../images/service/2.svg";
import simg3 from "../../images/service/3.svg";
import simg4 from "../../images/service/4.svg";

import sSimg1 from "../../images/service-single/img1.jpg";
import sSimg2 from "../../images/service-single/img2.jpg";
import sSimg3 from "../../images/service-single/img3.jpg";
import sSimg4 from "../../images/service-single/img4.jpg";

import ServiceCard from "../../components/ServiceSection/ServiceCard";
import SectionTitle from "../../components/SectionTitle/SectionTitle";

import blogSingleImg1 from "../../images/blog/1.jpg";
import blogSingleImg2 from "../../images/blog/2.jpg";
import blogSingleImg3 from "../../images/blog/3.jpg";

import recent1 from "../../images/recent-posts/img-1.jpg";
import recent2 from "../../images/recent-posts/img-2.jpg";
import recent3 from "../../images/recent-posts/img-3.jpg";

import blog1 from "../../images1/team/sevak-army-team-1.jpg";
import blog2 from "../../images1/team/sevak-army-team-2.jpg";
import blog3 from "../../images1/team/sevak-army-team-8.jpg";

import blogImg1 from "../../images1/influencer/home/sevak-army-influencer-home-1.jpg";
import blogImg2 from "../../images1/influencer/home/sevak-army-influencer-home-2.jpg";
import blogImg3 from "../../images1/influencer/home/sevak-army-influencer-home-3.jpg";

import image1 from "../../images1/team/sevak-army-team-4.jpg";
import image2 from "../../images1/team/sevak-army-team-6.jpg";
import image3 from "../../images1/team/sevak-army-team-7.jpg";

import AnchorLink from "react-anchor-link-smooth-scroll";
import "../../style.css";

const causesData = [
  {
    id: 1,

    title: "#EducationForAll  Campaign",

    description:
      "Influencers teamed up with NGOs to provide scholarships, learning materials and mentorship for underprivileged children. Their stories inspired support and demonstrated how social influence drives real educational change.",
  },
  {
    id: 2,

    title: "#CleanAirForIndia Campaign",

    description:
      "Environmental advocates and influencers worked together to raise awareness about air pollution. This campaign promoted cleaner air through education and local action and inspired community involvement.",
  },
  {
    id: 3,

    title: "#EmpowerWomenNow Campaign",

    description:
      "Focused on vocational training and financial literacy, this campaign empowered women in underserved communities. Influencers shared stories that motivated their audiences to support women's independence.",
  },
];

const ClickHandler = () => {
  window.scrollTo(10, 0);
};
const Services = [
  {
    Id: "01",
    sImg: simg1,
    sSImg: sSimg1,
    title: "Kids Education",
    slug: "1",
    description:
      "Through influencers, we raise awareness and funds for scholarships, learning materials and mentorship programmes, enabling underprivileged children to thrive.",
  },
  {
    Id: "02",
    sImg: simg2,
    sSImg: sSimg2,
    title: " Pure Water",
    slug: "2",
    description:
      " Our campaigns promote clean water initiatives, ensuring safe drinking water access for vulnerable communities.",
  },
  {
    Id: "03",
    sImg: simg3,
    sSImg: sSimg3,
    title: "Healthy Food",
    slug: "3",
    description: " Influencers highlight nutrition programmes providing healthy meals to underserved populations.",
  },
  {
    Id: "04",
    sImg: simg4,
    sSImg: sSimg4,
    title: "Medical Care",
    slug: "4",
    description:
      "We collaborate with influencers to support healthcare initiatives, bringing essential medical supplies and care to those in need.",
  },
  {
    Id: "05",
    sImg: simg4,
    sSImg: sSimg4,
    title: "Driving Real-World Impact",
    slug: "4",
    description: "Your contributions empower influencers to amplify life-changing causes. Help drive social impact.",
  },
  {
    Id: "06",
    sImg: simg4,
    sSImg: sSimg4,
    title: "Help Us Uplift Lives",
    slug: "4",
    description:
      "Your contribution makes a world of difference. Give what you can  for uplifting lives by volunteering with us.",
  },
];
const Testimonial = [
  {
    id: "01",
    text: "Sevak Army Influencer helped me use my platform for meaningful causes. Their team ensured the campaigns aligned with my values, making every collaboration deeply impactful. The feedback and real-world impact I've seen have been incredibly rewarding. It's more than just a partnership—it's a mission.",
    title: "Ritika Sharma",
    subtitle: "Social Media Influencer",
    image: image3,
  },
  {
    id: "02",
    text: "Sevak Army Influencer helped us expand our healthcare reach and engage a wider, younger audience. Their coordination and authenticity ensured genuine representation, resulting in greater awareness and increased donations. We couldn’t have achieved this without them.",
    title: "Sunita Rao",
    subtitle: "Co-Founder, NGO",
    image: image2,
  },
  {
    id: "03",
    text: "Sevak Army Influencer transformed our environmental campaign by authentically connecting with audiences. Their influencers boosted our social media engagement by 50%, improving brand awareness across platforms. It was a game-changing collaboration.",
    subtitle: "Brand Partner",
    title: "Rajesh Patel",
    image: image1,
  },
  // {
  //     id: "04",
  //     text: "This is the best agency for non profit activities.I love their work so much. I wish one day they would remove all the poverty from the world. I will be there when they need.",
  //     title: "Albert Flores",
  //     subtitle: "President of Sales",
  //     image: image2
  // },
];
const featuresData = [
  {
    iconClass: "flaticon-volunteer",
    title: "Become an Influencer",
    description: "Alone, your voice is powerful. Together, we can change the world.",
  },
  {
    iconClass: "flaticon-solidarity",
    title: "Join a Campaign",
    description: "Alone, you can raise awareness. Together, we can spark movements.",
  },
  {
    iconClass: "flaticon-charity",
    title: "Support a Cause",
    description: "Alone, your impact is meaningful. Together, we can create lasting change.",
  },
];

const blogs = [
  {
    id: "1",
    title: "How Influencers Are Shaping the Future of Charity and Non-Profit Work?",
    slug: "1",
    subtitle: "Successful Implementation Techniques.",
    create_at: "25 Jan, 24",
    screens: blogImg1,
    bsing: blog1,
    recent: recent1,
    blogSingleImg: blogSingleImg1,
    comment: "35",
    day: "28",
    comments: "5",
    month: "AUGUST",
    blClass: "format-standard-image",
    athor: "Sanket Kamble",
  },
  {
    id: "2",
    title: "How Influencers Can Create Lasting Social Impact Through Collaboration?",
    slug: "2",
    subtitle: " How Companies Are Making a Difference?",
    create_at: "25 Jan, 24",
    screens: blogImg2,
    bsing: blog2,
    recent: recent2,
    blogSingleImg: blogSingleImg2,
    comment: "35",
    day: "28",
    month: "AUGUST",
    comments: "14",
    blClass: "format-standard-image",
    athor: "Rishi Rathod",
  },
  {
    id: "3",
    title: "How Influencers Can Drive Social Change: Top Tips for Maximising Your Impact",
    slug: "3",
    subtitle: "Why Your Company Should Get Involved?",
    create_at: "25 Jan, 24",
    screens: blogImg3,
    bsing: blog3,
    recent: recent3,
    blogSingleImg: blogSingleImg3,
    comment: "35",
    day: "28",
    comments: "48",
    month: "AUGUST",
    blClass: "format-standard-image",
    athor: "Priyanka Ray",
  },
];

const Influencer = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
  };
  const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <i className="flaticon-right-arrow-1"></i>
      </div>
    );
  };

  const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <i className="flaticon-left-arrow"></i>
      </div>
    );
  };
  const settingst = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <Navbar hclass="wpo-site-header wpo-site-header" Logo={Logo} />
      <section className="static-hero-s3">
        <div className="hero-container">
          <div className="hero-inner">
            <div className="container-fluid">
              <div className="hero-content">
                <div className="slide-title-sub">
                  <h6>Join Sevak Army Influencers Today!</h6>
                </div>
                <div className="slide-title">
                  <h2>Leverage Your Influence to Drive Social Impact</h2>
                </div>
                <div className="slide-text slideee-texttt">
                  <p>
                    At Sevak Army, we connect influencers with grassroots movements to drive social impact. By
                    collaborating with us, influencers amplify causes and mobilise resources to uplift underserved
                    communities.
                  </p>
                </div>
                <div className="hero-btn">
                  <Link onClick={() => props.setShow(true)} to="#" className="theme-btn">
                    Join Our Team
                  </Link>
                  <div className="wpo-supporter-img">
                    <ul className="wpo-supporter-slide">
                      <Slider {...settings}>
                        <li data-bs-toggle="tooltip" data-bs-html="true" title="Aliza Anny">
                          <img src={team1} alt="" />
                        </li>
                        <li data-bs-toggle="tooltip" data-bs-html="true" title="David Miller">
                          <img src={team2} alt="" />
                        </li>
                        <li data-bs-toggle="tooltip" data-bs-html="true" title="Maria Silva">
                          <img src={team3} alt="" />
                        </li>
                        <li data-bs-toggle="tooltip" data-bs-html="true" title="Takila Mas">
                          <img src={team4} alt="" />
                        </li>
                        <li data-bs-toggle="tooltip" data-bs-html="true" title="Jenny Lawba">
                          <img src={team5} alt="" />
                        </li>
                      </Slider>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Swiper
          modules={[Autoplay, Navigation, A11y]}
          spaceBetween={0}
          slidesPerView={1}
          loop={true}
          speed={1000}
          parallax={true}
          navigation
          pagination={{ clickable: true }}
          autoplay={{
            delay: 1800,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
        >
          <SwiperSlide>
            <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${HeroSlide1})` }}></div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${HeroSlide2})` }}></div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${HeroSlide3})` }}></div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${HeroSlide4})` }}></div>
          </SwiperSlide>
          ...
        </Swiper>
      </section>

      <section className="wpo-features-area">
        <div className="container">
          <div className="features-wrap">
            <div className="row">
              {featuresData.map((feature, index) => (
                <div className="col col-lg-4 col-md-6 col-12" key={index}>
                  <div className="feature-item-wrap">
                    <div className="feature-item">
                      <div className="feature-icon">
                        <div className="icon">
                          <i className={`fi ${feature.iconClass}`}></i>
                        </div>
                      </div>
                      <div className="feature-text">
                        <h2 className="fs-5">{feature.title}</h2>
                        <p className="fs-6">{feature.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className={"section-padding " + "wpo-service-section s2"}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12 col-12">
              <SectionTitle subtitle="What We Do?" title="Your Support Brings Hope and Change" />
            </div>
          </div>
          <div className="service-wrap">
            <div className="row">
              {Services.map((Service, sitem) => (
                <div className="col  col-md-6 col-sm-6 col-12" key={sitem}>
                  <ServiceCard
                    title={Service.title}
                    img={Service.sImg}
                    slug={Service.slug}
                    sdescription={Service.description}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section style={{ marginTop: "130px" }} className={"wpo-testimonial-section"}>
        <div className="causes-wrap">
          <div className="container-fluid">
            <div className="row">
              {causesData.slice(0, 3).map((cause, cky) => (
                <div className="col col-lg-4 col-md-6 col-12" key={cky}>
                  <div className="causes-item">
                    <div className="content">
                      <h2>{cause.title}</h2>
                      <p>{cause.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className={" section-padding " + "wpo-fun-fact-section-s3"} style={{ marginTop: "20px" }}>
        <div className="container">
          <div className="fun-fact-wrap">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="info">
                  <h3>
                    <span>
                      <CountUp end={20} enableScrollSpy />+
                    </span>
                  </h3>
                  <p> Sevak Army Influencers</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="info">
                  <h3>
                    <span>
                      <CountUp end={10} enableScrollSpy />+
                    </span>
                  </h3>
                  <p> Sevak Army Campaigns</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="info">
                  <h3>
                    <span>
                      <CountUp end={20} enableScrollSpy />+
                    </span>
                  </h3>
                  <p> Sevak Army Partnerships</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="info">
                  <h3>
                    <span>
                      <CountUp end={50} enableScrollSpy />+
                    </span>
                  </h3>
                  <p> Sevak Army Engagements</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={"section-padding " + ""}>
        <div className="container">
          <div className="wpo-testimonial-slider">
            <Slider {...settingst}>
              {Testimonial.map((Testimonial, item) => (
                <div className="testimonial-item" key={item}>
                  <div className="content" style={{ minHeight: 615 }}>
                    <div className="icon">
                      <i className="flaticon-quote"></i>
                    </div>
                    <p>{Testimonial.text}</p>
                    <div className="shape">
                      <img src={Shape} alt="" />
                    </div>
                  </div>
                  <div className="bottom-content">
                    <div className="image">
                      <img src={Testimonial.image} alt="" />
                    </div>
                    <div className="text">
                      <h3>{Testimonial.title}</h3>
                      <span>{Testimonial.subtitle}</span>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
      <section className={"wpo-blog-section-s2 section-padding pt-0"}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12 col-12">
              <SectionTitle subtitle="Blogs" title="Our Most Recent Updates and Insights" />
            </div>
          </div>
          <div className="blog-wrap mt-5 mb-5 pb-5">
            <div className="row">
              {blogs.map((blog, bl) => (
                <div className="col col-lg-4 col-md-6 col-12" key={bl}>
                  <div className="blog-single-card">
                    <div className="image">
                      <img src={blog.screens} alt="" />
                      <div className="top-date">
                        {blog.athor.split(" ")[0]}
                        <div className="shape">
                          <svg width="8" height="9" viewBox="0 0 8 9" fill="none">
                            <path d="M7.99999 9.0001L-9.79769e-05 9.00023L8.00005 0.500149L7.99999 9.0001Z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="content">
                      <ul className="top-content">
                        <li>
                          <div className="image-single">
                            <img src={blog.bsing} alt="" />
                          </div>
                          {blog.athor}
                        </li>
                        <li>
                          <i className="flaticon-chatting"></i>
                          {blog.comments} Comments
                        </li>
                      </ul>
                      <h2 className="pb-5">
                        {/* <Link className="blog-title-home" onClick={ClickHandler} to={`/blog-single/${blog.slug}`}> */}
                        {blog.title}
                        {/* </Link> */}
                      </h2>
                      {/* <p >{blog.subtitle}</p> */}
                    </div>
                    {/* <div className="blog-btn">
                      <Link onClick={ClickHandler} to={`/blog-single/${blog.slug}`}>
                        <span>Read More...</span>
                        <i className="flaticon-right-arrow-2"></i>
                      </Link>
                    </div> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="col-lg-12">
          <div className="header-menu">
            <ul className="smothscroll">
              <li>
                <AnchorLink href="#scrool">
                  <i className="ti-arrow-up"></i>
                </AnchorLink>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Influencer;
