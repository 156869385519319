import React, { Fragment, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { Link, useParams } from "react-router-dom";
import Services from "../../api/Services";
import Footer from "../../components/footer/Footer";

import simg1 from "../../images/service-single/2.jpg";
import simg2 from "../../images/service-single/3.jpg";
import ServiceSidebar from "../../main-component/ServiceSinglePage/sidebar";

const accordionData = [
  {
    title: " How can my NGO partner with Sevak Army?",
    content:
      "To partner with Sevak Army, visit the 'NGO Partnership' page on our website, fill out the application form and our team will reach out to discuss partnership opportunities and next steps.",
  },
  {
    title: "What are the benefits of partnering with Sevak Army?",
    content:
      "By partnering with Sevak Army, NGOs can access a dedicated pool of volunteers, manage tasks through our platform and track volunteer impact efficiently. The partnership also provides visibility and the ability to connect with compassionate individuals who are eager to make a difference.",
  },
  {
    title: " Can my NGO post tasks for volunteers on Sevak Army?",
    content:
      "Yes, once your NGO is registered and approved, you can post tasks for volunteers directly on the platform through the 'NGO Dashboard'. You can set task requirements, deadlines and track volunteer progress.",
  },
  {
    title: "How do we ensure our tasks are completed by volunteers?",
    content:
      "Sevak Army allows you to track task progress and communicate with volunteers directly through the platform. You can also set deadlines and send reminders to ensure tasks are completed on time.",
  },
  {
    title: " Can my NGO provide feedback on volunteer performance?",
    content:
      "Yes, NGOs can provide feedback on volunteers after they complete tasks. This feedback helps improve future volunteer experiences and allows volunteers to grow and improve their skills.",
  },
  {
    title: " How can my NGO track volunteer hours?",
    content:
      "Through the NGO dashboard, you can monitor and approve volunteer hours logged for each task. This helps maintain transparency and ensures accurate tracking of volunteer contributions.",
  },
];

function NgoFaq() {
  const { slug } = useParams();

  const serviceDetails = Services.find((item) => item.slug === slug);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };
  return (
    <section className="wpo-service-single-section mt-0 mb-5 section-padding">
      <div className="container">
        <div className="row mb-5">
          <div className="col-lg-12 col-md-12">
            <div className="wpo-service-single-wrap">
              <div className="wpo-benefits-section">
                <h2>FAQs</h2>
                <div className="row">
                  <div className="col-lg-12 col-12">
                    <div className="wpo-benefits-item">
                      <div className="accordion" id="accordionExample">
                        {accordionData.map((item, index) => (
                          <div className="accordion-item" key={index}>
                            <h3 className="accordion-header" id={`heading${index}`}>
                              <button
                                className={`accordion-button ${activeAccordion === index ? "" : "collapsed"}`}
                                type="button"
                                onClick={() => toggleAccordion(index)}
                                aria-expanded={activeAccordion === index ? "true" : "false"}
                                aria-controls={`collapse${index}`}
                              >
                                {item.title}
                              </button>
                            </h3>
                            <div
                              id={`collapse${index}`}
                              className={`accordion-collapse collapse ${activeAccordion === index ? "show" : ""}`}
                              aria-labelledby={`heading${index}`}
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <p>{item.content}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NgoFaq;
