import React from 'react'
import ServiceCard from "../ServiceSection/ServiceCard";
import simg1 from '../../images/service/university-card.png'
import simg2 from '../../images/service/industry.png'
import simg3 from '../../images/service/earth.png'
import simg4 from '../../images/service/details.png'
import simg5 from '../../images/service/space.png'
import sSimg1 from '../../images/service-single/img1.jpg'
import sSimg2 from '../../images/service-single/img2.jpg'
import sSimg3 from '../../images/service-single/img3.jpg'
import sSimg4 from '../../images/service-single/img4.jpg'
import sSimg5 from '../../images/service-single/img4.jpg'
import SectionTitle from '../SectionTitle/SectionTitle';

function RegisterYourCompany() {

    const Services = [
        {
            Id: '01',
            sImg: simg1,
            sSImg: sSimg1,
            title: 'Company Name',
            slug: '2',
            description: 'Register your business under its official name.',
        },
        {
            Id: '02',
            sImg: simg2,
            sSImg: sSimg2,
            title: 'Industry',
            slug: '1',
            description: 'Tell us which industry you belong to so we can match you with relevant volunteering opportunities.',
        },
        {
            Id: '03',
            sImg: simg3,
            sSImg: sSimg3,
            title: 'CSR Goals',
            slug: '3',
            description: 'Share your company’s CSR vision and the causes that matter most to your organization.'

        },
        {
            Id: '04',
            sImg: simg4,
            sSImg: sSimg4,
            title: 'Company Details',
            slug: '4',
            description: 'Provide contact information and details about your company size to help us tailor opportunities to your business.',
        },
        {
            Id: '05',
            sImg: simg5,
            sSImg: sSimg5,
            title: 'Fill out the Form',
            slug: '4',
            description: 'Once your company is registered, you can invite employees, manage your volunteer efforts and track your social impact—all from a single platform.',
        },

    ]
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-10 col-md-12 col-12">
                    <SectionTitle title="Register Your Company and Make a Difference." />
                    <p className='text-center'>Joining Sevak Army is quick and straightforward. Simply fill out our Company Registration Form with your basic details and we’ll guide you through the rest. Once you’ve completed the form, you’ll gain access to our platform’s full suite of CSR tools and resources.</p>
                </div>
            </div>
            <div className="service-wrap mt-3">
                <div className="row justify-content-center">
                    {Services.slice(0, 5).map((Service, sitem) => (
                        <div className="col col-lg-3 col-md-6 col-sm-6 col-12" key={sitem}>
                            <div className="service-card-wrapper d-flex flex-column h-100">
                                <ServiceCard title={Service.title} img={Service.sImg} slug={Service.slug} sdescription={Service.description} />
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    )
}

export default RegisterYourCompany