import React, { useState } from "react";
import VideoModal from "../ModalVideo/VideoModal";
import SupportersSlider from "./SupportersSlider";

import Image1 from "../../images1/home/sevak-army-home-1.jpg";
import Videobg from "../../images1/home/sevak-army-home-2.jpg";
import ShapeBg from "../../images/slider/stick-man.svg";

const Hero = () => {
  return (
    <section className="static-hero">
      <div className="hero-container">
        <div className="hero-inner">
          <div className="container-fluid responsive-containerrr">
            <div className="hero-content">
              <div className="slide-title-sub">
                <span>Welcome to Sevak Army.</span>
              </div>
              <div className="slide-title">
                <h2 className="slide-heading">Empowering Corporate Volunteerism for Social Change.</h2>
              </div>
              <div className="slide-text">
                <p>
                  At Sevak Army, we believe that businesses have the power to drive positive change in society through
                  corporate social responsibility (CSR) initiatives.
                </p>
              </div>
              <div className="clearfix"></div>
              <div data-swiper-parallax="500" className="slide-video-content">
                <div className="slide-video-img">
                  <img src={Videobg} alt="img" />
                  {/* <VideoModal /> */}
                </div>
                <div className="slide-video-text">
                  <h4 className="slide-video-text-show">A registered commitment today, a brighter future tomorrow</h4>
                  <p>One action sparks change; together we ignite transformation.</p>
                </div>
              </div>
              <div className="charius-pic">
                <div className="charius-pic-main">
                  <img src={Image1} width={800} alt="img" />
                </div>
                {/* <div className="charius-inner"  style={{marginLeft: "800px"}}>
                  <img className="img-fluid" width={500} src={Image2} alt="img"/>
                </div> */}

                <div className="wpo-supporter">
                  <div className="wpo-supporter-text">
                    <div className="icon">
                      <i>
                        <img src={ShapeBg} alt="img" />
                      </i>
                    </div>
                    <div className="content">
                      <h3>120+</h3>
                      <p>Happy Volunteer</p>
                    </div>
                  </div>
                  <SupportersSlider />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
