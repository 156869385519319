import React, { useState } from "react";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Navbar from "../../components/Navbar/Navbar";
import Logo from "../../images/logo.svg";
import abImg1 from "../../images1/school-college/pages/sevak-army-school-college-pages-11.png";
import Instagram from "../../api/Instagram";
import abImg2 from "../../images1/school-college/pages/sevak-army-school-college-pages-2.jpg";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import abImg3 from "../../images1/school-college/pages/sevak-army-school-college-pages-3.jpg";
import abImg4 from "../../images1/school-college/pages/sevak-army-school-college-pages-4.jpg";
import image1 from "../../images1/team/sevak-army-team-4.jpg";
import image2 from "../../images1/team/sevak-army-team-5.jpg";
import image3 from "../../images1/team/sevak-army-team-7.jpg";
import image4 from "../../images1/team/sevak-army-team-9.jpg";
import image5 from "../../images1/team/sevak-army-team-10.jpg";
import Shape from "../../images1/school-college/pages/sevak-army-school-college-pages-8.jpg";
import abut3 from "../../images1/school-college/pages/sevak-army-school-college-pages-5.jpg";
import abutShape3 from "../../images1/school-college/pages/sevak-army-school-college-pages-6.jpg";
import blogs from "../../api/blogs";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import Cta1 from "../../images1/school-college/pages/sevak-army-school-college-pages-11.jpg";
import Cta2 from "../../images1/school-college/pages/sevak-army-school-college-pages-12.jpg";
import Cta3 from "../../images1/school-college/pages/sevak-army-school-college-pages-13.jpg";
import About4 from "../../images1/school-college/pages/sevak-army-school-college-pages-6.jpg";
import About4s2 from "../../images1/school-college/pages/sevak-army-school-college-pages-15.jpg";
/* team imags */
import team1 from "../../images1/team/sevak-army-team-5.jpg";
import team2 from "../../images1/team/sevak-army-team-6.jpg";
import team3 from "../../images1/team/sevak-army-team-7.jpg";
import team4 from "../../images1/team/sevak-army-team-10.jpg";
import team5 from "../../images1/team/sevak-army-team-9.jpg";
import PageTitle from "../../components/pagetitle/PageTitle";
import TaskOverview from "../../components/Volunteer/TaskOverview";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const TestimonialData = [
  {
    id: "01",
    text: "Sevak Army recently partnered with a group of volunteers and colleges to support schools in rural areas that were struggling with a shortage of teachers. A college in Maharashtra took the lead, engaging its students to volunteer as educators. Over a period of three months, the volunteers provided lessons in subjects ranging from maths to science, ensuring that the students in these remote schools received the education they deserved. Through Sevak Army’s platform, the college was able to efficiently coordinate the efforts of its student volunteers, track their progress and ensure accountability. The impact was profound – not only did the students in these rural schools improve academically, but the volunteers also gained valuable teaching experience and a sense of fulfilment from helping others.",
    title: "CSR Manager",
    subtitle: "Manager",
    image: image1,
  },
  {
    id: "02",
    text: "A large college in Pune wanted to engage its students in an environmental initiative and with Sevak Army’s help, they organised a community clean-up drive in collaboration with a local NGO. The platform made it easy for the college to post the task and attract volunteers. Over 200 students signed up to participate in the clean-up, which targeted several areas around the city. The event was a huge success, with volunteers collecting over two tonnes of rubbish, planting trees and raising awareness about the importance of keeping the environment clean. The college used Sevak Army’s tracking tools to monitor the impact of the event and celebrate the hard work of the volunteers, who were proud to contribute to a cleaner and greener community.",
    title: "CSR Lead",
    subtitle: "Leader",
    image: image2,
  },
  {
    id: "03",
    text: "In partnership with a local charity, a school in Mumbai used Sevak Army to organise a food drive to support the homeless. The school wanted to encourage its students to get involved in community service and teach them the importance of giving back. With the help of Sevak Army’s platform, the school was able to coordinate the logistics of the drive, from collecting food donations to distributing meals to those in need. The students played an active role, volunteering their time to package and deliver the food. The food drive was a huge success, with over 500 meals provided to the homeless in Mumbai. The experience not only helped those in need but also instilled a strong sense of responsibility and empathy in the students.",
    subtitle: "CSR Coordinator",
    title: "CSR Coordinator",
    image: image3,
  },
  {
    id: "04",
    text: "Sevak Army helped a college in Delhi connect with an NGO focused on providing digital skills training to underprivileged youth. The college wanted to offer its IT students an opportunity to use their skills to make a difference. Through the platform, they posted a task seeking volunteers to teach basic computer skills, coding and internet usage to children from low-income families. A group of student volunteers signed up and over the course of several weeks, they taught classes to eager learners. Many of the children had never used a computer before and the skills they gained opened up new opportunities for their future. The college was able to track the success of the programme through Sevak Army and share the positive outcomes with both the volunteers and the community.",
    title: "Albert Flores",
    subtitle: "President of Sales",
    image: image4,
  },
  {
    id: "05",
    text: "A university in Chennai partnered with a medical NGO to organise a blood donation drive, using Sevak Army to manage the event. The platform helped the university recruit volunteers, coordinate the logistics of the drive and keep track of participants. The students were eager to get involved and the drive was a great success, with over 150 units of blood collected. The university used Sevak Army to send out notifications and reminders to participants, ensuring maximum turnout. The event not only helped save lives but also created a sense of unity and purpose among the students, who felt proud to have contributed to such an important cause.",
    title: "CSR Manager",
    subtitle: "Manager",
    image: image5,
  },
];

const accordionData = [
  {
    title: "1. Select the Task Type.",
    content:
      "Start by selecting the type of task you want to post, whether it’s teaching, event coordination or food collection.",
  },
  {
    title: "2. Provide Task Details.",
    content:
      "Clearly describe the task by including key details such as the duration (e.g., how many hours or days the task will take), the skills required (e.g., teaching qualifications organizational skills) and the preferred timeline for completion. This information will help volunteers understand what’s expected and decide whether they’re a good fit for the task.",
  },
  {
    title: "3. Upload Additional Materials.",
    content:
      "If the task requires additional resources—such as flyers, event guidelines or instructional documents—you can upload these materials directly to the platform. This ensures that volunteers have all the information they need to perform the task effectively.",
  },
  {
    title: "4. Publish the Task.",
    content:
      "Once all the details are filled out, simply publish the task to make it available for volunteers to view and apply. Volunteers can then browse and apply for tasks that match their skills and interests, ensuring that your institution gets the support it needs.",
  },
];

const SchoolCollegePages = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const openModal = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <i className="flaticon-right-arrow-1"></i>
      </div>
    );
  };

  const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <i className="flaticon-left-arrow"></i>
      </div>
    );
  };
  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  const settingsForTaskOverview = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
  };

  return (
    <>
      <Navbar Logo={Logo} hclass={"wpo-site-header"} />
      <PageTitle pageTitle={"About us"} pagesub={"About us"} />

      <section className="wpo-about-section section-padding mt-5">
        <div className="container mb-5">
          <div className="wpo-about-wrap">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left">
                    <img src={abImg1} alt="img" />
                  </div> */}
                  <div className="">
                    <img src={abImg2} alt="img" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text mb-0 pb-0">
                  <div className="wpo-section-title">
                    <span>Who Are We?</span>
                    <h6 className="about-heading-second fw-bold text-titleeee">About Us</h6>
                  </div>
                  <p className="pb-0 mb-0">
                    Sevak Army is a platform that connects schools, colleges and volunteers, simplifying the process for
                    educational institutions to find skilled individuals eager to contribute to meaningful causes.
                    Whether it's organising a short-term event or engaging in long-term projects like teaching or
                    website development, Sevak Army makes volunteerism efficient, impactful and rewarding.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="section-padding pb-0">
          <div className="container">
            <div className="wpo-about-wrap">
              <div className="flex-containerrr justify-content-center gap-3">
                <div className="">
                  <div className="wpo-about-text mt-0">
                    <div className="wpo-section-title mt-0">
                      {/* <span>Welcome to Sevak Army!</span> */}
                      <h2 className="about-heading-third text-titleeee">Our Team</h2>
                    </div>
                    <p>
                      The team at Sevak Army is made up of a diverse group of professionals, including developers,
                      designers, social workers and project managers, all of whom are deeply committed to the success of
                      the platform. Together, the team is driven by a shared goal: to make Sevak Army the leading
                      platform for volunteerism, where individuals and organizations can come together to make a lasting
                      social impact.
                    </p>
                    <h4 className=".services-title-text mt-5">
                      Be a part of the Sevak Army community and start making a difference today. Click here to get
                      started with the registration process.{" "}
                    </h4>

                    {/* <Link onClick={ClickHandler} to="/school-college/contact-us" className="theme-btn">
                      Register Your School or College Now
                    </Link> */}
                  </div>
                </div>
                <div className="">
                  <div className="wpo-about-img">
                    {/* <div className="wpo-about-left-side">
                    <img src={abImg3} alt="img" />
                  </div> */}
                    <div className="wpo-about-right pt-0">
                      <img src={abImg4} alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      {/* Gallery */}
      <section className="wpo-instagram-section pt-0 s2 pb-0">
        <div className="container">
          <div className="instagram-wrap">
            <div className="wpo-section-title mt-0">
              <span>Gallery</span>
              <h2 className="about-heading-third">Our Gallery</h2>
            </div>
            <div className="row">
              {Instagram.map((item) => (
                <div className="col col-lg-4 col-md-6 col-12" key={item.id}>
                  <div className="instagram-card">
                    <div className="image">
                      <Link
                        to={item.imag}
                        className="fancybox"
                        data-fancybox-group="gall-1"
                        onClick={(e) => {
                          e.preventDefault();
                          openModal(item.imag);
                        }}
                      >
                        <img src={item.imag} alt={`Instagram ${item.id}`} className="img img-responsive" />
                        <div className="popup-icon">
                          <i className="ti-plus"></i>
                        </div>
                      </Link>
                    </div>
                    <div className="text">
                      <span>{item.title}</span>
                      <h2>{item.subtitle}</h2>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {modalOpen && (
          <div className="modal" onClick={closeModal}>
            <div className="modal-wrap">
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <span className="close" onClick={closeModal}>
                  &times;
                </span>
                <img src={selectedImage} alt="Modal" />
              </div>
            </div>
          </div>
        )}
      </section>

      {/* testimonial */}
      <section className={"section-padding " + "wpo-testimonial-section mt-0"}>
        <div className="container">
          <div className="wpo-testimonial-slider">
            <div className="wpo-section-title">
              <span>Testimonials</span>
              <h2 className="about-heading-third">What People Say</h2>
            </div>
            <Slider {...settings}>
              {TestimonialData.map((Testimonial, item) => (
                <div className="testimonial-item" key={item}>
                  <div className="content" style={{ minHeight: 800 }}>
                    <div className="icon">
                      <i className="flaticon-quote"></i>
                    </div>
                    <p className="" style={{ textAlign: "justify" }}>
                      {Testimonial.text}
                    </p>
                    <div className="shape">
                      <img src={Shape} alt="" />
                    </div>
                  </div>
                  <div className="bottom-content">
                    <div className="image">
                      <img src={Testimonial.image} alt="" />
                    </div>
                    <div className="text">
                      <h3>{Testimonial.title}</h3>
                      <span>{Testimonial.subtitle}</span>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>

      {/* causes */}
      <section className="wpo-about-section-s3 section-padding pt-0">
        <div className="container">
          <div className="wpo-about-wrap">
            <div className="flex-containerrr justify-content-center gap-3">
              <div className="">
                <div className="wpo-about-img">
                  <div className="image">
                    <img src={abut3} width={900} alt="" />
                    {/* <div className="shape">
                      <img src={abutShape3} alt="" />
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="w-lg-50 w-100">
                <div className="wpo-about-text">
                  <div className="wpo-section-title">
                    <span>Causes</span>
                    <h3 className="display-5 fw-bold text-capitalize">
                      Empower Your Institution with Seamless Volunteer Management and Impactful Performance Tracking on
                      the Sevak Army Dashboard.
                    </h3>
                  </div>
                  <p>
                    The <b>Task Overview</b> section serves as a quick snapshot of all ongoing, pending and completed
                    tasks within the institution.
                  </p>

                  {/* <p>
                    The Task Overview provides institutions with:
                    <ul>
                      <li>
                        <b>An Overview of Tasks In Progress:</b> View all active tasks and their current status, whether
                        they are underway, paused or waiting for volunteer feedback.
                      </li>
                      <li>
                        <b>Pending Approval Tasks:</b> Quickly see which tasks are awaiting approval from volunteers or
                        administrators, ensuring no delays in project start dates.
                      </li>
                      <li>
                        <b>Completed Tasks:</b> Review all completed tasks to evaluate the outcomes, assess the
                        volunteers’ contributions and mark the tasks as finalised.
                      </li>
                      <li>
                        <b>Volunteer Assignment Tracking:</b> Monitor which volunteers have been assigned to specific
                        tasks, track their progress and ensure they meet deadlines.
                      </li>
                    </ul>
                  </p> */}

                  {/* <div className="about-btn">
                    <Link to="/school-college/contact-us" className="theme-btn" onClick={ClickHandler}>
                      Access Task Overview
                    </Link> */}
                  {/* <a href="tel:+4733378901" className="call-content">
                      <div className="icon">
                        <i className="flaticon-phone-call"></i>
                      </div>
                      <div className="text">
                        <h5>Call Us:</h5>
                        <span>+(684) 555-0102</span>
                      </div>
                    </a> */}
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <TaskOverview />

      {/* task overview */}
      <section className="wpo-about-section-s4 section-padding pt-5">
        <div className="container">
          <div className="wpo-about-wrap">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 order-lg-2">
                <div className="wpo-about-img-s5">
                  <div className="image">
                    <img src={About4} alt="" />
                  </div>
                  <div className="wpo-supporter">
                    <div className="wpo-supporter-text">
                      {/* <div className="icon">
                        <i>
                          <img src={About4s2} alt="" />
                        </i>
                      </div> */}
                      <div className="content">
                        <h3>120+</h3>
                        <p>Happy Volunteers</p>
                      </div>
                    </div>
                    <div className="wpo-supporter-img">
                      <ul className="wpo-supporter-slide">
                        <Slider {...settingsForTaskOverview}>
                          <li data-bs-toggle="tooltip" data-bs-html="true" title="Aliza Anny">
                            <img src={team1} alt="" />
                          </li>
                          <li data-bs-toggle="tooltip" data-bs-html="true" title="David Miller">
                            <img src={team2} alt="" />
                          </li>
                          <li data-bs-toggle="tooltip" data-bs-html="true" title="Maria Silva">
                            <img src={team3} alt="" />
                          </li>
                          <li data-bs-toggle="tooltip" data-bs-html="true" title="Takila Mas">
                            <img src={team4} alt="" />
                          </li>
                          <li data-bs-toggle="tooltip" data-bs-html="true" title="Jenny Lawba">
                            <img src={team5} alt="" />
                          </li>
                        </Slider>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 order-lg-1">
                <div className="wpo-about-text">
                  <div className="wpo-section-title">
                    <span>Task Overview</span>
                    <h2 className="text-titleeee">Create, Manage and Edit Tasks.</h2>
                  </div>
                  <p>
                    Creating, managing and editing tasks has never been easier. The Sevak Army dashboard offers
                    intuitive tools for schools and colleges to outline their volunteer needs and ensure their projects
                    get the support they require.
                  </p>

                  <p>
                    Need volunteers for your next project? Click here to create and publish a new task and connect with
                    volunteers in minutes.
                  </p>
                  {/* <div className="about-btn">
                    <Link to="/about" className="theme-btn">
                      Create a New Task
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* News and articles */}
      <section className="wpo-blog-section-s2 section-padding pt-5 pb-5 ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12 col-12">
              <SectionTitle
                subtitle="Blogs"
                title="Recent News & Updates"
                paragarap="Stay up-to-date with the latest insights and blog posts from Sevak Army. From impact stories to how-to guides, our blog has everything you need to stay informed."
              />
            </div>
          </div>
          <div className="blog-wrap mt-5">
            <div className="row">
              {blogs.map((blog, bl) => (
                <div className="col col-lg-6 col-md-6 col-12" key={bl}>
                  <div className="blog-single-card">
                    <div className="image">
                      <img src={blog.screens} alt="" />
                      <div className="top-date">
                        {blog.athor.split(" ")[0]}
                        <div className="shape">
                          <svg width="8" height="9" viewBox="0 0 8 9" fill="none">
                            <path d="M7.99999 9.0001L-9.79769e-05 9.00023L8.00005 0.500149L7.99999 9.0001Z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="content">
                      <ul className="top-content">
                        <li>
                          <div className="image-single">
                            <img src={blog.bsing} alt="" />
                          </div>
                          {blog.athor}
                        </li>
                        <li>
                          <i className="flaticon-chatting"></i>
                          03 Comments
                        </li>
                      </ul>
                      <h2>
                        {/* <Link className=  "blog-title-home" onClick={ClickHandler} to={`/blog-single/${blog.slug}`}> */}
                        {blog.title}
                        {/* </Link>  */}
                      </h2>
                      <p>{blog.subtitle}</p>
                    </div>
                    {/* <div className="blog-btn">
                      <Link onClick={ClickHandler} to={`/blog-single/${blog.slug}`}>
                        <span>Read More...</span>
                        <i className="flaticon-right-arrow-2"></i>
                      </Link>
                    </div> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="wpo-cta-section">
        <div className="shape-1">
          <img src={Cta1} alt="" />
        </div>
        <div className="bg-overlay">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="cta-wrap">
                  {/* <div className="icon">
                    <img src={Cta2} alt="" />
                  </div> */}
                  <div className="wpo-section-title">
                    <h2 className="display-2">Newsletter Sign up</h2>
                    <p className="text-white">
                      Want to stay updated with the latest news and volunteer opportunities? Sign up for our newsletter
                      and never miss an update.
                    </p>
                  </div>
                  <Link onClick={() => props.setShow(true)} to="#" className="theme-btn-cta">
                    Subscribe now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-2">
          <img src={Cta3} alt="" />
        </div>
      </section>
      <section className="wpo-about-section-s4 section-padding">
        <SectionTitle subtitle={"Services"} />
        <div className="container">
          <div className="wpo-about-wrap">
            <div className="flex-containerrr justify-content-between gap-5 gap-y-3">
              <div className="">
                <div className="wpo-about-text">
                  <div className="wpo-section-title">
                    <h2 style={{ fontSize: "48px" }}>Task Management</h2>
                  </div>
                  <p>
                    Task management is one of the core features of the Sevak Army platform, designed to streamline the
                    workflow for both volunteers and schools by helping them efficiently track, manage and complete
                    their tasks.
                  </p>
                  <h3>Types of Tasks You Can Post:</h3>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Teaching Support:</span> Engage volunteers to assist with
                    tutoring or mentoring students, whether it’s academic subjects, extracurricular activities or life
                    skills.
                  </p>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Event Organisation:</span> Recruit volunteers to help plan and
                    execute school or college events, such as community fairs, charity fundraisers or awareness
                    campaigns.
                  </p>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Food Collection Drives:</span> Mobilise volunteers to collect,
                    sort and distribute food donations for local communities and charitable organizations.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 order-lg-1">
                <div className="wpo-about-text">
                  <div className="wpo-section-title">
                    <h2 style={{ fontSize: "48px" }}>How to Use the Platform?</h2>
                  </div>
                  <p>
                    Once your institution's registration is verified and approved, here is how you can start making the
                    most of the features available on Sevak Army:
                  </p>
                  <Link onClick={() => props.setShow(true)} to="#" className="theme-btn">
                    Know More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wpo-service-single-section my-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col col-md-12">
              <div className="wpo-service-single-wrap">
                <div className="wpo-benefits-section">
                  <h2>Uploading Tasks</h2>
                  <div className="row">
                    <div className="col-lg-12 col-12">
                      <div className="wpo-benefits-item">
                        <div className="accordion" id="accordionExample">
                          {accordionData.map((item, index) => (
                            <div className="accordion-item" key={index}>
                              <h3 className="accordion-header" id={`heading${index}`}>
                                <button
                                  className={`accordion-button ${activeAccordion === index ? "" : "collapsed"}`}
                                  type="button"
                                  onClick={() => toggleAccordion(index)}
                                  aria-expanded={activeAccordion === index ? "true" : "false"}
                                  aria-controls={`collapse${index}`}
                                >
                                  {item.title}
                                </button>
                              </h3>
                              <div
                                id={`collapse${index}`}
                                className={`accordion-collapse collapse ${activeAccordion === index ? "show" : ""}`}
                                aria-labelledby={`heading${index}`}
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  <p>{item.content}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </>
  );
};

export default SchoolCollegePages;
