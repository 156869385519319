import React from 'react'

function NgoFeaturesData() {
    const featuresData = [
        {
            iconClass: 'flaticon-volunteer',
            title: 'Skill Development Workshops',
            description: 'Aimed at fostering growth and empowering youth.'
        },
        {
            iconClass: 'flaticon-solidarity',
            title: 'Community Outreach',
            description: 'Programmes dedicated to improving health, advancing education and promoting welfare.'
        },
        {
            iconClass: 'flaticon-charity',
            title: 'Environmental Initiatives',
            description: 'To promote environmental sustainability and collective social responsibility.'
        }
    ];
  return (
    <section className="wpo-features-area">
    <div className="container">
        <div className="features-wrap">
            <div className="row">
                {featuresData.map((feature, index) => (
                    <div className="col col-lg-4 col-md-6 col-12" key={index}>
                        <div className="feature-item-wrap">
                            <div className="feature-item">
                                <div className="feature-icon">
                                    <div className="icon">
                                        <i className={`fi ${feature.iconClass}`}></i>
                                    </div>
                                </div>
                                <div className="feature-text">
                                    <h2 className='fs-5'>{feature.title}</h2>
                                    <p className='fs-6'>{feature.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
</section>
  )
}

export default NgoFeaturesData