import React, { useState } from "react";
import { Link } from "react-router-dom";
import Cta1 from "../../images/cta/shape-1.png";
import Cta2 from "../../images/cta/top-icon.png";
import Cta3 from "../../images/cta/shape-2.png";
import AuthModal from "../../auth-modal";
const CtaSection = () => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const [show, setShow] = useState(false);
  return (
    <>
      <section className="wpo-cta-section">
        <div className="shape-1">
          <img src={Cta1} alt="" />
        </div>
        <div className="bg-overlay">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="cta-wrap">
                  <div className="icon">
                    <img src={Cta2} alt="" />
                  </div>
                  <span>Join the Movement</span>
                  <h2>Empower your company, school, or organization to create lasting social impact.</h2>
                  <Link to="#" className="theme-btn-cta" onClick={() => setShow(true)}>
                    Become a Sevak Today
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-2">
          <img src={Cta3} alt="" />
        </div>
      </section>
      <AuthModal show={show} setShow={setShow} />
    </>
  );
};

export default CtaSection;
