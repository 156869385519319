import timg1 from '../images/volunteer/1.jpg'
import timg2 from '../images/volunteer/2.jpg'
import timg3 from '../images/volunteer/3.jpg'
import timg4 from '../images/volunteer/4.jpg'





const Teams = [
   {
      Id: '1',
      img: timg1,
      title: 'Wade Warren',
      subtitle: 'Charity Volunteer',
   },
   {
      Id: '2',
      img: timg2,
      title: 'Michelle Woods',
      subtitle: 'Charity Volunteer',
   },
   {
      Id: '3',
      img: timg3,
      title: 'Diane Fritz',
      subtitle: 'Charity Volunteer',
   },
   {
      Id: '4',
      img: timg4,
      title: 'John Wing',
      subtitle: 'Charity Volunteer',
   },





]

export default Teams;