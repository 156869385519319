import React from "react";
import { Link } from "react-router-dom";
import abImg1 from "../../images1/volunteer/home/sevak-army-volunteer-home-1.jpg";
import abImg2 from "../../images1/volunteer/home/sevak-army-volunteer-home-2.jpg";
import SectionTitle from "../SectionTitle/SectionTitle";
import Volunteerhomecards from "../Volunteer/Volunteerhomecards";
import volunteerhomeEvents from "../Volunteer/Volunteerhomeevents";
import Volunteerhometestmonial from "../Volunteer/Volunteerhometestmonial";
import Volunteerhomestories from "../Volunteer/Volunteerhomestories";
import Navbar from "../Navbar/Navbar";
import Footer from "../footer/Footer";
import Logo from "../../images/logo.svg";
import Scrollbar from "../scrollbar/scrollbar";
import VideoModal from "../ModalVideo/VideoModal";
import icon from "../../images/cta/top-icon.png";

import Image1 from "../../images1/ngo/home/sevak-army-ngo-home-1.jpg";
import Videobg from "../../images1/ngo/home/sevak-army-ngo-home-2.jpg";

import ShapeBg from "../../images/slider/stick-man.svg";
import SupportersSlider from "../hero/SupportersSlider";

const Image2 = "";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const featuresData = [
  {
    iconClass: "flaticon-volunteer",
    title: "Explore Opportunities",
    description: "Whether teaching, marketing or helping, there’s something for everyone.",
  },
  {
    iconClass: "flaticon-solidarity",
    title: "Flexible opportunities",
    description: "Opportunities available for all skill levels, adaptable to your schedule and interests.",
  },
  {
    iconClass: "flaticon-charity",
    title: "Gain rewards",
    description: "Complete tasks to earn points, recognition, and badges for your contributions.",
  },
];

const Volunteerhome = (props) => {
  return (
    <>
      <Navbar Logo={Logo} hclass={"wpo-site-header"} />
      <section className="static-hero">
        <div className="hero-container">
          <div className="hero-inner">
            <div className="container-fluid">
              <div className="hero-content">
                <div className="slide-title-sub">
                  <span>Inspiring Leaders, Driving Change.</span>
                </div>
                <div className="slide-title">
                  <h2 className="slide-heading">Empowering changemakers with impactful action.</h2>
                </div>
                <div className="slide-text">
                  <p>
                    Sevak Army is a community-driven portal. It connects volunteers to meaningful tasks. These tasks
                    span various causes.
                  </p>
                </div>
                <div className="clearfix"></div>
                <div data-swiper-parallax="500" className="slide-video-content">
                  <div className="slide-video-img">
                    <img src={Videobg} alt="img" />
                    {/* <VideoModal /> */}
                  </div>
                  <div className="slide-video-text">
                    <h4 className="slide-video-text-show">Make a difference together!</h4>
                    <p>Let’s come together and make a difference, creating a lasting impact for a better future!</p>
                  </div>
                </div>
                <div className="charius-pic">
                  <div className="charius-pic-main">
                    <img src={Image1} width={800} alt="img" />
                  </div>
                  {/* <div className="charius-inner">
                        <img src={Image2} alt="img" />
                      </div> */}

                  <div className="wpo-supporter">
                    <div className="wpo-supporter-text">
                      <div className="icon">
                        <i>
                          <img src={ShapeBg} alt="img" />
                        </i>
                      </div>
                      <div className="content">
                        <h3>500+</h3>
                        <p>Volunteers Partnered</p>
                      </div>
                    </div>
                    <SupportersSlider />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wpo-about-section section-padding">
        <div className="container">
          {/* <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-12 text-center">
                <SectionTitle
                  subtitle="Inspiring Leaders, Driving Change."
                  title="Empowering changemakers with impactful action!"
                  paragarap="Sevak Army is a community-driven portal. It connects volunteers to meaningful tasks. These tasks span various causes."
                />
              </div>
            </div> */}

          {/* <section className="section-padding">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-12 text-center">
                <SectionTitle
                  title="Empower action, inspire change."
                  paragarap="Sevak Army connects you to projects where your skills make a real difference, creating change."
                />
                <Link onClick={ClickHandler} to="/volunteer/events" className="theme-btn">
                  View Available Tasks
                </Link>
              </div>
            </div>
          </section> */}

          <section className="wpo-cta-section section-padding mt-5">
            <div className="bg-overlay">
              <div className="container">
                <div className="bg-color">
                  <div className="row justify-content-center">
                    <div className="col-lg-8">
                      <div className="cta-wrap">
                        <div className="icon">
                          <img src={icon} alt="" />
                        </div>
                        <span>Empower action, inspire change.</span>
                        <h2>
                          Sevak Army connects you to projects where your skills make a real difference, creating change.
                        </h2>
                        {/* <Link onClick={ClickHandler} to="/volunteer/events" className="theme-btn">
                        View Available Tasks
                      </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="pt-100">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-12 col-12">
                <SectionTitle subtitle="Empower Your Journey" />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-12 col-12">
                <SectionTitle title="Discover Opportunities and Earn Rewards" />
              </div>
            </div>

            <div className="wpo-features-area">
              <div className="features-wrap">
                <div className="row">
                  {featuresData.map((feature, index) => (
                    <div className="col col-lg-4 col-md-6 col-12" key={index}>
                      <div className="feature-item-wrap">
                        <div className="feature-item">
                          <div className="feature-icon">
                            <div className="icon">
                              <i className={`fi ${feature.iconClass}`}></i>
                            </div>
                          </div>
                          <div className="feature-text">
                            <h2 className="fs-5">{feature.title}</h2>
                            <p className="fs-6">{feature.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
          <section className="section-padding ">
            <div className="wpo-about-wrap mt-5">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="wpo-about-img">
                    {/* <div className="wpo-about-left">
                      <img src={abImg2} alt="img" />
                    </div> */}
                    <div className="wpo-about-right pt-5">
                      <img src={abImg2} alt="img" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="wpo-about-text">
                    <div className="wpo-section-title">
                      <span>Welcome to Sevak Army</span>
                      <h2 className="about-heading-second text-titleeee">Turn passion into impactful opportunities!</h2>
                    </div>
                    <p className="volunteer-home-text">
                      We connect individuals eager to contribute their skills, time and passion to meaningful service.
                      Our platform makes it easy to find, apply for and complete volunteer tasks with trusted NGOs.
                      Volunteers can be confident their efforts are organised and impactful. Our diverse community
                      includes professionals, students and retirees, all united by the purpose of giving back.
                      Opportunities span various causes such as education, healthcare and event management. Volunteers
                      can filter tasks by skills, location and interests. This ensures they find opportunities that
                      align with their passion.
                    </p>

                    <div className="about-info-wrap col-12 ">
                      <div className="about-info-item col-6 " style={{ alignItems: "self-start" }}>
                        <div className="about-info-icon">
                          <div className="icon">
                            <i className="fi flaticon-target"></i>
                          </div>
                        </div>
                        <div className="about-info-text col-6">
                          <h4>Our Mission</h4>
                          <p className="mission-paragraph">Sevak Army bridges volunteers with causes that matter.</p>
                        </div>
                      </div>
                      <div className="about-info-item " style={{ alignItems: "self-start" }}>
                        <div className="about-info-icon">
                          <div className="icon">
                            <i className="fi flaticon-mountain"></i>
                          </div>
                        </div>
                        <div className="about-info-text ">
                          <h4>Our Vision</h4>
                          <p className="vision-paragraph">
                            Uniting volunteers and organizations to inspire transformative change.
                          </p>
                        </div>
                      </div>
                    </div>

                    <Link onClick={() => props.setShow(true)} to="#" className="theme-btn">
                      Register Now
                    </Link>
                  </div>
                </div>

                {/* Comment */}
                {/* <div className="row justify-content-center section-padding">
                  <div className="col-lg-12 col-md-12 col-12 text-center p-2">
                    <SectionTitle
                      subtitle="Sevak Army’s Journey"
                      title="Together, We Make Every Effort Count!"
                      paragarap="Volunteers constantly seek new ways to engage with causes. Sevak Army offers diverse tasks for different skills. Opportunities include teaching, marketing and event organising. We also support food distribution efforts. There's something for everyone."
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </section>
          <Volunteerhomecards />
          {/* <section className="section-padding">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-12 text-center">
                <SectionTitle
                  title="Flexible memberships for every volunteer!"
                  paragarap="Sevak Army offers flexible memberships, tailored to fit your schedule and commitment level. "
                />
                <Link onClick={()=>()=>setShow(true)} to="#" className="theme-btn">
                  Become a Member
                </Link>
              </div>
            </div>
          </section> */}

          <section className="wpo-cta-section-s3 section-padding">
            <div className="container">
              <div className="bg-color" style={{ background: "#007560" }}>
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <div className="cta-wrap">
                      <div className="icon">
                        <img src={icon} alt="" />
                      </div>
                      <span>Flexible memberships for every volunteer!</span>
                      <h2>
                        Sevak Army offers flexible memberships, tailored to fit your schedule and commitment level.
                      </h2>
                      <Link onClick={() => props.setShow(true)} to="#" className="theme-btn">
                        Become a Member
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="event-wrap mt-5">
            <SectionTitle title="Join Events, Make Impact!" paragarap="Participate in volunteer events." />
            <div className="row mt-5">
              {volunteerhomeEvents.slice(0, 3).map((event, index) => (
                <div className="col col-lg-4 col-md-6 col-12" key={index}>
                  <div className="event-card">
                    <div className="top-date">
                      <span>{event.date}</span> {event.month}
                      <div className="shape">
                        <svg width="19" height="14" viewBox="0 0 19 14" fill="none">
                          <path d="M18.9999 14L0.5 14L18.9999 -3.6902e-05L18.9999 14Z" />
                        </svg>
                      </div>
                    </div>
                    <div className="image">
                      <img src={event.pimg} alt="" />
                    </div>
                    <div className="text">
                      <h3 className="text-center fw-bold text-white">{event.title}</h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <section className="section-padding ">
            <Volunteerhomestories />
          </section>
          <section className="">
            <Volunteerhometestmonial />
          </section>
        </div>
      </section>{" "}
      <Footer />
      <Scrollbar />
    </>
  );
};

export default Volunteerhome;
