import React, { Fragment } from "react";
import About1 from "../../images1/volunteer/about-us/sevak-army-volunteer-about-us-1.jpg";
import About6 from "../../images1/volunteer/about-us/sevak-army-volunteer-about-us-1.jpg";
import About2 from "../../images1/volunteer/about-us/sevak-army-volunteer-about-us-2.jpg";
import About3 from "../../images1/volunteer/about-us/sevak-army-volunteer-about-us-3.jpg";
import About4 from "../../images1/volunteer/about-us/sevak-army-volunteer-about-us-4.jpg";
import About5 from "../../images1/volunteer/about-us/sevak-army-volunteer-about-us-5.jpg";

function VolunteerAboutUsPage() {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const featuresData = [
    {
      iconClass: "flaticon-volunteer",
      title: "Our Mission",
      img: About2,
      description:
        "To create a seamless platform that connects passionate individuals with impactful volunteer opportunities, empowering people to contribute to societal change through their skills, time, and effort. Sevak Army strives to build a bridge between volunteers and causes that matter, ensuring that everyone, regardless of age or background, can find ways to make a difference. We aim to cultivate a culture of service where every contribution counts and inspire a community dedicated to making a positive impact. By fostering collaboration, we help turn passion into action, creating lasting change in our communities.",
    },
    {
      iconClass: "flaticon-solidarity",
      title: "Our Vision",
      img: About3,
      description:
        "To build a global community where every individual can contribute meaningfully, creating lasting social impact through dedicated and selfless service. We aim to unite passionate volunteers and organizations to inspire change, foster inclusivity, and empower people to transform lives, one act of service at a time. By embracing diverse perspectives and unique skills, we create a vibrant network that amplifies our collective efforts. Together, we envision a world where each act of kindness builds significant momentum for greater societal transformation, ensuring that everyone has a vital role in actively shaping a better future.",
    },
    {
      iconClass: "flaticon-charity",
      title: "Our Values",
      img: About4,
      description: (
        <>
          <b>Services, Collaboration, Transparency, Inclusivity, Empowerment.</b>
          <br />
          - Service: We believe in the power of seva (selfless service) to create lasting change.
          <br />
          - Collaboration: Volunteers and organizations work together to achieve meaningful impact.
          <br />
          - Transparency: Clear communication and processes ensure trust between volunteers and organizers.
          <br />
          - Inclusivity: We welcome volunteers of all ages and backgrounds.
          <br />- Empowerment: Every volunteer can make a difference with the right tools.
        </>
      ),
    },
  ];
  return (
    <Fragment>
      <section className="wpo-about-section mb-0 mt-5">
        <div className="container">
          <div className="wpo-about-wrap">
            <div className="flex-containerrr justify-content-center pt-100">
              <div className="">
                <div className="wpo-about-img">
                  <div className="bg-image">
                    <img src={About1} width={650} alt="" />
                  </div>
                  {/* <div className="img-1">
                    <img width={230} className="img-fluid" src={About2} alt="" />
                  </div> */}
                  {/* <div className="img-2">
                    <img width={230} className="img-fluid" src={About3} alt="" />
                  </div> */}
                  {/* <div className="img-3">
                    <img width={230} className="img-fluid" src={About4} alt="" />
                  </div> */}
                  {/* <div className="img-4">
                  <img width={230} className="img-fluid" src={About4} alt="" />
                  </div> */}
                  {/* <div className="img-5">
                    <img width={230} className="img-fluid" src={About6} alt="" />
                  </div> */}
                </div>
              </div>
              <div className="">
                <div className="wpo-about-text">
                  <div className="wpo-section-title">
                    <h2>About Us</h2>
                  </div>
                  <p className="volunteer-home-text">
                    We’re a passionate team of social workers, tech experts and community champions. Our diverse
                    backgrounds give us the skills and insights to create a platform that truly meets the needs of
                    volunteers and NGOs alike. We believe in the power of teamwork and are dedicated to making
                    volunteerism simple, organised and effective. We understand that volunteers always seek new ways to
                    engage with various causes. That is why Sevak Army offers diverse tasks that cater to different
                    skills and interests. From teaching and digital marketing to organising events and helping with food
                    distribution drives, there’s something for everyone.
                  </p>

                  {/* <div className="about-info-wrap">
                    <div className="about-info-item">
                      <div className="about-info-icon">
                        <div className="icon">
                          <i className="fi flaticon-target"></i>
                        </div>
                      </div>
                      <div className="about-info-text">
                        <h4>Our Mission</h4>
                        <p>We believe charity is a lifetime investment.</p>
                      </div>
                    </div>
                    <div className="about-info-item">
                      <div className="about-info-icon">
                        <div className="icon">
                          <i className="fi flaticon-mountain"></i>
                        </div>
                      </div>
                      <div className="about-info-text">
                        <h4>Our Vision</h4>
                        <p>We believe charity is a lifetime investment.</p>
                      </div>
                    </div>
                  </div>

                  <Link
                    onClick={ClickHandler}
                    to="/about"
                    className="theme-btn"
                  >
                    Discover More
                  </Link> */}
                </div>
              </div>
            </div>
            <section className="wpo-features-area pt-5 mt-5 section-padding">
              <div className="features-wrap">
                <div className="row">
                  {featuresData.map((feature, index) => (
                    <div className="col col-lg-4 col-md-6 col-12" key={index}>
                      <div className="feature-item-wrap">
                        <div className="feature-item">
                          <div className="feature-text">
                            <div className="mb-4 d-flex justify-content-center align-items-center">
                              <img
                                src={feature.img}
                                alt=""
                                className="img-fluid"
                                style={{ height: "auto", width: "100%", objectFit: "cover", maxHeight: "200px" }}
                              />
                            </div>
                            <h2 className=" fw-bold">{feature.title}</h2>
                            <p className="fs-6 p-2">{<p className="fs-6 p-2">{feature.description}</p>}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default VolunteerAboutUsPage;
