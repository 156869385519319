import React from 'react'
import ServiceCard from "../ServiceSection/ServiceCard";
import simg1 from '../../images/service/1.svg'
import simg2 from '../../images/service/2.svg'
import simg3 from '../../images/service/3.svg'
import simg4 from '../../images/service/4.svg'
import sSimg1 from '../../images/service-single/img1.jpg'
import sSimg2 from '../../images/service-single/img2.jpg'
import sSimg3 from '../../images/service-single/img3.jpg'
import sSimg4 from '../../images/service-single/img4.jpg'

function JoinUsCards() {
    const Services = [
        {
            Id: '01',
            title: "Sign Up",
            sImg: simg1,
            sSImg: sSimg1,
            slug: '1',
            description: 'Create an account by providing your basic details such as name, email and contact information.',
        },
        {
            Id: '02',
            title: "Complete Profile",
            sImg: simg2,
            sSImg: sSimg2,
            slug: '2',
            description: 'Fill in your profile with your skills, interests and areas of expertise.',
        },
        {
            Id: '03',
            title: "Browse Tasks",
            sImg: simg3,
            sSImg: sSimg3,
            slug: '3',
            description: 'Once your profile is set, browse through a variety of tasks based on your preferences.'

        },
        {
            Id: '04',
            title: "Apply for Tasks",
            sImg: simg4,
            sSImg: sSimg4,
            slug: '4',
            description: 'Apply for tasks that interest you, explaining why you are suited for the role.'

        },
        {
            Id: '05',
            title: "Get Approved",
            sImg: simg1,
            sSImg: sSimg1,
            slug: '5',
            description: 'Once the task organiser reviews your application, they will approve and communicate the next steps.',
        },

    ]

    return (
        <section>
            <div className="container">
                <div className="service-wrap">
                    <div className="row justify-content-center">
                        {Services.map((Service, sitem) => (
                            <div className="col col-lg-3 col-md-6 col-sm-6 col-12" key={sitem} style={{ width: '28.3rem', display: 'flex', justifyContent: 'center' }}>
                                <ServiceCard title={Service.title} img={Service.sImg} slug={Service.slug} sdescription={Service.description} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default JoinUsCards