import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Shape from "../../images/testimonial/bg.png";

import image1 from "../../images1/team/sevak-army-team-4.jpg";
import image2 from "../../images1/team/sevak-army-team-9.jpg";
import image3 from "../../images1/team/sevak-army-team-1.jpg";
import SectionTitle from "../SectionTitle/SectionTitle";

function NgoHomeTestimonal({ props }) {
  const TestimonialData = [
    {
      id: "01",
      text: "Sevak Army partnered with an education-focused NGO to mobilize volunteers, providing essential learning opportunities in underserved rural communities. Volunteers built makeshift classrooms, distributed educational materials and led engaging lessons, impacting hundreds of children and empowering their communities through literacy initiatives and enhancing educational access for all involved.",
      title: " Expanding Access to Education in Rural Areas.",
      // subtitle: "Manager",
      image: image1,
    },
    {
      id: "02",
      text: "Sevak Army successfully partnered with a healthcare NGO to deliver primary medical care in remote villages. Skilled volunteers, including doctors and nurses, organized medical camps offering health check-ups, necessary medicines and preventive care education, serving thousands of people and addressing critical healthcare gaps, thereby improving health and wellness for underserved communities effectively and consistently.",
      title: " Delivering Essential Healthcare to Remote Villages.",
      // subtitle: "Leader",
      image: image2,
    },
    {
      id: "03",
      text: "Sevak Army collaborated with an environmental NGO to spearhead clean-up drives and awareness campaigns. Volunteers cleared waste from public spaces, organized workshops on recycling and waste management, improving local environments and inspiring communities to adopt lasting sustainable practices, ultimately fostering a healthier planet and encouraging environmental stewardship for future generations.",
      // subtitle: "Driving Environmental Sustainability Initiatives",
      title: "Driving Environmental Sustainability Initiatives.",
      image: image3,
    },

    // {
    //     id: "04",
    //     text: "This is the best agency for non profit activities.I love their work so much. I wish one day they would remove all the poverty from the world. I will be there when they need.",
    //     title: "Albert Flores",
    //     subtitle: "President of Sales",
    //     image: image2
    // },
  ];
  const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <i className="flaticon-right-arrow-1"></i>
      </div>
    );
  };

  const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <i className="flaticon-left-arrow"></i>
      </div>
    );
  };
  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <section className={" " + props}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-12 col-12">
            <SectionTitle subtitle="" title="Testimonials" />
          </div>
        </div>
        <div className="wpo-testimonial-slider mt-5">
          <Slider {...settings}>
            {TestimonialData.map((Testimonial, item) => (
              <div className="testimonial-item" key={item}>
                <div className="content">
                  <div className="icon">
                    <i className="flaticon-quote"></i>
                  </div>
                  <p style={{textAlign: "justify"}}>{Testimonial.text}</p>
                  <div className="shape">
                    <img src={Shape} alt="" />
                  </div>
                </div>
                <div className="bottom-content">
                  <div className="image">
                    <img src={Testimonial.image} alt="" />
                  </div>
                  <div className="text">
                    <h3>{Testimonial.title}</h3>
                    <span>{Testimonial.subtitle}</span>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default NgoHomeTestimonal;
