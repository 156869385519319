import React from "react";
import abImg1 from "../../images1/organization/contact-us/sevak-army-organization-contact-us-12.png";
import abImg2 from "../../images1/organization/contact-us/sevak-army-organization-contact-us-2.jpg";
import { Link } from "react-router-dom";
import InformationMessege from "../Volunteer/InformationMessege";
import WhyMatterContact from "../Volunteer/WhyMatterContact";

const Contact = () => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <>
      <section className="wpo-about-section pt-100">
        <div className="container">
          <div className="wpo-about-wrap">
            <div className="flex-containerrr align-items-center">
              <div className="">
                <div className="wpo-about-text">
                  <div className="wpo-section-title">
                    <span>Welcome to Sevak Army!</span>
                    <h2 className="about-heading-third text-titleeee">Get in Touch: We’re Here to Assist You.</h2>
                  </div>
                  <p>
                    Whether you have questions, need support or want to share your feedback, the Contact Us section is
                    your gateway to connecting with the Sevak Army team. We value your input and are committed to
                    providing timely and effective assistance to help you make the most out of our platform.<br></br>
                    Reach out to us through various channels to receive the support you need in enhancing your corporate
                    social responsibility efforts.<br></br>
                    <b>Contact Form: Quick and Convenient.</b>
                    <br></br>
                    Our Contact Form is designed to make it easier for you to reach out to us with any queries or
                    concerns. Simply fill out the form with your details and message and we’ll ensure that your inquiry
                    is directed to the appropriate team member.
                  </p>
                </div>
              </div>
              <div className="">
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left-side">
                    <img src={abImg2} alt="img" />
                  </div> */}
                  <div className="wpo-about-right pt-0">
                    <img src={abImg1} alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" pt-100">
            {/* <div className="col col-lg-8 col-md-10">
              <div className="wpo-event-item">
                <div className="wpo-event-details-text w-100 mt-0">
                  <h2 className="w-100 text-center text-titleeee">
                    Please include the following information in your message:
                  </h2>
                </div>
                <div className="wpo-event-details-wrap">
                  <div className="wpo-event-details-tab">
                    <ul className="nav nav-tabs"></ul>
                  </div>
                  <div className="wpo-event-details-content">
                    <div className="tab-content">
                      <div className="active">
                        <div id="Schedule" className="tab-pane active">
                          <ul>
                            <li className="about-section-list">
                              <p className="about-section-paragraph-list">
                                <strong className="heading-paragraph">Name:</strong>
                                Your full name so we can address you properly.
                              </p>
                            </li>
                            <li className="about-section-list ">
                              <p className="about-section-paragraph-list">
                                <strong className="heading-paragraph">Email Address:</strong>
                                The email address where we can send our response.
                              </p>
                            </li>
                            <li className="about-section-list">
                              <p className="about-section-paragraph-list">
                                <strong className="heading-paragraph">Subject:</strong>A brief description of your
                                inquiry or issue.
                              </p>
                            </li>
                            <li className="about-section-list">
                              <p className="about-section-paragraph-list">
                                <strong className="heading-paragraph">Message:</strong>
                                Detailed information about your request or concern. The more details you provide, the
                                better we can assist you.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <InformationMessege/>
          </div>
          <section className="pt-100">
            {/* <div className="w-100 d-flex justify-content-center">
              <div className="col col-lg-8 col-md-10">
                <div className="wpo-event-item">
                  <div className="wpo-event-details-text w-100 mt-0">
                    <h2 className="w-100 text-center text-titleeee">Why It Matters?</h2>
                  </div>
                  <div className="wpo-event-details-wrap">
                    <div className="wpo-event-details-tab">
                      <ul className="nav nav-tabs"></ul>
                    </div>
                    <div className="wpo-event-details-content">
                      <div className="tab-content">
                        <div className="active">
                          <div id="Schedule" className="tab-pane active">
                            <ul>
                              <li className="about-section-list">
                                <p className="about-section-paragraph-list">
                                  <strong className="heading-paragraph">Efficient Communication:</strong>
                                  This contact form helps us streamline inquiries, ensuring that your message reaches
                                  the right team quickly.
                                </p>
                              </li>
                              <li className="about-section-list ">
                                <p className="about-section-paragraph-list">
                                  <strong className="heading-paragraph">Organised Responses:</strong>
                                  Providing specific details will help us deliver a more accurate and relevant response.
                                </p>
                              </li>
                              <li className="about-section-list">
                                <p className="about-section-paragraph-list">
                                  <strong className="heading-paragraph">Convenience:</strong>
                                  Submit your inquiry any time and we’ll get back to you as soon as possible.
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <WhyMatterContact/>
          </section>
          <section className="section-padding pt-100">
            <div className="col col-lg-12">
              <div className="wpo-event-item">
                <div className="wpo-event-details-text w-100 mt-0 text-center">
                  <h2 className="w-100 text-center text-titleeee">Customer Support Information: Direct Assistance</h2>
                </div>
                <div className="wpo-event-details-wrap">
                  <div className="wpo-event-details-tab">
                    <ul className="nav nav-tabs"></ul>
                  </div>
                  <div className="wpo-event-details-content">
                    <div className="tab-content">
                      <div className="active">
                        <div id="Schedule" className="tab-pane active">
                          <ul>
                            <li className="about-section-list">
                              <p className="about-section-paragraph-list">
                                <strong className="heading-paragraph">Email Support:</strong>
                                Reach out to us at <b>support@SevakArmy.com</b> for any issues or queries. Our team will
                                respond promptly to assist you.
                              </p>
                            </li>
                            <li className="about-section-list ">
                              <p className="about-section-paragraph-list">
                                <strong className="heading-paragraph">Phone Support:</strong>
                                Call us at ---------- during our business hours for immediate assistance. Our support
                                team is available to provide real-time help.
                              </p>
                            </li>
                            <li className="about-section-list">
                              <p className="about-section-paragraph-list">
                                <strong className="heading-paragraph">
                                  Reach out using our contact details for direct support and assistance.
                                </strong>
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-5">
              <Link onClick={ClickHandler} to="/contact-us" className="theme-btn">
                Contact us today and let us help you
              </Link>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default Contact;
