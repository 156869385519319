import { Link } from "react-router-dom";
// import {causesDataAbout} from "../../api/causes";
import { causesDataAbout } from "../../api/causes";
import SectionTitle from "../SectionTitle/SectionTitle";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const CausesSecionS3About = (props) => {
  return (
    <section className={"" + props.hclass}>
      <div className="causes-wrap paddingggggg" >
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12 col-12">
              <SectionTitle
                subtitle="Impact"
                title=" How do Companies Contribute to Social Change?
"
              />
              <p className="col-lg-12 mb-4 text-center">
                At Sevak Army, we are proud to be a bridge between businesses and the causes that need their support.
                The impact of our corporate partners extends far beyond volunteer hours - it touches lives, uplifts
                communities and addresses the most pressing issues of our time.
              </p>
            </div>
          </div>
          <div className="row d-flex justify-content-center mb-5">
            <style>
              {`
                .content {
                  min-height: 300px
                }

                @media (max-width: 1440px) {
                  .content {
                    min-height: 350px
                  }
                }

                @media (max-width: 1024px) {
                  .content {
                    min-height: 370px
                  }
                }
              `}
            </style>
            {causesDataAbout.slice(0, 3).map((cause, cky) => (
              <div className="col col-lg-3 col-md-6 col-12" key={cky}>
                <div className="causes-item">
                  <div className="image">
                    <img src={cause.image} alt="" />
                  </div>
                  <div className="content">
                    <h2 className="fw-bold">
                      {/* <Link onClick={ClickHandler} to={`/causes-single/${cause.slug}`}> */}
                      {cause.title}
                      {/* </Link> */}
                    </h2>
                    <p>{cause.description}</p>
                    {/* <div className="progress-item">
                                            <div className="progress">
                                                <div className="bar" style={{ width: `${(cause.raisedAmount / cause.goalAmount) * 100}%` }}></div>
                                            </div>
                                            <span className="cssProgress-label">{((cause.raisedAmount / cause.goalAmount) * 100).toFixed(2)}%</span>
                                            <div className="progres-label">
                                                <div className="label-on">
                                                    <p>Raised:<span>${cause.raisedAmount}</span></p>
                                                </div>
                                                <div className="label-two">
                                                    <p>Goal:<span>${cause.goalAmount}</span></p>
                                                </div>
                                            </div>
                                        </div> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CausesSecionS3About;
