import React, { useState } from "react";
import { Link } from "react-router-dom";

const AuthModal = ({ show, setShow, defaultSign = "register" }) => {
  // State for controlling modal visibility
  const formDataDefault = {
    fullName: "",
    email: "",
    mobile: "",
    password: "",
  };

  // State for form data
  const formErrorsDefault = {
    fullName: "",
    email: "",
    mobile: "",
    password: "",
  };

  const [formData, setFormData] = useState(formDataDefault);
  const [formErrors, setFormErrors] = useState(formErrorsDefault);
  const [value, setValue] = useState(""); // For selected account type
  const [sign, setSign] = useState(defaultSign); // For selected account type

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Clear validation message when the user starts typing
    setFormErrors({ ...formErrors, [name]: "" });
  };

  // Validate form inputs for registration
  const validateRegistration = () => {
    let isValid = true;
    const errors = {};

    if (!formData.fullName) {
      errors.fullName = "Full Name Is Required.";
      isValid = false;
    }
    if (!formData.email) {
      errors.email = "Email ID Is Required.";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email Is Invalid.";
      isValid = false;
    }
    if (!formData.mobile) {
      errors.mobile = "Mobile Number Is Required.";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      errors.mobile = "Mobile Number Must Be 10 Digits.";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  // Validate form inputs for login
  const validateLogin = () => {
    let isValid = true;
    const errors = {};

    if (!formData.email) {
      errors.email = "Email ID is required.";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email ID is invalid.";
      isValid = false;
    }
    if (!formData.password) {
      errors.password = "Password is required.";
      isValid = false;
    } else if (formData.password.length < 6) {
      errors.password = "Password Must Be At Least 6 Characters.";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  // Handle form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const isValid = value ? validateLogin() : validateRegistration();
    if (isValid) {
      console.log("Form submitted:", formData);
      // Reset form data after successful submission
      setFormData(formDataDefault);
      setValue("");
    }
  };

  // Handle modal close
  const handleClose = () => {
    setShow(false); // Hide the modal
    setFormData(formDataDefault); // Reset form data
    setFormErrors(formErrorsDefault); // Reset errors
    setValue(""); // Reset selected account type
  };

  const buttons = [
    { label: "Volunteer" },
    { label: "NGO" },
    { label: "Organization" },
    { label: "School/College" },
    { label: "Influencer" },
  ];

  return (
    <div
      className={`modal fade bd-example-modal-lg ${show ? "show d-block" : "d-none"}`}
      tabIndex="-1"
      data-target=".bd-example-modal-lg"
      role="dialog"
      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
    >
      <div className="modal-dialog modal-lg d-flex justify-content-center" role="document">
        <div className="modal-content d-flex p-3">
          {/* First Column */}
          {/* <div
            style={{
              width: 1200,
            }}
            className=" p-3"
          >
            <div className="modal-body">
              <img
                src="http://localhost:3036/static/media/sevak-army-home-1.95a4785f0b49d3c72119.jpg"
                alt="logo"
                style={{ width: "100%" }}
              />
            </div>
          </div> */}
          {/* Second Column */}
          <div
            // style={{
            //   width: 1200,
            // }}
            className="p-3"
          >
            <div className="modal-header">
              <h3 className=" slide-title-sub fs-2" style={{ fontWeight: "700" }}>
                Sevak Army
              </h3>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#5f6368"
                style={{
                  cursor: "pointer",
                }}
                onClick={handleClose}
              >
                <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
              </svg>
            </div>
            {value ? (
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                    flexDirection: "column",
                    alignItems: "end",
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                >
                  <button
                    className="btn theme-btn"
                    style={{ width: "fit-content" }}
                    onClick={() => {
                      setValue(""); // Clear selected account type
                      setFormData(formDataDefault); // Reset form data
                      setFormErrors(formErrorsDefault); // Reset errors
                    }}
                  >
                    Back
                  </button>
                </div>
                <h3 className="my-3 w-100 text-center" style={{ fontSize: "32px", fontWeight: "700" }}>
                  {value} {sign === "register" ? "Registration" : "Login"}
                </h3>
                <form noValidate onSubmit={handleFormSubmit}>
                  {sign === "register" && (
                    <>
                      <div className="form-group py-2">
                        <label
                          className="mb-1"
                          style={{
                            fontWeight: "bold",
                            color: "black",
                          }}
                          htmlFor="fullName"
                        >
                          Full Name
                        </label>
                        <input
                          style={{
                            borderRadius: 10,
                          }}
                          type="text"
                          className="form-control py-2"
                          id="fullName"
                          name="fullName"
                          value={formData.fullName}
                          onChange={handleInputChange}
                          placeholder="Full Name"
                        />
                        {formErrors.fullName && <p className="text-danger">{formErrors.fullName}</p>}
                      </div>
                      <div className="form-group py-2">
                        <label
                          className="mb-1"
                          style={{
                            fontWeight: "bold",
                            color: "black",
                          }}
                          htmlFor="mobile"
                        >
                          Mobile Number
                        </label>
                        <input
                          style={{
                            borderRadius: 10,
                          }}
                          type="tel"
                          className="form-control py-2"
                          id="mobile"
                          name="mobile"
                          value={formData.mobile}
                          onChange={handleInputChange}
                          placeholder="Mobile Number"
                        />
                        {formErrors.mobile && <p className="text-danger">{formErrors.mobile}</p>}
                      </div>
                    </>
                  )}
                  <div className="form-group py-2">
                    <label
                      className="mb-1"
                      style={{
                        fontWeight: "bold",
                        color: "black",
                      }}
                      htmlFor="email"
                    >
                      Email ID
                    </label>
                    <input
                      style={{
                        borderRadius: 10,
                      }}
                      type="email"
                      className="form-control py-2"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder="Email ID"
                    />
                    {formErrors.email && <p className="text-danger">{formErrors.email}</p>}
                  </div>
                  <div className="form-group py-2">
                    <label
                      className="mb-1"
                      style={{
                        fontWeight: "bold",
                        color: "black",
                      }}
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      style={{
                        borderRadius: 10,
                      }}
                      type="password"
                      className="form-control py-2 py-2"
                      id="password"
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      placeholder="Password"
                    />
                    {formErrors.password && <p className="text-danger">{formErrors.password}</p>}
                  </div>
                  {sign !== "register" && <Link to="#">Forget Password?</Link>}
                  <div className="my-4 w-100 d-flex justify-content-center">
                    <button type="submit" className="btn theme-btn my-3 mb-0 px-4 py-2 fs-4">
                      {sign === "register" ? "Register" : "Login"}
                    </button>
                  </div>
                  {sign !== "register" ? (
                    <div className="w-100 d-flex justify-content-center align-items-center">
                      Don’t have an account?{" "}
                      <Link
                        to={"#"}
                        className="p-0 m-0"
                        onClick={() => {
                          setSign("register");
                          setFormData(formDataDefault); // Reset form data
                          setFormErrors(formErrorsDefault); // Reset errors
                        }}
                      >
                        {" "}
                        &nbsp; Register{" "}
                      </Link>
                    </div>
                  ) : (
                    <p className="w-100 d-flex justify-content-center">
                      Already a Member?{" "}
                      <Link
                        to={"#"}
                        onClick={() => {
                          setSign("login");
                          setFormData(formDataDefault); // Reset form data
                          setFormErrors(formErrorsDefault); // Reset errors
                        }}
                      >
                        Log In{" "}
                      </Link>
                    </p>
                  )}
                </form>
              </div>
            ) : (
              <div className="modal-body">
                <h5>Select an account type</h5>
                {buttons.map((button) => (
                  <button key={button.label} onClick={() => setValue(button.label)} className="theme-btn my-2 mx-2">
                    {button.label}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthModal;
