


const SectionTitle = ({ title, subtitle, paragarap }) => {

    return (
        <div className="wpo-section-title mb-0 pt-0" style={{maxWidth:'860px'}}>
            <span className="text-center pt-0">{subtitle}</span>
            <h2 className="about-heading-second text-smallll w-100 mt-0 pt-0" style={{maxWidth:'860px'}}>{title}</h2>
            <p className="">{paragarap}</p>
        </div>
    )
}

export default SectionTitle;

