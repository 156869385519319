import React, { Fragment, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import { NavLink } from "react-router-dom";
import "./style.css";

const menus = [
  {
    id: 1,
    title: "Home",
    link: "/",
  },

  {
    id: 3,
    title: "Volunteer",
    link: "/volunteer",
    submenu: [
      { id: 30, title: "Home", link: "/volunteer" },
      { id: 31, title: "About Us", link: "/volunteer/about-us" },
      { id: 32, title: "Opportunities", link: "/volunteer/opportunities" },
      { id: 33, title: "Events", link: "/volunteer/events" },
      // { id: 34, title: "Blogs", link: "/volunteer/blogs" },
      { id: 35, title: "Join Us", link: "/volunteer/contact" },
    ],
  },
  {
    id: 4,
    title: "Organization",
    link: "/organization",
    submenu: [
      { id: 41, title: "Home", link: "/organization" },
      { id: 42, title: "About Us", link: "/organization/about-us" },
      { id: 43, title: "Registration", link: "/organization/registration" },
      { id: 44, title: "Employee Engagement", link: "/organization/engagement" },
      { id: 45, title: "Leaderboard", link: "/organization/leaderboard" },
      { id: 46, title: "Contact Us", link: "/organization/contact" },
      // { id: 47, title: "Blogs", link: "/organization/blogs" },
    ],
  },
  {
    id: 7,
    title: "School/College",
    link: "/school-college",
    submenu: [
      { id: 71, title: "Home", link: "/school-college" },
      { id: 72, title: "Pages", link: "/school-college/pages" },
      { id: 73, title: "Donations", link: "/school-college/donations" },
      { id: 74, title: "Events", link: "/school-college/events" },
      // { id: 75, title: "Blogs", link: "/school-college/blogs" },
      { id: 76, title: "Contact Us", link: "/school-college/contact" },
    ],
  },
  {
    id: 8,
    title: "Influencer",
    link: "/influencer",
    submenu: [
      { id: 81, title: "Home", link: "/influencer" },
      { id: 82, title: "Pages", link: "/influencer/pages" },
      { id: 83, title: "Services", link: "/influencer/services" },
      { id: 84, title: "Donation", link: "/influencer/donation" },
      // { id: 85, title: "Blogs", link: "/influencer/blogs" },
      { id: 86, title: "Contact Us", link: "/influencer/contact" },
    ],
  },
  {
    id: 9,
    title: "NGO",
    link: "/ngo",
    submenu: [
      { id: 91, title: "Home", link: "/ngo" },
      { id: 92, title: "About Us", link: "/ngo/about-us" },
      { id: 93, title: "Get Involved", link: "/ngo/get-involved" },
      { id: 94, title: "Contact Us", link: "/ngo/contact" },
      { id: 95, title: "Login/Register", link: "/ngo/login" },
    ],
  },

  {
    id: 88,
    title: "Contact",
    link: "/contact-us",
  },
];

const MobileMenu = () => {
  const [openId, setOpenId] = useState(0);
  const [menuActive, setMenuState] = useState(false);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div>
      <div className={`mobileMenu ${menuActive ? "show" : ""}`}>
        <div className="menu-close">
          <div className="clox" onClick={() => setMenuState(!menuActive)}>
            <i className="ti-close"></i>
          </div>
        </div>

        <ul className="responsivemenu">
          {menus.map((item, mn) => {
            return (
              <ListItem className={item.id === openId ? "active" : null} key={mn}>
                {item.submenu ? (
                  <Fragment>
                    <p onClick={() => setOpenId(item.id === openId ? 0 : item.id)}>
                      {item.title}
                      <i className={item.id === openId ? "fa fa-angle-up" : "fa fa-angle-down"}></i>
                    </p>
                    <Collapse in={item.id === openId} timeout="auto" unmountOnExit>
                      <List className="subMenu">
                        <Fragment>
                          {item.submenu.map((submenu, i) => {
                            return (
                              <ListItem key={i}>
                                <NavLink onClick={ClickHandler} className="active" to={submenu.link}>
                                  {submenu.title}
                                </NavLink>
                              </ListItem>
                            );
                          })}
                        </Fragment>
                      </List>
                    </Collapse>
                  </Fragment>
                ) : (
                  <NavLink className="active" to={item.link}>
                    {item.title}
                  </NavLink>
                )}
              </ListItem>
            );
          })}
        </ul>
      </div>

      <div className="showmenu mobail-menu" onClick={() => setMenuState(!menuActive)}>
        <button type="button" className="navbar-toggler open-btn">
          <span className="icon-bar first-angle"></span>
          <span className="icon-bar middle-angle"></span>
          <span className="icon-bar last-angle"></span>
        </button>
      </div>
    </div>
  );
};

export default MobileMenu;
