import simg1 from '../images/service/1.svg'
import simg2 from '../images/service/2.svg'
import simg3 from '../images/service/3.svg'
import simg4 from '../images/service/4.svg'

import sSimg1 from '../images/service-single/img1.jpg'
import sSimg2 from '../images/service-single/img2.jpg'
import sSimg3 from '../images/service-single/img3.jpg'
import sSimg4 from '../images/service-single/img4.jpg'



const Services = [
   {
      Id: '01',
      sImg: simg1,
      sSImg: sSimg1,
      title: 'Register Your Company',
      slug: '1',
      description: 'Fill out our form to create your company profile.',
   },
   {
      Id: '02',
      sImg: simg2,
      sSImg: sSimg2,
      title: 'Invite Your Employees',
      slug: '2',
      description: 'Engage your workforce to sign up and browse opportunities.',
   },
   {
      Id: '03',
      sImg: simg3,
      sSImg: sSimg3,
      title: 'Assign Tasks',
      slug: '3',
      description: ' Allocate volunteer tasks based on skills and interests.',
   },
   {
      Id: '04',
      sImg: simg4,
      sSImg: sSimg4,
      title: 'Track Impact',
      slug: '4',
      description: 'Monitor volunteer hours and celebrate achievements in real time.',
   },

]
export const ServicesAbout = [
   {
      Id: '01',
      sImg: simg1,
      sSImg: sSimg1,
      title: 'Register Your Company',
      slug: '1',
      description: 'Fill out our form to create your company profile.',
   },
   {
      Id: '02',
      sImg: simg2,
      sSImg: sSimg2,
      title: 'Invite Your Employees',
      slug: '2',
      description: 'Engage your workforce to sign up and browse opportunities.',
   },
   {
      Id: '03',
      sImg: simg3,
      sSImg: sSimg3,
      title: 'Assign Tasks',
      slug: '3',
      description: ' Allocate volunteer tasks based on skills and interests.',
   },
   {
      Id: '04',
      sImg: simg4,
      sSImg: sSimg4,
      title: 'Track Impact',
      slug: '4',
      description: 'Monitor volunteer hours and celebrate achievements in real time.',
   },

]

export default Services;