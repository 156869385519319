import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/css";
import "swiper/css/navigation";
import ServiceCard from "../ServiceSection/ServiceCard";

import simg1 from "../../images/service/1.svg";
import simg2 from "../../images/service/2.svg";
import simg3 from "../../images/service/3.svg";

import sSimg1 from "../../images/service-single/img1.jpg";
import sSimg2 from "../../images/service-single/img2.jpg";
import sSimg3 from "../../images/service-single/img3.jpg";
import SectionTitle from "../SectionTitle/SectionTitle";

import abImg1 from "../../images1/organization/registration/sevak-army-organization-registration-2.png";
import abImg2 from "../../images1/organization/registration/sevak-army-organization-registration-3.jpg";
import abImg3 from "../../images1/organization/registration/sevak-army-organization-registration-4.jpg";
import abImg4 from "../../images1/organization/registration/sevak-army-organization-registration-5.jpg";

import About4 from "../../images1/organization/registration/sevak-army-organization-registration-1.jpg";
import About4s2 from "../..//images/slider/stick-man.svg";
import RegisterYourCompany from "../Volunteer/RegisterYourCompany";
import RegisterSevakArmy from "../Volunteer/RegisterSevakArmy";

const OrganizationRegistration = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const Services = [
    {
      Id: "01",
      sImg: simg1,
      sSImg: sSimg1,
      slug: "1",
      description: [
        <h3>Register your Organization.</h3>,
        <br></br>,
        "Fill out our form to create your company profile.",
      ],
    },
    {
      Id: "02",
      sImg: simg2,
      sSImg: sSimg2,
      slug: "2",
      description: [<h3>Invite your Employees.</h3>, <br></br>, "Engage your workforce to browse opportunities."],
    },
    {
      Id: "03",
      sImg: simg3,
      sSImg: sSimg3,
      slug: "3",
      description: [
        <h3>Setup Company Profile.</h3>,
        <br></br>,
        " Allocate volunteer tasks based on skills and interests.",
      ],
    },
  ];
  useEffect(() => {
    const rotate = () => {
      const lastChild = document.querySelector(".manroted div:last-child").cloneNode(true);
      document.querySelectorAll(".manroted div").forEach((div) => div.classList.remove("firstSlide"));
      document.querySelector(".manroted div:last-child").remove();
      document.querySelector(".manroted").prepend(lastChild);
    };

    const intervalId = setInterval(rotate, 4000);

    return () => clearInterval(intervalId);
  }, []);
  return (
    <>
      <section className="wpo-about-section-s4 section-padding">
        <div className="container">
          <div className="wpo-about-wrap">
            <div className="flex-containerrr justify-content-center gap-3 align-items-center">
              <div className="w-lg-50  w-100 order-lg-2">
                <div className="wpo-about-img-s5">
                  <div className="image">
                    <img src={About4} alt="" />
                  </div>
                  <div className="wpo-supporter">
                    <div className="wpo-supporter-text">
                      <div className="icon">
                        <i>
                          <img src={About4s2} alt="" />
                        </i>
                      </div>
                      <div className="content">
                        <h3>100+</h3>
                        <p>Happy Organizations</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" order-lg-1">
                <div className="wpo-about-text">
                  <div className="wpo-section-title">
                    <span>Welcome to Sevak Army!</span>
                    <h2 className="about-heading-third text-titleeee">
                      Join Sevak Army: Making Corporate Social Responsibility Simple
                    </h2>
                  </div>
                  <p>
                    At Sevak Army, every organization has the potential to make a meaningful impact on society. Our
                    platform makes it easy for companies to get involved in volunteer activities and lead the way in
                    corporate social responsibility. Whether you’re a small business or a large corporation, we provide
                    the tools you need to engage your employees, contribute to social change and measure your impact.
                    <br></br>
                    Employees can:<br></br>
                    <b>Register your company with Sevak Army today and start making a difference!</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <section className="col-12 d-flex align-items-center justify-content-center section-padding pb-0">
          <div className="col col-lg-8 col-md-10">
            <div className="wpo-event-item">
              <div className="wpo-event-details-text w-100">
                <h2 className="w-100 text-center text-titleeee">Register Your Company and Make a Difference.</h2>
              </div>
              <div className="wpo-event-details-wrap" style={{ boxShadow: '' }}>
                <div className="wpo-event-details-tab">
                  <ul className="nav nav-tabs"></ul>
                </div>
                <div className="wpo-event-details-content">
                  <div className="tab-content">
                    <div className="active">
                      <div id="Schedule" className="tab-pane active">
                        <ul>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              Joining Sevak Army is quick and straightforward. Simply fill out our Company Registration
                              Form with your basic details and we’ll guide you through the rest. Once you’ve completed
                              the form, you’ll gain access to our platform’s full suite of CSR tools and resources.
                            </p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">Here’s what you’ll need to get started:</p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">Company Name:</strong>
                              Register your business under its official name.
                            </p>
                          </li>
                          <li className="about-section-list ">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">Industry:</strong>
                              Tell us which industry you belong to so we can match you with relevant volunteering
                              opportunities.
                            </p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">CSR Goals:</strong>
                              Share your company’s CSR vision and the causes that matter most to your organization.
                            </p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">Company Details:</strong>
                              Provide contact information and details about your company size to help us tailor
                              opportunities to your business.
                            </p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">Fill out the Form:</strong>
                              Once your company is registered, you can invite employees, manage your volunteer efforts
                              and track your social impact—all from a single platform.
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <div className="section-padding pb-0">
          {" "}
          <RegisterYourCompany />
        </div>

        <div className="container pt-100">
          <div className="wpo-about-wrap">
            <div className="flex-containerrr just-fy-content-center gap-5">
              <div className="">
                <div className="wpo-about-text">
                  <div className="wpo-section-title">
                    <h2 className="about-heading-third text-titleeee">Invite Your Team Members.</h2>
                  </div>
                  <p>
                    Getting your employees involved in volunteering is easy with Sevak Army. After registering your
                    company, you can send Employee Registration Invitation directly from our platform. This tool enables
                    you to quickly engage your workforce, invite them to join your CSR initiatives and allocate
                    volunteetasks in a few simple clicks.<br></br>
                    Employees can:<br></br>
                    <b>Create personal volunteer profiles.</b>
                    <br></br>
                    <b>Select from a variety of causes that align with their interests.</b>
                    <br></br>
                    <b>Track their volunteering hours and progress.</b>
                    <br></br>
                    <b>Earn recognition for their efforts, such as badges and rankings on the employee leaderboard.</b>
                    <br></br>
                    Start building a culture of volunteerism within your organization by inviting your team today.
                  </p>
                  <h4 className=".services-title-text">It’s simple, it’s effective and it starts with a click.</h4>
                </div>
              </div>
              <br></br>
              <div className="">
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left-side">
                    <img src={abImg2} alt="img" />
                  </div> */}
                  <div className="wpo-about-right pt-0">
                    <img src={abImg1} width={600} alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="section-padding pt-100">
          <div className="container">
            <div className="wpo-about-wrap">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="wpo-about-img">
                    {/* <div className="wpo-about-left">
                      <img src={abImg3} alt="img" />
                    </div> */}
                    <div className="wpo-about-right pt-0">
                      <img src={abImg4} alt="img" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="wpo-about-text">
                    <div className="wpo-section-title">
                      <h2 className="about-heading-second text-titleeee">Company Profile Setup.</h2>
                    </div>
                    <p>
                      Once your company is registered, it’s time to set up your Company Profile. This is where you
                      showcase your organization’s CSR mission, valuable commitment to volunteerism.<br></br>
                      Your company profile includes:<br></br>
                      <b>Company Logo:</b> Upload your logo to personalise your profile and reinforce your brand’s
                      commitment to CSR.<br></br>
                      <b>Industry Information:</b> Share details about the industry you operate in and the types of
                      volunteer opportunities you’re most interested in.<br></br>
                      <b>CSR Goals:</b> Highlight your company’s long-term goals for social responsibility, showing
                      potential partners, clients and employees to organization is dedicated to making a difference.
                      <br></br>
                      <b>Volunteering History:</b> As you complete volunteer tasks, your company’s history of
                      contributions will be displayed, showcasing the impact made through the Sevak Army platform.
                      <br></br>
                      This profile helps you manage your volunteer efforts and serves as a public commitment to social
                      good, enhancing your company’s reputation and value.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="col col-lg-8 col-md-10 ">
            <div className="wpo-event-item">
              <div className="wpo-event-details-text w-100">
                <h2 className="text-center text-titleeee">Why Register with Sevak Army?</h2>
              </div>
              <div className="wpo-event-details-wrap">
                <div className="wpo-event-details-tab">
                  <ul className="nav nav-tabs"></ul>
                </div>
                <div className="wpo-event-details-content">
                  <div className="tab-content">
                    <div className="active">
                      <div id="Schedule" className="tab-pane active">
                        <ul>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              When you join Sevak Army, you gain access to a suite of features designed to maximise your
                              CSR impact.
                            </p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">Customisable Volunteer Opportunities:</strong>
                              Tailor your volunteer efforts to causes that resonate with your company’s values and
                              industry.
                            </p>
                          </li>
                          <li className="about-section-list ">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">Employee Engagement Tools:</strong>
                              Easily invite, engage and track your employees’ volunteer progress, fostering a culture of
                              responsibicompassion.
                            </p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">Impact Measurement:</strong>
                              Track every volunteer hour, every task completed and the social change your company is
                              creating. Use these metrics to report on CSR goals and achievements.
                            </p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">Recognition and Rewards:</strong>
                              Gain visibility for your company’s volunteer efforts through our public leaderboard and
                              achievements system, building brand value and loyalty.
                            </p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">Register today and take the first step.</strong>
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <RegisterSevakArmy />

        <section className="mt-5">
          <div className="d-flex justify-content-center">
            <Link onClick={() => props.setShow(true)} to="#" className="theme-btn ">
              Register Now
            </Link>
          </div>
        </section>
      </section>
      <section className={"mb-5 pt-0 p-0" + props.hclass}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12 col-12 ">
              <SectionTitle
                subtitle="Why Choose Us?"
                title="Sevak Army makes volunteer management easy and rewarding."
              />
            </div>
          </div>
          <div className="service-wrap mt-5">
            <div className="row">
              {Services.slice(0, 4).map((Service, sitem) => (
                <div className="col col-lg-4 col-md-6 col-sm-6 col-12 " key={sitem}>
                  <ServiceCard img={Service.sImg} slug={Service.slug} sdescription={Service.description} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OrganizationRegistration;
