import React from "react";
import SectionTitle from "../SectionTitle/SectionTitle";
import CountUp from "react-countup";

// function NgoHomeStatus({props}) {
//   return (
//     <section>
//     <div className="container">
//         <div className="row justify-content-center">
//             <div className="col-lg-6 col-md-12 col-12">
//                 <SectionTitle subtitle="Ready to make a difference?" title="Join Sevak Army today where volunteers and NGOs connect to create real impact." />
//             </div>
//         </div>
//         <section className={" section-padding " + props?.hclass}>
//       <div className="container">
//         <div className="fun-fact-wrap">
//           <div className="row">
//             <div className="col-lg-3 col-md-6 col-sm-6 col-12">
//               <div className="info">
//                 <h3>
//                   <span>
//                     <CountUp end={260} enableScrollSpy />
//                   </span>
//                 </h3>
//                 <p>Total Campaigns</p>
//               </div>
//             </div>
//             <div className="col-lg-3 col-md-6 col-sm-6 col-12">
//               <div className="info">
//                 <h3>
//                   <span>
//                     <CountUp end={26} enableScrollSpy />M
//                   </span>
//                 </h3>
//                 <p>Total Fund Raised</p>
//               </div>
//             </div>
//             <div className="col-lg-3 col-md-6 col-sm-6 col-12">
//               <div className="info">
//                 <h3>
//                   <span>
//                     <CountUp end={120} enableScrollSpy />
//                   </span>
//                 </h3>
//                 <p>Happy Volunteers</p>
//               </div>
//             </div>
//             <div className="col-lg-3 col-md-6 col-sm-6 col-12">
//               <div className="info">
//                 <h3>
//                   <span>
//                     <CountUp end={15} enableScrollSpy />
//                   </span>
//                 </h3>
//                 <p>Years of Fund Raising</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//         </div>
//         </section>
//   )
// }

// export default NgoHomeStatus

function NgoHomeStatus({ hclass }) {
  return (
    <section className="section-padding">
      <div className="container mb-3">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-12 col-12">
            <SectionTitle
              subtitle="Ready to make a difference?"
              title="Join Sevak Army today where volunteers and NGOs connect to create real impact."
            />
          </div>
        </div>
        {/* <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-6 col-12">
            <div className="info">
              <h3>
                <span>
                  <CountUp end={500} enableScrollSpy />+
                </span>
              </h3>
              <p>NGOs partnered globally</p>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 col-12">
            <div className="info">
              <h3>
                <span>
                  <CountUp end={10000} enableScrollSpy />+
                </span>
              </h3>
              <p>Active volunteers</p>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 col-12">
            <div className="info">
              <h3>
                <span>
                  <CountUp end={1} enableScrollSpy />
                  M+
                </span>
              </h3>
              <p>Hours of volunteer service</p>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 col-12">
            <div className="info">
              <h3>
                <span>
                  <CountUp end={95} enableScrollSpy />%
                </span>
              </h3>
              <p>NGO satisfaction rate</p>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 col-12">
            <div className="info">
              <h3>
                <span>
                  <CountUp end={80} enableScrollSpy />+
                </span>
              </h3>
              <p>Active countries</p>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 col-12">
            <div className="info">
              <h3>
                <span>
                  <CountUp end={50000} enableScrollSpy />+
                </span>
              </h3>
              <p>Lives impacted through our platform</p>
            </div>
          </div>
        </div> */}

        <div className="card text-center shadow-lg w-100 h-100 bg-warning rounded-5 text-white p-5 mb-5 rounded mt-5">
          <div className="card-body">
            <div className="row text-center">
              <div className="col-lg-3 col-md-4 col-sm-6 ">
                <h3 className="font-weight-bold">
                  <h1 className="fw-bold text-white">
                    <CountUp end={500} enableScrollSpy />+
                  </h1>
                </h3>
                <p className="fw-bolder text-white">NGOs partnered globally</p>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 ">
                <h3 className="font-weight-bold">
                  <h1 className="fw-bold text-white">
                    <CountUp end={10000} enableScrollSpy />+
                  </h1>
                </h3>
                <p className="fw-bolder text-white">Active volunteers</p>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 ">
                <h3 className="font-weight-bold">
                  <h1 className="fw-bold text-white">
                    <CountUp end={1} enableScrollSpy />
                    M+
                  </h1>
                </h3>
                <p className="fw-bolder text-white">Hours of volunteer service</p>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 ">
                <h3 className="font-weight-bold">
                  <h1 className="fw-bold text-white">
                    <CountUp end={95} enableScrollSpy />%
                  </h1>
                </h3>
                <p className="fw-bolder text-white">NGO satisfaction rate</p>
              </div>

              {/* <div className="col-lg-3 col-md-4 col-sm-6 ">
            <h3 className="font-weight-bold">
              <span className="fw-bold text-white">
                <CountUp end={80} enableScrollSpy />+
              </span>
            </h3>
            <p className="fw-bolder text-white">Active countries</p>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6  ">
            <h3 className="font-weight-bold">
              <span className="fw-bold text-white">
                <CountUp end={50000} enableScrollSpy />+
              </span>
            </h3>
            <p className="fw-bolder text-white">Lives impacted through our platform</p>
          </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default NgoHomeStatus;
