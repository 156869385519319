import React, { useState } from "react";
import { Link } from "react-router-dom";
import icon from "../../images/cta/top-icon.png";
import { causesData } from "../../api/causes";
import SectionTitle from "../SectionTitle/SectionTitle";
import blogs from "../../api/blogs";

import Instagram from "../../api/Instagram";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay } from "swiper";

/* team imags */
import team1 from "../../images1/team/sevak-army-team-1.jpg";
import team2 from "../../images1/team/sevak-army-team-2.jpg";
import team3 from "../../images1/team/sevak-army-team-7.jpg";
import team4 from "../../images1/team/sevak-army-team-5.jpg";
import team5 from "../../images1/team/sevak-army-team-10.jpg";

/* HeroSlide Image  */
import HeroSlide1 from "../../images1/organization/home/sevak-army-organization-home-slider-11.png";
import HeroSlide2 from "../../images1/organization/home/sevak-army-organization-home-slider-2.jpg";
import HeroSlide3 from "../../images1/organization/home/sevak-army-organization-home-slider-33.png";
import HeroSlide4 from "../../images1/organization/home/sevak-army-organization-home-slider-14.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import About4 from "../../images1/organization/home/sevak-army-organization-home-1.jpg";
import About4s2 from "../..//images/slider/stick-man.svg";

import Testimonial from "../../api/testimonial";
import Shape from "../../images/testimonial/bg.png";

const Home = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
  };

  const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <i className="flaticon-right-arrow-1"></i>
      </div>
    );
  };

  const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <i className="flaticon-left-arrow"></i>
      </div>
    );
  };

  const settings1 = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const openModal = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  const settings3 = {
    dots: false,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: true,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="static-hero-s3">
        <div className="hero-container">
          <div className="hero-inner">
            <div className="container-fluid">
              <div className="hero-content heroo-contenttt">
                <div className="slide-title-sub">
                  <h6>Welcome to Sevak Army!</h6>
                </div>
                <div className="slide-title">
                  <h2>Join Sevak Army: Making Corporate Social Responsibility Simple</h2>
                </div>
                <div className="slide-text">
                  <p>Register your company with Sevak Army today and start making a difference!</p>
                </div>
                <div className="hero-btn">
                  <Link onClick={() => props.setShow(true)} to="#" className="theme-btn">
                    Join Our Team
                  </Link>
                  <div className="wpo-supporter-img">
                    <ul className="wpo-supporter-slide">
                      <Slider {...settings}>
                        <li data-bs-toggle="tooltip" data-bs-html="true" title="Aliza Anny">
                          <img src={team1} alt="" />
                        </li>
                        <li data-bs-toggle="tooltip" data-bs-html="true" title="David Miller">
                          <img src={team2} alt="" />
                        </li>
                        <li data-bs-toggle="tooltip" data-bs-html="true" title="Maria Silva">
                          <img src={team3} alt="" />
                        </li>
                        <li data-bs-toggle="tooltip" data-bs-html="true" title="Takila Mas">
                          <img src={team4} alt="" />
                        </li>
                        <li data-bs-toggle="tooltip" data-bs-html="true" title="Jenny Lawba">
                          <img src={team5} alt="" />
                        </li>
                      </Slider>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Swiper
          modules={[Autoplay, Navigation, A11y]}
          spaceBetween={0}
          slidesPerView={1}
          loop={true}
          speed={1000}
          parallax={true}
          navigation
          pagination={{ clickable: true }}
          autoplay={{
            delay: 1800,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
        >
          <SwiperSlide>
            <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${HeroSlide1})` }}></div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${HeroSlide2})` }}></div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${HeroSlide3})` }}></div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${HeroSlide4})` }}></div>
          </SwiperSlide>
          ...
        </Swiper>
      </section>
      <section className="wpo-about-section-s4 section-padding">
        <div className="container">
          <div className="wpo-about-wrap">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12  order-lg-2">
                <div className="wpo-about-img-s5">
                  <div className="image">
                    <img src={About4} alt="" />
                  </div>
                  <div className="wpo-supporter">
                    <div className="wpo-supporter-text">
                      <div className="icon">
                        <i>
                          <img src={About4s2} alt="" />
                        </i>
                      </div>
                      <div className="content">
                        <h3>100+</h3>
                        <p>Happy Organizations</p>
                      </div>
                    </div>
                    <div className="wpo-supporter-img">
                      <ul className="wpo-supporter-slide">
                        <Slider {...settings}>
                          <li data-bs-toggle="tooltip" data-bs-html="true" title="Aliza Anny">
                            <img src={team1} alt="" />
                          </li>
                          <li data-bs-toggle="tooltip" data-bs-html="true" title="David Miller">
                            <img src={team2} alt="" />
                          </li>
                          <li data-bs-toggle="tooltip" data-bs-html="true" title="Maria Silva">
                            <img src={team3} alt="" />
                          </li>
                          <li data-bs-toggle="tooltip" data-bs-html="true" title="Takila Mas">
                            <img src={team4} alt="" />
                          </li>
                          <li data-bs-toggle="tooltip" data-bs-html="true" title="Jenny Lawba">
                            <img src={team5} alt="" />
                          </li>
                        </Slider>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 order-lg-1">
                <div className="wpo-about-text">
                  <div className="wpo-section-title">
                    <span>Welcome to Sevak Army!</span>
                    <h2 className="text-titleeee">You’re the Hope of Others.</h2>
                  </div>
                  <p>
                    Are you ready to make a difference? Join Sevak Army today and start your journey towards meaningful,
                    sustainable social impact. Whether you’re looking to engage your employees, boost your company’s
                    reputation or simply give back to your community, Sevak Army is here to guide you every step of the
                    way.
                  </p>
                  {/* <div className="about-btn ">
                    <Link to="/about" className="theme-btn d-flex justify-constent-center">
                      Discover More
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wpo-cta-section-s3">
        <div className="container">
          <div className="bg-color">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="cta-wrap">
                  <div className="icon">
                    <img src={icon} alt="" />
                  </div>
                  <span>Help us raise them up.</span>
                  <h2>Your donation means a lot to them. Donate what you can.</h2>
                  <Link onClick={ClickHandler} to="/donate" className="theme-btn">
                    Start Donating Them
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={"section-padding"}>
        <div className="container">
          <div className="wpo-testimonial-slider">
            <Slider {...settings1}>
              {Testimonial.map((Testimonial, item) => (
                <div className="testimonial-item" key={item}>
                  <div className="content">
                    <div className="icon">
                      <i className="flaticon-quote"></i>
                    </div>
                    <p>{Testimonial.text}</p>
                    <div className="shape">
                      <img src={Shape} alt="" />
                    </div>
                  </div>
                  <div className="bottom-content">
                    <div className="image">
                      <img src={Testimonial.image} alt="" />
                    </div>
                    <div className="text">
                      <h3>{Testimonial.title}</h3>
                      <span>{Testimonial.subtitle}</span>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
      <section className={" section-padding"}>
        <div className="container-fluid">
          <SectionTitle subtitle="Our Gallery" title="Look Into Our Gallery." />

          <div className="instagram-wrap">
            <div className="instagram-slider">
              <Slider {...settings3}>
                {Instagram.map((item) => (
                  <div className="instagram-card" key={item.id}>
                    <div className="image">
                      <Link
                        to={item.imag}
                        className="fancybox"
                        data-fancybox-group="gall-1"
                        onClick={(e) => {
                          e.preventDefault();
                          openModal(item.imag);
                        }}
                      >
                        <img src={item.imag} alt={`Instagram ${item.id}`} className="img img-responsive" />
                        <div className="popup-icon">
                          <i className="ti-plus"></i>
                        </div>
                      </Link>
                    </div>
                    <div className="text">
                      <span>{item.title}</span>
                      <h2>{item.subtitle}</h2>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>

        {modalOpen && (
          <div className="modal" onClick={closeModal}>
            <div className="modal-wrap">
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <span className="close" onClick={closeModal}>
                  &times;
                </span>
                <img src={selectedImage} alt="Modal" />
              </div>
            </div>
          </div>
        )}
      </section>
      <section className={props.hclass}>
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div className="col-lg-6 col-md-12 col-12">
              <SectionTitle subtitle="Blogs" title="Explore our latest insights on corporate volunteerism and CSR." />
            </div>
          </div>
          <div className="blog-wrap mt-5">
            <div className="row">
              {blogs.map((blog, bl) => (
                <div className="col col-lg-3 col-md-6 col-12" key={bl}>
                  <div className="blog-single-card">
                    <div className="image">
                      <img src={blog.screens} alt="" />
                      <div className="top-date">
                        {blog.create_at}
                        <div className="shape">
                          <svg width="8" height="9" viewBox="0 0 8 9" fill="none">
                            <path d="M7.99999 9.0001L-9.79769e-05 9.00023L8.00005 0.500149L7.99999 9.0001Z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="content">
                      <ul className="top-content">
                        <li>
                          <div className="image-single">
                            <img src={blog.bsing} alt="" />
                          </div>
                          {blog.athor}
                        </li>
                        <li>
                          <i className="flaticon-chatting"></i>
                          03 Comments
                        </li>
                      </ul>
                      <h2>
                        <Link className="blog-title-home" onClick={ClickHandler} to={`/blog-single/${blog.slug}`}>
                          {blog.title}
                        </Link>
                      </h2>
                      <p>{blog.subtitle}</p>
                    </div>
                    {/* <div className="blog-btn">
                      <Link onClick={ClickHandler} to={`/blog-single/${blog.slug}`}>
                        <span>Read More...</span>
                        <i className="flaticon-right-arrow-2"></i>
                      </Link>
                    </div> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
