import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import About1 from "../../images1/ngo/about-us/sevak-army-ngo-about-us-3.jpg";
import About2 from "../../images1/ngo/about-us/sevak-army-ngo-about-us-2.jpg";
import About3 from "../../images1/ngo/about-us/sevak-army-ngo-about-us-3.jpg";
import About4 from "../../images1/ngo/about-us/sevak-army-ngo-about-us-4.jpg";
import About5 from "../../images1/ngo/about-us/sevak-army-ngo-about-us-6.jpg";
import About6 from "../../images1/ngo/about-us/sevak-army-ngo-about-us-5.jpg";

function NgoAboutFirstSection() {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const featuresData = [
    {
      iconClass: "flaticon-volunteer",
      title: "Our Mission",
      description:
        "At Sevak Army, we are on a mission to bring enthusiastic volunteers and NGOs together to create real, lasting change. We make it easy for passionate people who genuinely want to help and dedicated organizations that truly need support to connect, collaborate, work together and ultimately make a significant difference in communities around you.",
    },
    {
      iconClass: "flaticon-solidarity",
      title: "Our Vision",
      description:
        "To create a world where volunteers and NGOs unite effortlessly to drive meaningful change. We envision communities empowered through collaboration, with every act of service contributing to a lasting global impact. At Sevak Army, we strive for a future where helping hands connect seamlessly, creating sustainable progress for generations to come.",
    },
    {
      iconClass: "flaticon-charity",
      title: "Our Values",
      description:
        "Sevak Army values teamwork, believing in collaboration to achieve more. We empower volunteers and NGOs by providing essential tools for success. Integrity is at our core, ensuring transparency and trustworthiness. We build a supportive community where everyone feels valued. Innovation drives us to maximize impact. These principles guide our mission to create positive change.",
    },
  ];
  return (
    <Fragment>
      <section className="wpo-about-section mb-0 mt-5 pb-0 pt-5">
        <div className="container ">
          <div className="wpo-about-wrap">
            <div className="flex-containerrr justify-content-center">
              <div className="">
                <div className="wpo-about-img">
                  <div className="bg-image">
                    <img src={About1} width={650} alt="" />
                  </div>
                  {/* <div className="img-1">
                    <img width={230} className="img-fluid" src={About2} alt="" />
                  </div> */}
                  {/* <div className="img-2">
                    <img width={230} className="img-fluid" src={About3} alt="" />
                  </div> */}
                  {/* <div className="img-3">
                    <img width={230} className="img-fluid" src={About4} alt="" />
                  </div> */}
                  {/* <div className="img-4">
                  <img width={230} className="img-fluid" src={About4} alt="" />
                  </div> */}
                  {/* <div className="img-5">
                    <img width={230} className="img-fluid" src={About6} alt="" />
                  </div> */}
                </div>
              </div>
              <div className="">
                <div className="wpo-about-text">
                  <div className="wpo-section-title">
                    <h2 className="text-titleeee">About Us</h2>
                  </div>
                  <p className="volunteer-home-text">
                    Sevak Army bridges the gap between passionate volunteers and dedicated NGOs, fostering impactful
                    collaboration for real, lasting change. We simplify the connection process, empowering individuals
                    and organizations to work together seamlessly for global community impact. Driven by our core values
                    of teamwork, empowerment, integrity, community and innovation, we strive to build a future where
                    every helping hand contributes to a brighter, more inclusive and sustainable world. Through
                    innovative technology, shared purpose and a commitment to social responsibility, we enable
                    meaningful relationships that transcend borders, inspire positive action and drive transformative
                    change across diverse communities globally. By nurturing partnerships and facilitating long-term
                    support, Sevak Army aims to create resilient networks, amplifying the collective efforts toward
                    social equity and environmental stewardship.
                  </p>

                  {/* <div className="about-info-wrap">
                    <div className="about-info-item">
                      <div className="about-info-icon">
                        <div className="icon">
                          <i className="fi flaticon-target"></i>
                        </div>
                      </div>
                      <div className="about-info-text">
                        <h4>Our Mission</h4>
                        <p>We believe charity is a lifetime investment.</p>
                      </div>
                    </div>
                    <div className="about-info-item">
                      <div className="about-info-icon">
                        <div className="icon">
                          <i className="fi flaticon-mountain"></i>
                        </div>
                      </div>
                      <div className="about-info-text">
                        <h4>Our Vision</h4>
                        <p>We believe charity is a lifetime investment.</p>
                      </div>
                    </div>
                  </div>

                  <Link
                    onClick={ClickHandler}
                    to="/about"
                    className="theme-btn"
                  >
                    Discover More
                  </Link> */}
                </div>
              </div>
            </div>
            <section className="wpo-features-area">
              <div className="features-wrap">
                <div className="row">
                  {featuresData.map((feature, index) => (
                    <div className="col col-lg-4 col-md-6 col-12" key={index}>
                      <div className="feature-item-wrap">
                        <div className="feature-item">
                          <div className="feature-text">
                            <h2 className=" fw-bold">{feature.title}</h2>
                            <p className="fs-6 p-2" style={{ textAlign: "justify" }}>
                              {feature.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default NgoAboutFirstSection;
