import React from "react";
import ServiceCard from "../ServiceSection/ServiceCard";
import simg1 from "../../images/service/puzzle.png";
import simg2 from "../../images/service/support.png";
import simg3 from "../../images/service/community.png";
import simg4 from "../../images/service/recog.png";
import sSimg1 from "../../images/service-single/img1.jpg";
import sSimg2 from "../../images/service-single/img2.jpg";
import sSimg3 from "../../images/service-single/img3.jpg";
import sSimg4 from "../../images/service-single/img4.jpg";
import SectionTitle from "../SectionTitle/SectionTitle";

function NgoWhatWeDo() {
  const Services = [
    {
      Id: "01",
      sImg: simg1,
      sSImg: sSimg1,
      title: "Perfect Matches",
      slug: "1",
      description:
        "We connect volunteers to opportunities that align with their skills, creating meaningful community impact.",
    },
    {
      Id: "02",
      sImg: simg2,
      sSImg: sSimg2,
      title: "Support NGOs",
      slug: "2",
      description:
        "Our tools and resources assist NGOs in efficiently managing volunteers and operations to enhance their outreach and community impact.",
    },
    {
      Id: "03",
      sImg: simg3,
      sSImg: sSimg3,
      title: "Building Community",
      slug: "3",
      description:
        "Sevak Army unites individuals to share experiences, fostering connections and achieving goals within our community.",
    },
    {
      Id: "04",
      sImg: simg4,
      sSImg: sSimg4,
      title: "Recognition & Rewards",
      slug: "4",
      description: "We celebrate contributions through reward programs that motivate dedication and excellence among.",
    },
  ];

  return (
    <section>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-12 col-12">
            <SectionTitle subtitle="What We Do?" title="Together for a stronger impact." />
          </div>
        </div>
        <div className="service-wrap">
          <div className="row">
            {Services.slice(0, 4).map((Service, sitem) => (
              <div className="col col-lg-3 col-md-6 col-sm-6 col-12" key={sitem}>
                <ServiceCard
                  title={Service.title}
                  img={Service.sImg}
                  slug={Service.slug}
                  sdescription={Service.description}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default NgoWhatWeDo;
