import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Logo from "../../images/logo.svg";
import NgoHero from "../ngohero/NgoHero";
import NgoFeaturesData from "../ngoFeaturesData/NgoFeaturesData";
import NgoHomeAbout from "../NgoHomeAbout/NgoHomeAbout";
import NgoWhatWeDo from "../NgoWhatWeDo/NgoWhatWeDo";
import NgoHomeStatus from "../NgoHomeStatus/NgoHomeStatus";
import NgoHomeTestimonal from "../NgoHomeTestimonal/NgoHomeTestimonal";
import NgoBlockHome from "../NgoHomeBlock/NgoHomeBlock";

function NgoHome() {
  return (
    <Fragment>
      <Navbar Logo={Logo} hclass={"wpo-site-header"} />
      <NgoHero />
      <NgoFeaturesData />
      <NgoHomeAbout />
      <NgoWhatWeDo />
      <NgoHomeStatus hclass={"wpo-fun-fact-section"} />
      <NgoHomeTestimonal hclass={"wpo-testimonial-section"} />
      <NgoBlockHome />
      {/* <TestimonialSection hclass={"wpo-testimonial-section"} /> */}
      {/* <ServiceSection hclass={'wpo-service-section'} />
    <EventSection />
    <FunFact hclass={'wpo-fun-fact-section'} />
    // <TestimonialSection hclass={"wpo-testimonial-section"} />
    <InstagramSection hclass={"wpo-instagram-section"} />
    <BlogSection hclass={"wpo-blog-section-s2 section-padding"} />
  
    <CtaSection/> */}
      <Footer />
      <Scrollbar />
    </Fragment>
  );
}

export default NgoHome;
