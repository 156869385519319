import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Logo from "../../images/logo.svg";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Home from "../../components/Organization/home";
import ServiceSection from "../../components/ServiceSection/ServiceSection";

const OrganizationHome = () => {
  return (
    <>
      <Fragment>
        <Navbar Logo={Logo} hclass={"wpo-site-header-s5"} />
        <Home />
        <ServiceSection hclass={"wpo-service-section s1 "} />
        <Footer />
        <Scrollbar />
      </Fragment>
    </>
  );
};
export default OrganizationHome;
