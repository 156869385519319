import blog1 from "../../images1/team/sevak-army-team-7.jpg";
import blog2 from "../../images1/team/sevak-army-team-3.jpg";
import blog3 from "../../images1/team/sevak-army-team-8.jpg";

import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle/SectionTitle";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const blogs = [
  {
    id: "1",
    title: "Creating Lasting Community Impact Through Service.",
    slug: "1",
    subtitle: "Sevak Army helped me make a real difference. I always struggled to find the right platform until now.",
    bsing: blog1,
    athor: "Ritu S",
  },
  {
    id: "2",
    title: "Empowering college students to create change through service.",
    slug: "2",
    subtitle:
      "Sevak Army made volunteering easy despite my busy academic schedule. I could contribute remotely from anywhere.",
    bsing: blog2,
    athor: "Arjun P",
  },
  {
    id: "3",
    title: "Engaging working professionals in impactful volunteer service.",
    slug: "3",
    subtitle:
      "Balancing work and volunteering seemed impossible until I found Sevak Army. It fit perfectly into my schedule.",
    bsing: blog3,
    athor: "Priya R",
  },
];

const Volunteerhometestmonial = (props) => {
  return (
    <section className={props.hclass}>
      <div className="container">
        <div className="row justify-content-center mt-5">
          <div className="col-lg-6 col-md-12 col-12">
            <SectionTitle title="Testimonials" />
          </div>
        </div>
        <div className="blog-wrap">
          <div className="row">
            {blogs.map((blog, bl) => (
              <div className="col col-lg-4 col-md-6 col-12" key={bl}>
                <div className="blog-single-card">
                  <div className="content p-3">
                    <h2>
                      <Link className="blog-title-home mt-2" onClick={ClickHandler} to={``}>
                        {blog.title}
                      </Link>
                    </h2>
                    <p>{blog.subtitle}</p>
                    <ul className="top-content">
                      <li className="mb-3">
                        <div className="image-single">
                          <img src={blog.bsing} alt="" />
                        </div>
                        {blog.athor}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Volunteerhometestmonial;
