import React, { useState } from "react";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/css";
import "swiper/css/navigation";

import abImg1 from "../../images1/organization/employee-engagement/sevak-army-organization-employee-engagement-1.jpg";
import abImg2 from "../../images1/organization/employee-engagement/sevak-army-organization-employee-engagement-2.jpg";
import abImg3 from "../../images1/organization/employee-engagement/sevak-army-organization-employee-engagement-3.jpg";
import abImg4 from "../../images1/organization/employee-engagement/sevak-army-organization-employee-engagement-44.png";
import abImg5 from "../../images1/organization/employee-engagement/sevak-army-organization-employee-engagement-5.jpg";
import abImg6 from "../../images1/organization/employee-engagement/sevak-army-organization-employee-engagement-6.jpg";
import abImg7 from "../../images1/organization/employee-engagement/sevak-army-organization-employee-engagement-7.jpg";
import abImg8 from "../../images1/organization/employee-engagement/sevak-army-organization-employee-engagement-8.png";
import SectionTitle from "../SectionTitle/SectionTitle";
import TrackEmployee from "../Volunteer/TrackEmployee";
import RangeOfTasks from "../Volunteer/RangeOfTasks";
import AuthModal from "../../auth-modal";

const EmployeeEngagementPage = () => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
  };
  const [show, setShow] = useState(false);
  return (
    <>
      <section className="wpo-about-section section-padding">
        <div className="container">
          <div className="wpo-about-wrap">
            <div className="flex-containerrr justify-content-center gap-5">
              <div className="w-lg-50 w-100">
                <div className="wpo-about-text">
                  <div className="wpo-section-title">
                    <span>Welcome to Sevak Army!</span>
                    <h2 className="about-heading-third text-titleeee">Empowering Employees to Drive Social Change.</h2>
                  </div>
                  <p>
                    At Sevak Army, every employee has the power to make a difference. By engaging your workforce in
                    meaningful volunteer activities, your company strengthens its social responsibility efforts and
                    enhances employee morale, teamwork and satisfaction. Our platform makes it simple for organizations
                    to assign tasks, track volunteer hours and celebrate achievements while fostering a culture of
                    purpose and giving back.<br></br>
                    <b>
                      Empower your employees to be a part of something bigger—engage them through Sevak Army’s seamless
                      platform today.
                    </b>
                    <br></br>
                  </p>
                </div>
              </div>
              <div className="">
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left-side">
                    <img src={abImg2} alt="img" />
                  </div> */}
                  <div className="wpo-about-right pt-5">
                    <img src={abImg2} width={850} alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="section-padding">
          <div className="container">
            <div className="wpo-about-wrap">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="wpo-about-img">
                    {/* <div className="wpo-about-left">
                    <img src={abImg3} alt="img" />
                  </div> */}
                    <div className="wpo-about-right pt-0">
                      <img src={abImg4} alt="img" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="wpo-about-text">
                    <div className="wpo-section-title">
                      <h2 className="about-heading-second text-titleeee">Assigning Meaningful Volunteer Work.</h2>
                    </div>
                    <p>
                      Getting started with employee engagement is easy. Once your company is registered, you can begin
                      assigning volunteer tasks to your employees through our intuitive <b>Task Allocation</b> system.
                      This feature enables companies to:<br></br>
                      <b>Customise Tasks:</b> Tailor volunteer assignments based on your employees’ skills, interests
                      and availability. From environmental clean-ups to community outreach programmes, Sevak Army offers
                      a diverse range of volunteering opportunities to suit every interest.
                      <br></br>
                      <b>Assign by Department or Group:</b> Easily assign tasks to specific teams, departments or even
                      individual employees. Encourage collaboration across departments by creating group tasks that
                      foster teamwork and a shared sense of purpose.
                      <br></br>
                      <b>Track Task Progress:</b> Keep an eye on the progress of each task in real-time. Our platform
                      allows you to see which employees are involved, the hours they’ve contributed and the tasks
                      they’ve completed.
                      <br></br>
                      By simplifying and flexibly managing task allocation, Sevak Army helps companies maximise their
                      volunteer efforts while ensuring that each employee is engaged in meaningful work aligned with
                      their interests.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section>
      <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="col col-lg-8 col-md-10 ">
            <div className="wpo-event-item">
              <div className="wpo-event-details-text w-100">
                <h2 className="text-center text-titleeee">Track Employee Contributions with Ease</h2>
              </div>
              <div className="wpo-event-details-wrap">
                <div className="wpo-event-details-tab">
                  <ul className="nav nav-tabs"></ul>
                </div>
                <div className="wpo-event-details-content">
                  <div className="tab-content">
                    <div className="active">
                      <div id="Schedule" className="tab-pane active">
                        <ul>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              Sevak Army’s platform includes powerful tools for tracking employee volunteer efforts.
                              Through the <b>Volunteer Progress</b> dashboard, organizations can monitor and assess
                              their employees’ participation, ensuring that everyone has the opportunity to contribute
                              and make an impact.
                            </p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">Here’s how it works:</p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">Real-Time Data:</strong>
                              Track volunteer hours, tasks completed and the overall impact of each employee’s efforts
                              in real-time.
                            </p>
                          </li>
                          <li className="about-section-list ">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">Detailed Reports:</strong>
                              Generate reports that highlight your company’s collective contributions.
                            </p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">Individual Employee Profiles:</strong>
                              Each employee has their volunteer profile where they can track their progress.
                            </p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              Tracking progress not only ensures that your CSR goals are met, but it also recognises the
                              dedication of your employees—boosting morale and encouraging further participation.
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

        <TrackEmployee />
        {/* <section>
       <div className="col-12 d-flex align-items-center justify-content-center pt-100">
          <div className="col col-lg-8 col-md-10">
            <div className="wpo-event-item">
              <div className="wpo-event-details-text w-100">
                <h2 className="text-center text-titleeee">Explore a Range of Tasks for Your Employees.</h2>
              </div>
              <div className="wpo-event-details-wrap">
                <div className="wpo-event-details-tab">
                  <ul className="nav nav-tabs"></ul>
                </div>
                <div className="wpo-event-details-content">
                  <div className="tab-content">
                    <div className="active">
                      <div id="Schedule" className="tab-pane active">
                        <ul>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              At Sevak Army, we understand that volunteerism is not one-size-fits-all. That’s why we
                              offer a diverse array of <b>Volunteering Opportunities</b> tailored to different
                              industries, causes and company cultures.
                            </p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">Available Volunteer Tasks Include:</p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">Community Development:</strong>
                              From building homes to organising community events, these tasks focus on improving local
                              infrastructure and fostering stronger communities.
                            </p>
                          </li>
                          <li className="about-section-list ">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">Environmental Initiatives:</strong>
                              Tasks like beach clean-ups, tree planting and wildlife conservation are perfect for
                              companies committed to sustainability and environmental stewardship.
                            </p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">Education & Mentorship:</strong>
                              Empower the next generation by volunteering in schools, mentoring underprivileged students
                              and leading workshops that equip young people with skills for the future.
                            </p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">Healthcare & Wellness:</strong>
                              Get involved in health camps, blood donation drives and mental health awareness campaigns.
                            </p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              <strong className="heading-paragraph">Poverty Alleviation:</strong>
                              Help provide basic necessities like food, clothing and shelter to those living in poverty.
                            </p>
                          </li>
                          <li className="about-section-list">
                            <p className="about-section-paragraph-list">
                              By offering a wide range of volunteering opportunities, Sevak Army ensures that every
                              company can find tasks that align with their mission and values, while also giving
                              employees the chance to volunteer for causes they are passionate about.
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       </section> */}

        <div className="section-padding pb-0">
          <RangeOfTasks />
        </div>
        <section className="section-padding pt-100">
          <div className="container">
            <div className="wpo-about-wrap">
              <div className="flex-containerrr justify-content-center gap-5">
                <div className="w-lg-50 w-100">
                  <div className="wpo-about-text">
                    <div className="wpo-section-title">
                      {/* <span>Welcome to Sevak Army!</span> */}
                      <h2 className="about-heading-third text-titleeee">
                        Celebrate Volunteerism with Achievements and Badges.
                      </h2>
                    </div>
                    <p>
                      Recognising and celebrating your employees’ volunteer efforts is key to fostering long-term
                      engagement.<br></br>
                      <b>How It Works:</b>
                      <br></br>
                      <b>Achievement Milestones:</b> Employees can earn badges and awards for hitting certain
                      milestones, such as completing a specific number of volunteer hours or tasks.<br></br>
                      <b>Leaderboard Recognition:</b> Employees who consistently contribute to volunteer efforts can
                      appear on our Individual Employee Leaderboard, which showcases top volunteers across the platform.
                      <br></br>
                      <b>Company-Wide Celebrations:</b> Recognise top-performing employees during company meetings or
                      events.<br></br>
                      Celebrating employee achievements not only boosts engagement but also enhances your company’s
                      reputation as a leader in corporate social responsibility.
                    </p>
                    <br></br>
                  </div>
                </div>
                <div className="">
                  <div className="wpo-about-img">
                    {/* <div className="wpo-about-left-side">
                    <img src={abImg6} alt="img" />
                  </div> */}
                    <div className="wpo-about-right pt-0">
                      <img src={abImg5} alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="wpo-about-wrap">
            <div className="row flex-wrap-reverse">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img">
                  {/* <div className="wpo-about-left">
                    <img src={abImg8} alt="img" />
                  </div> */}
                  <div className="wpo-about-right pt-0">
                    <img src={abImg8} alt="img" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text">
                  <div className="wpo-section-title">
                    <h2 className="about-heading-second text-titleeee">Why Employee Engagement Matters?</h2>
                  </div>
                  <p>
                    Engaging your employees in volunteerism does more than just contribute to societal good—it also
                    drives benefits for your organization, including:<br></br>
                    <b>Increased Employee Retention:</b> Studies show that employees who engage in volunteer activities
                    feel more connected to their company’s mission and are more likely to stay long-term.<br></br>
                    <b>Boosted Morale and Teamwork:</b> Volunteering together fosters stronger bonds among employees,
                    improving collaboration and morale in the workplace.<br></br>
                    <b>Enhanced Company Reputation:</b> Companies that actively support CSR initiatives are viewed more
                    favourably by consumers, partners and potential employees.<br></br>
                    <b>Personal Growth for Employees:</b> Volunteering helps employees develop new skills, gain
                    leadership experience and grow personally and professionally—all of which contribute to their
                    overall job satisfaction.<br></br>
                    By actively engaging employees in volunteer activities, your company can create a positive workplace
                    culture where social responsibility and employee well-being go hand in hand.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={"section-padding pt-0"}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12 col-12 ">
              <SectionTitle
                title="Get Started with Employee Engagement Today."
                subtitle="Sevak Army makes it easy for your company to inspire, engage and track your employees’ volunteer efforts. With our comprehensive tools, you can seamlessly integrate corporate volunteerism into your organization, creating a culture of giving back that benefits both your company and society as a whole."
              />
            </div>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <Link onClick={() => setShow(true)} to="#" className="theme-btn">
              Register Your Company Today
            </Link>
          </div>
        </div>
      </section>
      <AuthModal show={show} setShow={setShow} />
    </>
  );
};

export default EmployeeEngagementPage;
