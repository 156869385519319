import Navbar from "../../components/Navbar/Navbar";
import Logo from "../../images/logo.svg";
import Footer from "../../components/footer/Footer";
import abut3 from "../../images1/influencer/pages/sevak-army-influencer-pages-1.jpg";
import abutShape3 from "../../images/about/shape-2.png";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Shape from "../../images/testimonial/bg.png";
import PageTitle from "../../components/pagetitle/PageTitle";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import Cimg1 from "../../images1/influencer/pages/sevak-army-influencer-pages-5.jpg";
import Cimg2 from "../../images1/influencer/pages/sevak-army-influencer-pages-3.jpg";
import Cimg4 from "../../images1/influencer/pages/sevak-army-influencer-pages-4.png";
import Cimg3 from "../../images1/influencer/pages/sevak-army-influencer-pages-2.jpg";

import Cta1 from "../../images/cta/shape-1.png";
import Cta2 from "../../images/cta/top-icon.png";
import Cta3 from "../../images/cta/shape-2.png";

import Csimg1 from "../../images/cause-single/1.jpg";
import Csimg2 from "../../images/cause-single/2.jpg";
import Csimg3 from "../../images/cause-single/3.jpg";
import Csimg4 from "../../images/cause-single/4.jpg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Instagram from "../../api/Instagram";

import TeamCard from "../../components/TeamSection/TeamCard";
import timg1 from "../../images1/team/sevak-army-team-10.jpg";
import timg2 from "../../images1/team/sevak-army-team-1.jpg";
import timg3 from "../../images1/team/sevak-army-team-8.jpg";
import timg4 from "../../images1/team/sevak-army-team-2.jpg";

import image1 from "../../images1/team/sevak-army-team-10.jpg";
import image2 from "../../images1/team/sevak-army-team-8.jpg";
import image3 from "../../images1/team/sevak-army-team-3.jpg";
import image4 from "../../images1/team/sevak-army-team-6.jpg";
import image5 from "../../images1/team/sevak-army-team-5.jpg";
import image6 from "../../images1/team/sevak-army-team-9.jpg";
import image7 from "../../images1/team/sevak-army-team-2.jpg";
import Scrollbar from "../../components/scrollbar/scrollbar";

const Testimonial1 = [
  {
    id: "01",
    text: "Sevak Army Influencer helped me use my platform for meaningful causes. Their team ensured the campaigns aligned with my values, making every collaboration deeply impactful. The feedback and real-world impact I've seen have been incredibly rewarding. It's more than just a partnership—it's a mission.",
    title: "Ritika Sharma",
    subtitle: "Social Media Influencer",
    image: image1,
  },
  {
    id: "02",
    text: "Sevak Army Influencer helped us expand our healthcare reach and engage a wider, younger audience. Their coordination and authenticity ensured genuine representation, resulting in greater awareness and increased donations. We couldn’t have achieved this without them.",
    title: "Sunita Rao",
    subtitle: "Co-Founder, NGO",
    image: image2,
  },
  {
    id: "03",
    text: "Sevak Army Influencer transformed our environmental campaign by authentically connecting with audiences. Their influencers boosted our social media engagement by 50%, improving brand awareness across platforms. It was a game-changing collaboration.",
    subtitle: "Brand Partner",
    title: "Rajesh Patel",
    image: image3,
  },
  {
    id: "04",
    text: "Sevak Army Influencer connected me with causes that align with my values. Their support made content creation seamless and I love seeing the real-world impact my work generates.",
    title: "Aarti Mehta",
    subtitle: "Lifestyle Influencer",
    image: image4,
  },
  {
    id: "05",
    text: "Sevak Army Influencer showed me the power of promoting change, not just products. Their wellness campaigns resonated with my audience and boosted both engagement and support for important causes.",
    title: "Kabir Singh",
    subtitle: "Fitness Influencer",
    image: image5,
  },
  {
    id: "06",
    text: "Sevak Army Influencer connected us with passionate influencers who created authentic content. This increased engagement and sales while staying true to our sustainability mission.",
    title: "Priya Desai",
    subtitle: "CEO",
    image: image6,
  },
  {
    id: "06",
    text: "Sevak Army Influencer’s authentic collaborations elevated our healthcare campaign. Their influencers created meaningful content that increased engagement and participation in our programs.",
    title: "Ajay Verma",
    subtitle: "CEO",
    image: image7,
  },
];

const Team = [
  {
    Id: "1",
    img: timg1,
    title: "Ananya Desai",
    subtitle: " Social Impact Advocate",
  },
  {
    Id: "2",
    img: timg2,
    title: "Kabir Mehta",
    subtitle: "Health and Wellness Influencer",
  },
  {
    Id: "3",
    img: timg3,
    title: "Riya Kapoor",
    subtitle: "Environmental Sustainability Enthusiast",
  },
  {
    Id: "4",
    img: timg4,
    title: "Arjun Malhotra",
    subtitle: " Education and Youth Empowerment Promoter",
  },
];

const ClickHandler = () => {
  window.scrollTo(10, 0);
};
const Testimonial = [
  {
    id: "01",
    text: "We believe that actual influence comes from authenticity. Our collaborations with influencers are built on a genuine passion for the causes they support. We ensure that every campaign reflects the influencer’s values and resonates with their audience, creating real connections and inspiring meaningful action.",
    title: "Authenticity",
  },
  {
    id: "02",
    text: "This partnership is at the heart of everything we do. We connect influencers with NGOs, brands and grassroots organizations to form impactful collaborations. By working together, we amplify our efforts and ensure that every campaign makes a tangible difference in the communities we serve.",
    title: "Collaboration for Impact",
  },
  {
    id: "03",
    text: "We empower influencers to use their voice for positive change. By providing access to campaigns, tools and resources, we help influencers harness their platform’s full potential to drive social good, amplifying the reach of critical causes.",

    title: "Empowerment Through Influence",
  },
  {
    id: "04",
    text: "We are committed to promoting sustainability in every campaign. Whether it’s environmental conservation, education or healthcare, we strive to support long-term, sustainable solutions that uplift communities and preserve the planet for future generations.",
    title: "Sustainability in Action",
  },
  {
    id: "05",
    text: "Influencers have a responsibility to give back to society. We encourage influencers to champion causes that align with their values, driving awareness and inspiring action for the betterment of underserved communities.",
    title: "Social Responsibility",
    subtitle: "President of Sales",
  },
  {
    id: "06",
    text: "Trust is the foundation of our work. We ensure complete transparency in all our partnerships, from influencer selection to campaign execution, maintaining open communication and clear objectives to build lasting relationships between influencers, NGOs and communities.",
    title: "Transparency and Trust",
  },
  {
    id: "07",
    text: "At Sevak Army Influencer, we empower you to make a difference by contributing directly to the causes that matter. Our donation buttons are integrated across campaigns, allowing you and your followers to contribute. Every rupee counts toward creating an impact in education and environmental initiatives.",
    title: "Donation Buttons",
  },
  {
    id: "08",
    text: "We connect influencers with hands-on opportunities to volunteer and support campaigns on the ground. Our volunteer management system makes it easy for influencers and their followers to sign up for activities like community clean-ups, teaching and health camps, tracking your contribution to each cause.",
    title: "Volunteer Management",
  },
  {
    id: "09",
    text: "Transparency is key. We showcase real-time impact metrics, including funds raised, volunteer hours contributed and communities affected. These metrics provide a live view of how campaigns are progressing and the change they’re driving, reinforcing the tangible impact of your involvement.",
    title: "Impact Metrics",
  },
];
const InflunencerPages = (props) => {
  const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <i className="flaticon-right-arrow-1"></i>
      </div>
    );
  };

  const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <i className="flaticon-left-arrow"></i>
      </div>
    );
  };
  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const openModal = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const causesData = [
    {
      id: 1,

      title: "#EducationForAll Campaign: Transforming Lives Through Education",

      description:
        "During the campaign, influencers partnered with education-focused NGOs to bring learning opportunities to underprivileged children in rural areas. The initiative provides scholarships, essential learning materials and mentorship to students who need access to quality education. Influencers shared inspiring stories of children overcoming challenges, motivating their audiences to support the cause. The campaign demonstrated how social influence can drive real-world change, especially in education.",
    },
    {
      id: 2,

      title: "#CleanAirForIndia Campaign: Influencers Unite for a Cleaner Tomorrow",

      description:
        "Sevak Army Influencer collaborated with environmental advocates to raise awareness about air pollution in major Indian cities. The campaign reached a broad audience and sparked local actions, such as implementing air quality monitoring systems in key areas. Influencers used their platforms to educate their followers on simple yet effective ways to reduce air pollution, inspiring communities to take action for a cleaner environment. The campaign’s success was amplified by widespread media coverage, prompting greater attention from community leaders.",
    },
    {
      id: 3,

      title: "#EmpowerWomenNow Campaign: Uplifting Women, One Story at a Time",

      description:
        "This campaign focused on empowering women in underserved communities by providing vocational training and financial literacy programmes. With the help of influencers, many women were able to transform their lives, gaining skills and knowledge to achieve economic independence. Influencers shared compelling stories of women’s journeys through the programme, motivating their audiences to support the cause. The success of the #EmpowerWomenNow campaign showed the powerful role influencer storytelling plays in driving meaningful social impact.",
    },
  ];

  const causesData1 = [
    {
      id: 1,
      slug: "1",
      title: "Kids Education",
      campaign:
        "Campaign: Influencers share stories of children who have overcome adversity through education, showcasing how every contribution can make a tangible difference. This drives emotional engagement and action from audiences, helping us reach our goals.",
      image: Cimg1,
      Simage: Csimg1,
      raisedAmount: 8000,
      goalAmount: 10000,
      description:
        "Education is the cornerstone of empowerment. The SavakArmy Influencer programme brings together influencers who advocate for educational equity, raising awareness and funds to support scholarships, learning materials and mentorship programmes for underprivileged children. By collaborating with influencers, we amplify the voices of those fighting for educational opportunities and create impactful campaigns that inspire donors to contribute towards children’s futures. The funds raised provide necessary resources like books, uniforms, technology and access to quality teachers.",
    },
    {
      id: 2,
      slug: "2",
      title: "Pure Water",
      campaign:
        "Campaign: Using real-life examples of communities transformed by clean water access, influencers highlight clean water's health and economic benefits, making the case for continued support of water initiatives.",
      image: Cimg2,
      Simage: Csimg2,
      raisedAmount: 4000,
      goalAmount: 10000,
      description:
        "Access to clean water is a fundamental right, yet millions worldwide lack this basic necessity. Influencers in the SavakArmy programme are instrumental in promoting clean water initiatives. Their platforms help raise awareness of the critical importance of safe drinking water, particularly in vulnerable and underserved communities. Through social media campaigns and interactive engagement, influencers encourage their audience to actively contribute to the construction of wells, water filtration systems and sanitation education, helping to improve access to clean water.",
    },
    {
      id: 3,
      slug: "3",
      title: "Healthy Food",
      campaign:
        "Campaign: Influencers showcase before-and-after stories of children whose lives have been changed by access to nutritious food, demonstrating the value of sustained support for food programmes.",
      image: Cimg3,
      Simage: Csimg3,
      raisedAmount: 8692,
      goalAmount: 10000,
      description:
        "The role of nutrition in a child's physical and cognitive development cannot be overstated. The SavakArmy Influencer programme enlists influencers to support and promote nutrition programmes to provide healthy meals to underserved populations. Influencers use their platforms to emphasise the importance of food security and the direct impact it has on children’s ability to learn, grow and thrive. These campaigns encourage others to take steps to provide support for meal programs in schools and community centres, ensuring that children receive the nourishment they need.",
    },
    {
      id: 4,
      slug: "4",
      title: "Medical Care",
      campaign:
        "Campaign: Influencers document the delivery of medical supplies and healthcare services to remote villages, showcasing the real-world impact of these efforts and inspiring their audience to take meaningful action to support the cause",
      image: Cimg4,
      Simage: Csimg4,
      raisedAmount: 5000,
      goalAmount: 10000,
      description:
        "Healthcare is a critical need in many impoverished regions. SavakArmy passionately advocates for essential healthcare initiatives through partnerships with influencers, providing vital medical supplies, mobile clinics, and emergency care. Influencers spotlight health disparities and inspire their followers to support life-saving services for children and families who lack access to care, while effectively amplifying the message and mobilizing their audiences to make a tangible difference.",
    },
  ];

  const settings1 = {
    dots: false,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: true,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Navbar Logo={Logo} hclass={"wpo-site-header"} />
      <PageTitle pageTitle={"About Us"} pagesub={"About Us"} />
      <Scrollbar />
      <section className="wpo-about-section-s3 pb-0 pt-100">
        <div className="container">
          <div className="wpo-about-wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-img">
                  <div className="">
                    <img src={abut3} alt="about us" />
                  </div>
                  {/* <div className="shape">
                      <img src={abutShape3} alt="" />
                    </div> */}
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="wpo-about-text">
                  <div className="wpo-section-title">
                    <h2 className="text-titleeee"> About Us</h2>
                  </div>
                  <p>
                    At Sevak Army Influencer, we believe in the transformative power of social influence to create
                    lasting change. Our platform bridges the gap between influencers, NGOs and grassroots organizations,
                    enabling them to collaborate and drive impactful campaigns for the betterment of society. By
                    partnering with us, influencers become agents of change, amplifying causes that matter most to
                    underserved communities. Our mission is to harness the power of social media and influence to bring
                    real-world solutions to the most pressing social issues.
                  </p>

                  {/* <div className="about-btn">
                    <Link to="/influencer/services" className="theme-btn" onClick={ClickHandler}>
                      Discover More
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wpo-about-section-s4 section-padding">
        <div className="container">
          <div className="wpo-about-wrap">
            <div className="row just-fy-content-center align-items-center">
              <div className="col-lg-6 col-md-12  order-lg-1">
                <div className="wpo-about-text mt-0">
                  <div className="wpo-section-title mt-0">
                    <h2 className="text-titleeee mt-0">Our Mission</h2>
                  </div>
                  <p style={{ textAlign: "justify" }}>
                    Empower influencers to drive meaningful social change by connecting them with grassroots
                    organizations, NGOs and impactful causes. We aim to transform social influence into a powerful tool
                    for raising awareness, mobilising resources and amplifying initiatives that create long-lasting,
                    positive change in communities worldwide. Whether it’s promoting education, healthcare,
                    environmental conservation or women’s empowerment, our mission is to unite influencers with causes
                    that resonate and inspire action.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 order-lg-1">
                <div className="wpo-about-text">
                  <div className="wpo-section-title mt-0">
                    <h2 className="text-titleeee">Our Vision</h2>
                  </div>
                  <p style={{ textAlign: "justify" }}>
                    A world where every influencer uses their platform to foster positive social impact, driving
                    solutions for critical issues and improving lives globally. Our vision is to build a community where
                    all the influencers from all walks of life collaborate to address global challenges and to help
                    others to grow, promote the sustainable development and uplift underserved communities. We envision
                    a future where social influencers drives collective action, creating a better, more equitable
                    society for all communities and generations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={"section-padding pt-0"}>
        <div className="container">
          <SectionTitle subtitle={"Values"} title="Our Core Values" />
          <div className="wpo-testimonial-slider">
            <Slider {...settings}>
              {Testimonial.map((Testimonial, item) => (
                <div className="testimonial-item" key={item}>
                  <div className="content" style={{ minHeight: 580 }}>
                    <div className="text">
                      <p style={{ textAlign: "center", fontSize: "20px", fontWeight: "bold" }}>{Testimonial.title}</p>
                    </div>
                    <p>{Testimonial.text}</p>
                    {/* <div className="shape">
                      <img src={Shape} alt="" />
                    </div> */}
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
      <section className="wpo-instagram-section s2 section-padding pt-0">
        <div className="container">
          <div className="instagram-wrap">
            <div className="wpo-section-title">
              <span>Gallery</span>
              <h2 className="about-heading-third">Our Gallery</h2>
            </div>
            <div className="row">
              {Instagram.map((item) => (
                <div className="col col-lg-4 col-md-6 col-12" key={item.id}>
                  <div className="instagram-card">
                    <div className="image">
                      <Link
                        to={item.imag}
                        className="fancybox"
                        data-fancybox-group="gall-1"
                        onClick={(e) => {
                          e.preventDefault();
                          openModal(item.imag);
                        }}
                      >
                        <img src={item.imag} alt={`Instagram ${item.id}`} className="img img-responsive" />
                        <div className="popup-icon">
                          <i className="ti-plus"></i>
                        </div>
                      </Link>
                    </div>
                    <div className="text">
                      <span>{item.title}</span>
                      <h2>{item.subtitle}</h2>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {modalOpen && (
          <div className="modal" onClick={closeModal}>
            <div className="modal-wrap">
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <span className="close" onClick={closeModal}>
                  &times;
                </span>
                <img src={selectedImage} alt="Modal" />
              </div>
            </div>
          </div>
        )}
      </section>

      <section className={"wpo-testimonial-section pt-0 mt-0"}>
        <div className="causes-wrap pt-0 mt-0">
          <div className="container-fluid">
            <SectionTitle title="Communities Thrive with Your Support Today." subtitle="Causes" />
            <div className="row">
              {causesData.slice(0, 3).map((cause, cky) => (
                <div className="col col-lg-4 col-md-6 col-12" key={cky}>
                  <div className="causes-item">
                    <div className="content" style={{ minHeight: 540 }}>
                      <h2>{cause.title}</h2>
                      <p>{cause.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section style={{ marginTop: "100px" }} className="wpo-cta-section">
        <div className="shape-1">
          <img src={Cta1} alt="" />
        </div>
        <div className="bg-overlay">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="cta-wrap">
                  <div className="icon">
                    <img src={Cta2} alt="" />
                  </div>
                  <h2> Be a Part of Our Success Stories</h2>
                  <span>
                    Sevak Army's influencer collaborations do more than raise awareness. They create tangible results.
                    Whether you're an influencer looking to make a difference, a brand wanting to contribute to social
                    good or an NGO seeking to amplify your cause, Sevak Army Influencer provides the platform, support
                    and community needed to create real change.
                  </span>

                  <Link to="/register" onClick={ClickHandler} className="theme-btn-cta">
                    Subscribe now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-2">
          <img src={Cta3} alt="" />
        </div>
      </section>
      <section style={{ marginTop: "100px" }} className={props.hclass}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12 col-12">
              <SectionTitle subtitle="Volunteer" title="Meet our expert volunteer team." />
            </div>
          </div>
          <div className="volunteer-wraper">
            <div className="row">
              {Team.slice(0, 4).map((teams, tky) => (
                <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={tky}>
                  <TeamCard Tsubtitle={teams.subtitle} Timg={teams.img} Ttitle={teams.title} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="wpo-causes-section-s2 section-padding">
        <div className="causes-wrap">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-12 col-12">
                <SectionTitle subtitle="Services" title="Your Influence Sparks Lifelong Change." />
              </div>
            </div>
            <div className="row g-0 align-items-center mt-4">
              <div className="w-100 d-flex flex-wrap">
                {causesData1.slice(0, 4).map((cause, index) => (
                  <div key={index} className="col col-lg-6 col-md-6 col-12">
                    <div className="causes-item mb-4" >
                      <div className="image">
                        <img src={cause.image} alt={cause.title}  />
                      </div>
                      <div className="content">
                        <h2>{cause.title}</h2>
                        <p>{cause.description}</p>
                        <p>{cause.campaign}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default InflunencerPages;
