import image1 from "../images1/team/sevak-army-team-8.jpg";
import image2 from "../images1/team/sevak-army-team-4.jpg";
import image3 from "../images1/team/sevak-army-team-5.jpg";

const TestimonialData = [
  {
    id: "01",
    text: "Sevak Army.com empowered our company to engage employees in meaningful volunteer projects, fostering social responsibility and strengthening community ties through CSR initiatives.",
    title: "CSR Manager",
    subtitle: "Manager",
    image: image1,
  },
  {
    id: "02",
    text: "Partnering with Sevak Army.com transformed our CSR efforts. The platform provided seamless access to volunteer opportunities that aligned with our values, making it easy for us to contribute to lasting change.",
    title: "CSR Lead",
    subtitle: "Leader",
    image: image2,
  },
  {
    id: "03",
    text: "Sevak Army.com made our CSR initiatives more impactful. Their streamlined approach allowed us to connect with causes that resonated with our mission, helping us drive real social impact.",
    subtitle: "CSR Coordinator",
    title: "CSR Coordinator",
    image: image3,
  },
  // {
  //     id: "04",
  //     text: "This is the best agency for non profit activities.I love their work so much. I wish one day they would remove all the poverty from the world. I will be there when they need.",
  //     title: "Albert Flores",
  //     subtitle: "President of Sales",
  //     image: image2
  // },
];

export default TestimonialData;
