import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { useParams } from "react-router-dom";
import blogs from "../../api/blogs";
import BlogSingle from "../../components/BlogDetails/BlogSingle.js";
import Footer from "../../components/footer/Footer";
import Logo from "../../images/logo.svg";

const BlogDetails = (props) => {
  const BlogDetails = {
    id: 82,
    title: "Career Growth",
    ngo_id: 235,
    details: "A chance to advance to a higher position within your organization due to excellent performance.",
    volunteering_type: "on_site",
    opportunity_type: "volunteering",
    days: "50",
    price: 1000,
    frequency: "monthly",
    minimum_term: null,
    perks: "certificate",
    selection_criteria:
      "With functions you can reuse code\n\nYou can write code that can be used many times.\n\nYou can use the same code with different arguments, to produce different results.",
    skills: ["Marketing", "Admin", "Legal"],
    gender: ["male", "female", "other"],
    age_range: "31-35",
    languages: ["Punjabi", "Tamil"],
    qualification: ["Associate's Degree", "Bachelor's Degree", "Master's Degree"],
    points: "100",
    is_approved: 1,
    approved_by_user_id: 1,
    file: "https://ngo-app-asset-images.s3.ap-south-1.amazonaws.com/opportunity_docs/files-c2f7cb91-129e-46b6-ba17-439717566617.jpg",
    created_at: "2024-10-01T07:16:42.000000Z",
    updated_at: "2024-10-01T07:36:39.000000Z",
    status: "accept",
    opportunity_image:
      "https://ngo-app-asset-images.s3.ap-south-1.amazonaws.com/opportunity_docs/opportunity_image-45956d01-d649-49f7-915d-c53f2da30d1d.jpg",
    available_type_id: null,
    ngo_status: null,
    ngo_status_updated_by: null,
    hours: null,
    count: 100,
    reason: null,
    opportunity_video:
      "https://ngo-app-asset-images.s3.ap-south-1.amazonaws.com/opportunity_video/opportunity_video-c67d914b-45ea-49e5-a703-7d1d996b37f2.mp4",
    opportunity_video_link: null,
    start_date: "2024-10-02 00:00:00",
    end_date: "2024-10-31 00:00:00",
    reviews: [
      {
        id: 6,
        message: "i am happy with working eith you and great opportuity this is .",
        ngo_id: 235,
        review_by_user_id: 435,
        is_approve: 1,
        created_at: "2024-10-01T10:15:33.000000Z",
        updated_at: "2024-10-01T10:15:33.000000Z",
        rating: 4,
        opportunity_id: 82,
        review_to: "volunteer",
        review_by_user: {
          id: 435,
          name: "Shivani Anil Pawar",
          email: "shivani.neuromonk7+121@gmail.com",
          mobile_number: "2525252525",
          created_at: "2024-09-30T01:17:11.000000Z",
          updated_at: "2024-09-30T01:34:36.000000Z",
          type: "volunteer",
          ngo_id: null,
          is_referred_user_id: null,
          referral_code: "SASHIV435",
          whatsapp_no: null,
          alt_mobile: "2525252525",
          date_of_birth: "2002-07-09",
          about:
            'The "@" symbol is commonly referred to as the "at symbol" or simply "at." In various languages and regions, it may have different names or be called by other terms. Here are some of the names for the "@" symbol in different languages: English: At symbol, At sign. Spanish: Arroba.10 Jan 2020',
          gender: "female",
          pincode: "123654",
          state: "Maharashtra",
          city: "Pune",
          area: "marketyard",
          address_line_1: "marketyard, gultekdi",
          address_line_2: "marketyard, gultekdi",
          landmark: "bus stop",
          industry_id: 70,
          profession_id: 59,
          title: "abc",
          experience: "30+ yrs",
          institution:
            'The "@" symbol is commonly referred to as the "at symbol" or simply "at." In various languages and regions, it may have different names or be called by other terms. Here are some of the names for the "@" symbol in different languages: English: At symbol, At sign. Spanish: Arroba.10 Jan 2020.',
          languages: ["Hindi", "Marathi", "Gujarati", "Punjabi"],
          skills: ["Marketing", "Branding", "Computers", "Admin"],
          preferred_opportunity: "skilled",
          motivation: [
            "civicResponsibility",
            "familySocietalExpectations",
            "internationalNorms",
            "other",
            "schoolCollegeOrEmployerRequirements",
            "personalOrProfessionalDevelopment",
            "seniors",
            "personalInterest",
            "networking",
            "resumeBuilding",
          ],
          why_volunteering: null,
          how_heard: "NGO",
          done_volunteering: "yes",
          is_referred: 0,
          amount_saved: null,
          hours_dedicated: null,
          opportunities: null,
          how_many_hours_per_month: "310",
          volunteer_signup_1: 1,
          volunteer_signup_complete: 1,
          basic_details: 1,
          professional_details: 1,
          cause_availability: 1,
          additional_details: 1,
          ngo_admin_signup_1: 0,
          ngo_admin_signup_complete: 0,
          previous_experience: "456",
          volunteer_profile:
            "https://ngo-app-asset-images.s3.ap-south-1.amazonaws.com/vol_profile/files-4fea770b-1b85-4704-8bba-fa5904dd9775.png",
          firebase_device_id: null,
          district: null,
          taluka: null,
          country_code: "91",
          alt_mobile_country_code: "91",
          country: "India",
          onesignal_player_id: null,
          two_factor_enabled: 0,
        },
      },
    ],
    average_rating: 4,
    causes: [
      {
        id: 73,
        name: "Child Protection",
        created_at: "2024-10-01T05:20:02.000000Z",
        updated_at: "2024-10-01T05:20:02.000000Z",
        pivot: {
          opportunity_id: 82,
          cause_id: 73,
        },
      },
      {
        id: 71,
        name: "Women Empowerment",
        created_at: "2024-10-01T05:20:02.000000Z",
        updated_at: "2024-10-01T05:20:02.000000Z",
        pivot: {
          opportunity_id: 82,
          cause_id: 71,
        },
      },
      {
        id: 70,
        name: "Poverty Alleviation",
        created_at: "2024-10-01T05:20:02.000000Z",
        updated_at: "2024-10-01T05:20:02.000000Z",
        pivot: {
          opportunity_id: 82,
          cause_id: 70,
        },
      },
    ],
    ngo: {
      id: 235,
      name: "Clean Enviornment",
      email: "shivani.neuromonk7+30@gmail.com",
      ngo_name: null,
      is_kyc_done: 1,
      is_admin_approved: 0,
      city: "Pune",
      time_from: null,
      time_to: null,
      days: "sunday,monday,tuesday,wednesday,thursday,friday,saturday",
      team_size: 40,
      facebook: "sevakarmy",
      instagram: "sevakarmy",
      linkedin: "sevakarmy",
      twitter: "sevakarmy",
      medium: null,
      pincode: "456325",
      state: "Maharashtra",
      area: "marketyard",
      address_line_1: "marketyard, gultekadi, pune",
      address_line_2: "marketyard, gultekadi, pune",
      cities_present: "Pune Division,Aundh Satara,Wakad,Hadapsar Pune",
      year_of_establishment: 1948,
      website: "www.sevakarmy.com",
      created_at: "2024-10-01T06:40:05.000000Z",
      updated_at: "2024-10-01T06:54:47.000000Z",
      about:
        "With functions you can reuse code\r\n\r\nYou can write code that can be used many times.\r\n\r\nYou can use the same code with different arguments, to produce different results.",
      amount_saved: 0,
      hours_dedicated: 0,
      opportunities: 0,
      signup_1: 1,
      signup_complete: 1,
      registration: 1,
      kyc: 1,
      aadhaar_file:
        "https://ngo-app-asset-images.s3.ap-south-1.amazonaws.com/ngo_docs/aadhaar_files-265fa8cf-1364-4944-ba63-692709d70e4b.png",
      pan_file:
        "https://ngo-app-asset-images.s3.ap-south-1.amazonaws.com/ngo_docs/pan_files-831bda05-34fa-4266-af95-4fe6d66d79ea.png",
      ngo_logo:
        "https://ngo-app-asset-images.s3.ap-south-1.amazonaws.com/ngo_docs/ngo_logos-140e5b19-cd20-4848-9044-43ba3b21b923.png",
      district: null,
      taluka: null,
      country_code: null,
      country: "India",
      type: "ngo_admin",
      status: "accepted",
      reson: null,
      approve_by_user_id: null,
      reddit: null,
      quora: null,
    },
    available_type: null,
  };

  return (
    <Fragment>
      <Navbar Logo={Logo} hclass={"wpo-site-header-s5"} />
      {/* <PageTitle pageTitle={BlogDetails.title} pagesub={"Blog"} /> */}
      <BlogSingle BlogDetails={BlogDetails} show={props.show} setShow={props.setShow} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default BlogDetails;
