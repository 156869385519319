import pimg1 from '../../images1/volunteer/home/sevak-army-volunteer-home-3.jpg'
import pimg2 from '../../images1/volunteer/home/sevak-army-volunteer-home-4.jpg'
import pimg3 from '../../images1/volunteer/home/sevak-army-volunteer-home-5.jpg'
import psimg1 from '../../images1/volunteer/home/sevak-army-volunteer-home-3.jpg'
import psimg2 from '../../images1/volunteer/home/sevak-army-volunteer-home-4.jpg'
import psimg3 from '../../images1/volunteer/home/sevak-army-volunteer-home-5.jpg'



const volunteerhomeEvents = [
    {
        id: '1',
        title: 'Attend the blood donation camp',
        slug: '1',
        pimg: pimg1,
        psimg: psimg1,
        date: "25th",
        month: "Sept"
    },
    {
        id: '2',
        title: 'Join us for the community food drive',
        slug: '2',
        pimg: pimg2,
        psimg: psimg2,
        date: "1st",
        month: "Oct"
    },
    {
        id: '3',
        title: 'Participate in the Mumbai cleanliness drive',
        slug: '3',
        pimg: pimg3,
        psimg: psimg3,
        date: "5th",
        month: "Oct"
    },
]

export default volunteerhomeEvents;